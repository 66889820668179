export const QUERY_KEYS = {
  oidc: {
    getToken: 'get-oidc-token',
    refreshToken: 'refresh-oidc-token',
    userProfile: 'get-user-profile'
  },
  mission: {
    getNextMission: 'get-next-mission'
  },
  tour: {
    parameterOptionsBundle: 'get-tour-parameter-options-bundle',
    estimatedDrivingTime: 'get-estimated-driving-time',
    openTours: 'get-open-tours',
    openTour: 'get-open-tour'
  },
  step: {
    completeActions: 'get-complete-actions'
  },
  relocate: {
    vehicles: 'get-relocate-vehicles'
  },
  locations: {
    getBusinessLocation: 'get-business-locations'
  },
  cache: {
    activeVehicleTypes: 'get-pricing-level-entries'
  },
  vehicle: {
    byLicencePlate: 'get-vehicle-by-licenceplate',
    nearby: 'get-vehicle-nearby',
    latestReports: 'get-latest-reports'
  },
  workshop: {
    repairTickets: 'get-repair-tickets',
    repairTicket: 'get-repair-ticket',
    problemList: 'get-problem-list',
    liveData: 'get-vehicle-live-data'
  }
} as const;

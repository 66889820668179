import { ROUTES } from '@/App/routes/routes';
import { useGetOidcToken } from '@/hooks/Authentication/token/useGetOidcToken';
import { useUserProfile } from '@/hooks/Authentication/useUserProfile';
import { useStore } from '@/store';
import { clearToken } from '@badgermoleV2/client';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const LoginReturnScreen = () => {
  const [returnedUrlParams] = useSearchParams();

  const navigate = useNavigate();
  const { isSuccess } = useGetOidcToken(returnedUrlParams.get('code'));

  const queryClient = useQueryClient();
  const logout = useStore((s) => s.logout);
  const { user, isPending, isError, isUserInAuthorizedGroup } = useUserProfile(isSuccess);

  useEffect(() => {
    if (isPending) return;
    if (isError) {
      logout();
      clearToken();
      queryClient.clear();
    }
    if (isSuccess && user && isUserInAuthorizedGroup()) {
      navigate(ROUTES.OVERVIEW, { replace: true });
    }
  }, [isPending, isError, logout, isSuccess, user, navigate, queryClient, isUserInAuthorizedGroup]);

  return <div>Redirecting...</div>;
};

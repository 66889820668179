/* tslint:disable */
/* eslint-disable */
/**
 * Badgermole (staging)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 8caff256
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * @type Action
 * @export
 */
export type Action = object;

/**
 *
 * @export
 * @interface ActionNotAllowed
 */
export interface ActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof ActionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ActionResult = {
  Success: 'Success',
  Error: 'Error',
  NothingToDo: 'NothingToDo'
} as const;

export type ActionResult = (typeof ActionResult)[keyof typeof ActionResult];

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  vin: string;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof Asset
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof Asset
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface AssetFilters
 */
export interface AssetFilters {
  /**
   *
   * @type {number}
   * @memberof AssetFilters
   */
  businessLocationId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof AssetFilters
   */
  assetStateIds?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof AssetFilters
   */
  ignoreAssetsWithOpenTasks?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetFilters
   */
  licencePlate?: string;
}
/**
 *
 * @export
 * @interface AssetInfo
 */
export interface AssetInfo {
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  assetStateId?: number;
  /**
   *
   * @type {string}
   * @memberof AssetInfo
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleTypeId?: number;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  wunderfleetVehicleStateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof AssetInfo
   */
  isReserved?: boolean;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  mileage?: number;
  /**
   *
   * @type {string}
   * @memberof AssetInfo
   */
  iotId?: string;
  /**
   *
   * @type {number}
   * @memberof AssetInfo
   */
  batteryPercent?: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof AssetInfo
   */
  location?: GpsPosition;
  /**
   *
   * @type {Asset}
   * @memberof AssetInfo
   */
  asset: Asset;
  /**
   *
   * @type {Array<WunderVehicleTag>}
   * @memberof AssetInfo
   */
  tags?: Array<WunderVehicleTag>;
  /**
   *
   * @type {Array<Comment>}
   * @memberof AssetInfo
   */
  comments?: Array<Comment>;
}
/**
 *
 * @export
 * @interface AssetType
 */
export interface AssetType {
  /**
   *
   * @type {number}
   * @memberof AssetType
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  pricingLevel: AssetTypePricingLevelEnum;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetType
   */
  isActive: boolean;
}

export const AssetTypePricingLevelEnum = {
  Default: 'default',
  Discounted: 'discounted',
  Overpriced: 'overpriced',
  Special: 'special',
  Unknown: 'unknown'
} as const;

export type AssetTypePricingLevelEnum =
  (typeof AssetTypePricingLevelEnum)[keyof typeof AssetTypePricingLevelEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentState = {
  Unassigned: 'Unassigned',
  Considered: 'Considered',
  InProgress: 'InProgress',
  PreStepsCompleted: 'PreStepsCompleted',
  AtStepsCompleted: 'AtStepsCompleted',
  PostStepsCompleted: 'PostStepsCompleted',
  Completed: 'Completed',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Skipped: 'Skipped'
} as const;

export type AssignmentState = (typeof AssignmentState)[keyof typeof AssignmentState];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  DriveBack: 'DriveBack',
  Relocate: 'Relocate'
} as const;

export type AssignmentType = (typeof AssignmentType)[keyof typeof AssignmentType];

/**
 *
 * @export
 * @interface AvailableProblem
 */
export interface AvailableProblem {
  /**
   *
   * @type {string}
   * @memberof AvailableProblem
   */
  name: string;
}
/**
 *
 * @export
 * @interface Base64Image
 */
export interface Base64Image {
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  imageType: Base64ImageImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  base64EncodedImage: string;
}

export const Base64ImageImageTypeEnum = {
  Png: 'png',
  Jpeg: 'jpeg'
} as const;

export type Base64ImageImageTypeEnum =
  (typeof Base64ImageImageTypeEnum)[keyof typeof Base64ImageImageTypeEnum];

/**
 *
 * @export
 * @interface BaseTerritory
 */
export interface BaseTerritory {
  /**
   *
   * @type {number}
   * @memberof BaseTerritory
   */
  territoryId: number;
  /**
   *
   * @type {Array<Polygon>}
   * @memberof BaseTerritory
   */
  polygons?: Array<Polygon>;
  /**
   *
   * @type {string}
   * @memberof BaseTerritory
   */
  marker?: string;
}
/**
 *
 * @export
 * @interface BulkCreateAssetsFileValidation
 */
export interface BulkCreateAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkCreateAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkCreateRow>}
   * @memberof BulkCreateAssetsFileValidation
   */
  assetsToBeCreated?: Array<BulkCreateRow>;
  /**
   *
   * @type {string}
   * @memberof BulkCreateAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkCreateRow
 */
export interface BulkCreateRow {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  deliveryDate: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  endWarranty: string;
}
/**
 *
 * @export
 * @interface BulkCreateRowResult
 */
export interface BulkCreateRowResult {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  assetUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkCreateRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkCreateStatus
 */
export interface BulkCreateStatus {
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedBy: string;
  /**
   *
   * @type {BulkCreateStatusType}
   * @memberof BulkCreateStatus
   */
  status: BulkCreateStatusType;
  /**
   *
   * @type {number}
   * @memberof BulkCreateStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkCreateRowResult>}
   * @memberof BulkCreateStatus
   */
  results?: Array<BulkCreateRowResult>;
}
/**
 * @type BulkCreateStatusType
 * @export
 */
export type BulkCreateStatusType = object;

/**
 *
 * @export
 * @interface BulkEditAssetsFileValidation
 */
export interface BulkEditAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkEditAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkEditRow>}
   * @memberof BulkEditAssetsFileValidation
   */
  vehiclesToBeEdited?: Array<BulkEditRow>;
  /**
   *
   * @type {string}
   * @memberof BulkEditAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkEditRow
 */
export interface BulkEditRow {
  /**
   *
   * @type {number}
   * @memberof BulkEditRow
   */
  vehicleId: number;
}
/**
 *
 * @export
 * @interface BulkEditRowResult
 */
export interface BulkEditRowResult {
  /**
   *
   * @type {number}
   * @memberof BulkEditRowResult
   */
  vehicleId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkEditRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkEditStartBody
 */
export interface BulkEditStartBody {
  /**
   *
   * @type {BulkEditAssetsFileValidation}
   * @memberof BulkEditStartBody
   */
  validation: BulkEditAssetsFileValidation;
  /**
   *
   * @type {string}
   * @memberof BulkEditStartBody
   */
  updateBodyJson: string;
}
/**
 *
 * @export
 * @interface BusinessLocation
 */
export interface BusinessLocation {
  /**
   *
   * @type {number}
   * @memberof BusinessLocation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation
   */
  label: string;
  /**
   *
   * @type {BusinessLocationCenter}
   * @memberof BusinessLocation
   */
  cityCenter: BusinessLocationCenter;
  /**
   *
   * @type {BaseTerritory}
   * @memberof BusinessLocation
   */
  businessTerritory: BaseTerritory;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  noParkingTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  incentiveTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<MobilityHub>}
   * @memberof BusinessLocation
   */
  mobilityHubs?: Array<MobilityHub>;
}
/**
 *
 * @export
 * @interface BusinessLocation1
 */
export interface BusinessLocation1 {
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  abbreviation?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  projectKey: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof BusinessLocation1
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  widthInMeters: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  heightInMeters: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocation1
   */
  tileSizeInMeters: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation1
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessLocation1
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface BusinessLocationCenter
 */
export interface BusinessLocationCenter {
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lon: number;
}
/**
 *
 * @export
 * @interface ClientCredentials
 */
export interface ClientCredentials {
  /**
   *
   * @type {string}
   * @memberof ClientCredentials
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ClientCredentials
   */
  password: string;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  authorUsername: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  message: string;
}
/**
 * @type CommonIdType
 * @export
 */
export type CommonIdType = object;

/**
 * @type CommonModelType
 * @export
 */
export type CommonModelType = object;

/**
 *
 * @export
 * @interface CommonSearchResult
 */
export interface CommonSearchResult {
  /**
   *
   * @type {CommonModelType}
   * @memberof CommonSearchResult
   */
  modelType: CommonModelType;
  /**
   *
   * @type {CommonIdType}
   * @memberof CommonSearchResult
   */
  idType: CommonIdType;
  /**
   *
   * @type {string}
   * @memberof CommonSearchResult
   */
  idValue: string;
  /**
   *
   * @type {string}
   * @memberof CommonSearchResult
   */
  description: string;
}
/**
 *
 * @export
 * @interface CompiledTour
 */
export interface CompiledTour {
  /**
   *
   * @type {Tour}
   * @memberof CompiledTour
   */
  tour: Tour;
  /**
   *
   * @type {Array<Step>}
   * @memberof CompiledTour
   */
  compiledSteps?: Array<Step>;
}
/**
 *
 * @export
 * @interface CompleteChecksResponse
 */
export interface CompleteChecksResponse {
  /**
   *
   * @type {boolean}
   * @memberof CompleteChecksResponse
   */
  success: boolean;
  /**
   *
   * @type {Array<LocalizedDescription>}
   * @memberof CompleteChecksResponse
   */
  errors?: Array<LocalizedDescription>;
}
/**
 *
 * @export
 * @interface CompleteTourBody
 */
export interface CompleteTourBody {
  /**
   *
   * @type {number}
   * @memberof CompleteTourBody
   */
  serviceVehicleMileage: number;
}
/**
 *
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lon: number;
}
/**
 *
 * @export
 * @interface CorridorPolygon
 */
export interface CorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomRight: GpsPosition;
}
/**
 *
 * @export
 * @interface EmptyBusinessLocation
 */
export interface EmptyBusinessLocation {
  /**
   *
   * @type {string}
   * @memberof EmptyBusinessLocation
   */
  message: string;
}
/**
 *
 * @export
 * @interface EndTourBody
 */
export interface EndTourBody {
  /**
   *
   * @type {string}
   * @memberof EndTourBody
   */
  reason: string;
  /**
   *
   * @type {boolean}
   * @memberof EndTourBody
   */
  keepDriveBackOpen: boolean;
}
/**
 *
 * @export
 * @interface EstimatedDrivingTime
 */
export interface EstimatedDrivingTime {
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  start: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  end: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof EstimatedDrivingTime
   */
  estimatedDrivingSeconds: number;
}
/**
 * @type ExperienceLevel
 * @export
 */
export type ExperienceLevel = object;

/**
 *
 * @export
 * @interface ExtendByOneBody
 */
export interface ExtendByOneBody {
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lon: number;
}
/**
 *
 * @export
 * @interface FailStepBody
 */
export interface FailStepBody {
  /**
   *
   * @type {TransitionAction}
   * @memberof FailStepBody
   */
  cancelAction: TransitionAction;
  /**
   *
   * @type {string}
   * @memberof FailStepBody
   */
  reason: string;
}

/**
 *
 * @export
 * @interface FilterSet
 */
export interface FilterSet {
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  filterSetId?: number;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof FilterSet
   */
  serviceLocation: BusinessLocation1;
  /**
   *
   * @type {ServiceHub}
   * @memberof FilterSet
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSet
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  mustGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  noGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface FilterSetBody
 */
export interface FilterSetBody {
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  filterSetId?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  startHubId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSetBody
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  mustGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type {Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  noGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface Forbidden
 */
export interface Forbidden {
  /**
   *
   * @type {string}
   * @memberof Forbidden
   */
  message: string;
}
/**
 *
 * @export
 * @interface GenericReservation
 */
export interface GenericReservation {
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  vehicleStateId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericReservation
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof GenericReservation
   */
  endTime?: string;
  /**
   *
   * @type {ReservationLocation}
   * @memberof GenericReservation
   */
  startAddress?: ReservationLocation;
  /**
   *
   * @type {ReservationLocation}
   * @memberof GenericReservation
   */
  endAddress?: ReservationLocation;
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  startBatteryLevel?: number;
  /**
   *
   * @type {number}
   * @memberof GenericReservation
   */
  endBatteryLevel?: number;
}
/**
 *
 * @export
 * @interface GenericTourEvent
 */
export interface GenericTourEvent {
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  jobId?: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  stepId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventTimestamp: string;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof GenericTourEvent
   */
  description: LocalizedDescription;
  /**
   *
   * @type {GenericTourEventType}
   * @memberof GenericTourEvent
   */
  eventType: GenericTourEventType;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventSubType: string;
  /**
   *
   * @type {ActionResult}
   * @memberof GenericTourEvent
   */
  eventOutcome: ActionResult;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventError?: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventPayload?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const GenericTourEventType = {
  TourEvent: 'TourEvent',
  TransitionEvent: 'TransitionEvent',
  TriggerEvent: 'TriggerEvent'
} as const;

export type GenericTourEventType = (typeof GenericTourEventType)[keyof typeof GenericTourEventType];

/**
 *
 * @export
 * @interface GenericVehicleNote
 */
export interface GenericVehicleNote {
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  noteId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericVehicleNote
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  vehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof GenericVehicleNote
   */
  createdBy?: number;
  /**
   *
   * @type {string}
   * @memberof GenericVehicleNote
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface GpsPosition
 */
export interface GpsPosition {
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface GpsPosition1
 */
export interface GpsPosition1 {
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lon: number;
}
/**
 *
 * @export
 * @interface Grid
 */
export interface Grid {
  /**
   *
   * @type {number}
   * @memberof Grid
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof Grid
   */
  createdAt: string;
  /**
   *
   * @type {Array<Tile>}
   * @memberof Grid
   */
  tiles?: Array<Tile>;
  /**
   *
   * @type {Array<Line>}
   * @memberof Grid
   */
  lines?: Array<Line>;
}
/**
 *
 * @export
 * @interface HeaderValidation
 */
export interface HeaderValidation {
  /**
   *
   * @type {boolean}
   * @memberof HeaderValidation
   */
  validated: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  expectedHeaders?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  foundHeaders?: Array<string>;
}
/**
 *
 * @export
 * @interface HelmetBox
 */
export interface HelmetBox {
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  locked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  closed?: boolean;
}
/**
 *
 * @export
 * @interface InjectedTaskBasedJob
 */
export interface InjectedTaskBasedJob {
  /**
   *
   * @type {number}
   * @memberof InjectedTaskBasedJob
   */
  taskId: number;
  /**
   *
   * @type {number}
   * @memberof InjectedTaskBasedJob
   */
  positionInCompiledJobList: number;
}
/**
 *
 * @export
 * @interface InputComment
 */
export interface InputComment {
  /**
   *
   * @type {string}
   * @memberof InputComment
   */
  message: string;
}
/**
 *
 * @export
 * @interface InputServiceTask
 */
export interface InputServiceTask {
  /**
   *
   * @type {LocalizedDescription}
   * @memberof InputServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof InputServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof InputServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof InputServiceTask
   */
  sourceType?: ServiceSourceType;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  businessLocationId: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  serviceAssetVin: string;
}

/**
 *
 * @export
 * @interface InputSubTask
 */
export interface InputSubTask {
  /**
   *
   * @type {LocalizedDescription}
   * @memberof InputSubTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {string}
   * @memberof InputSubTask
   */
  assetVin: string;
  /**
   *
   * @type {AssignmentState}
   * @memberof InputSubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof InputSubTask
   */
  failReason?: string;
  /**
   *
   * @type {StepCategory}
   * @memberof InputSubTask
   */
  category?: StepCategory;
  /**
   *
   * @type {number}
   * @memberof InputSubTask
   */
  usedByTaskId?: number;
}

/**
 *
 * @export
 * @interface InputWorkshop
 */
export interface InputWorkshop {
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof InputWorkshop
   */
  serviceableBusinessLocationIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof InputWorkshop
   */
  businessLocationId: number;
  /**
   *
   * @type {number}
   * @memberof InputWorkshop
   */
  physicalLocationId: number;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  basicAccessRole: string;
  /**
   *
   * @type {string}
   * @memberof InputWorkshop
   */
  privilegedAccessRole: string;
  /**
   *
   * @type {boolean}
   * @memberof InputWorkshop
   */
  isExternal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InputWorkshop
   */
  deprecated: boolean;
}
/**
 *
 * @export
 * @interface JiraBulkCreateSubtaskBody
 */
export interface JiraBulkCreateSubtaskBody {
  /**
   *
   * @type {Array<string>}
   * @memberof JiraBulkCreateSubtaskBody
   */
  vins?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  subtaskDescription: string;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  confirmationMailTo: string;
}
/**
 *
 * @export
 * @interface JiraIssue
 */
export interface JiraIssue {
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  summary: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueType: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  statusName: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  resolution: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueKey: string;
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  updated?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  workshop?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  reasonForRepair?: string;
}
/**
 * @type JiraPriorities
 * @export
 */
export type JiraPriorities = object;

/**
 *
 * @export
 * @interface JiraPriorityBody
 */
export interface JiraPriorityBody {
  /**
   *
   * @type {JiraPriorities}
   * @memberof JiraPriorityBody
   */
  priority: JiraPriorities;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {number}
   * @memberof Job
   */
  jobId?: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Job
   */
  summary: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof Job
   */
  jobType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof Job
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  inheritedState?: string;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  preSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  atSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  postSteps?: Array<Step>;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  lastUpdatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  scooterId?: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Job
   */
  location?: GpsPosition;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  createdByServiceTask?: number;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  priority?: number;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Job
   */
  serviceSourceType?: ServiceSourceType;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  replacedByJobId?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  preEnergyLevel?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  postEnergyLevel?: number;
}

/**
 *
 * @export
 * @interface JobExistingWithoutTask
 */
export interface JobExistingWithoutTask {
  /**
   *
   * @type {string}
   * @memberof JobExistingWithoutTask
   */
  message: string;
}
/**
 *
 * @export
 * @interface JobOrdering
 */
export interface JobOrdering {
  /**
   *
   * @type {number}
   * @memberof JobOrdering
   */
  jobId: number;
  /**
   *
   * @type {number}
   * @memberof JobOrdering
   */
  position: number;
}
/**
 *
 * @export
 * @interface LicencePlateMissing
 */
export interface LicencePlateMissing {
  /**
   *
   * @type {string}
   * @memberof LicencePlateMissing
   */
  message: string;
}
/**
 *
 * @export
 * @interface Line
 */
export interface Line {
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  left: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  right: GpsPosition;
}
/**
 *
 * @export
 * @interface LiveData
 */
export interface LiveData {
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  battery_level: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  board_voltage: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  locked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  ignition_off: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  immobilized: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  centerstand_out?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  keyfob_in?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  saddle_closed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  alarm_active?: boolean;
  /**
   *
   * @type {HelmetBox}
   * @memberof LiveData
   */
  helmetBox?: HelmetBox;
  /**
   *
   * @type {TelematicsPosition}
   * @memberof LiveData
   */
  location?: TelematicsPosition;
}
/**
 *
 * @export
 * @interface LocalizedDescription
 */
export interface LocalizedDescription {
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  de: string;
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  en: string;
}
/**
 *
 * @export
 * @interface LocalizedDescriptionEmpty
 */
export interface LocalizedDescriptionEmpty {
  /**
   *
   * @type {string}
   * @memberof LocalizedDescriptionEmpty
   */
  message: string;
}
/**
 * @type LogTaskChangeType
 * @export
 */
export type LogTaskChangeType = object;

/**
 *
 * @export
 * @interface MismatchingJiraToken
 */
export interface MismatchingJiraToken {
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  code: string;
}
/**
 *
 * @export
 * @interface MissingField
 */
export interface MissingField {
  /**
   *
   * @type {string}
   * @memberof MissingField
   */
  message: string;
}
/**
 *
 * @export
 * @interface MissingPermission
 */
export interface MissingPermission {
  /**
   *
   * @type {string}
   * @memberof MissingPermission
   */
  message: string;
}
/**
 *
 * @export
 * @interface Mission
 */
export interface Mission {
  /**
   *
   * @type {ServiceEmployee}
   * @memberof Mission
   */
  createdBy: ServiceEmployee;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  createdAt: string;
  /**
   *
   * @type {MissionType}
   * @memberof Mission
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  priority: number;
  /**
   *
   * @type {Timespan}
   * @memberof Mission
   */
  validTimespan: Timespan;
  /**
   *
   * @type {UserTargetGroup}
   * @memberof Mission
   */
  offeredTo: UserTargetGroup;
  /**
   *
   * @type {FilterSet}
   * @memberof Mission
   */
  filterSet?: FilterSet;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  missionId?: number;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionBody
 */
export interface MissionBody {
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof MissionBody
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  priority: number;
  /**
   *
   * @type {UserTargetGroupBody}
   * @memberof MissionBody
   */
  offerTo: UserTargetGroupBody;
  /**
   *
   * @type {FilterSetBody}
   * @memberof MissionBody
   */
  filterSet?: FilterSetBody;
  /**
   *
   * @type {Timespan}
   * @memberof MissionBody
   */
  validTimespan: Timespan;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof MissionBody
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionOverview
 */
export interface MissionOverview {
  /**
   *
   * @type {Array<MissionWithTourStats>}
   * @memberof MissionOverview
   */
  missions?: Array<MissionWithTourStats>;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalMissions: number;
}
/**
 *
 * @export
 * @interface MissionTourStats
 */
export interface MissionTourStats {
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  missionId: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfCompletedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfFailedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfOpenTours: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MissionType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  Relocate: 'Relocate'
} as const;

export type MissionType = (typeof MissionType)[keyof typeof MissionType];

/**
 *
 * @export
 * @interface MissionWithTourStats
 */
export interface MissionWithTourStats {
  /**
   *
   * @type {Mission}
   * @memberof MissionWithTourStats
   */
  mission: Mission;
  /**
   *
   * @type {MissionTourStats}
   * @memberof MissionWithTourStats
   */
  missionTourStats: MissionTourStats;
}
/**
 *
 * @export
 * @interface MobilityHub
 */
export interface MobilityHub {
  /**
   *
   * @type {string}
   * @memberof MobilityHub
   */
  provider: string;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lon: number;
}
/**
 *
 * @export
 * @interface ModelProblem
 */
export interface ModelProblem {
  /**
   *
   * @type {number}
   * @memberof ModelProblem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  model: string;
  /**
   *
   * @type {boolean}
   * @memberof ModelProblem
   */
  deprecated: boolean;
  /**
   *
   * @type {ModelProblemCategory}
   * @memberof ModelProblem
   */
  category: ModelProblemCategory;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  defectPart?: string;
  /**
   *
   * @type {string}
   * @memberof ModelProblem
   */
  defectState?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const ModelProblemCategory = {
  Electronics: 'Electronics',
  Battery: 'Battery',
  Check: 'Check',
  HelmetBox: 'HelmetBox',
  Operations: 'Operations',
  Transmission: 'Transmission',
  Stickers: 'Stickers',
  PlatesAndStickers: 'PlatesAndStickers',
  Cloudboxx: 'Cloudboxx',
  LicencePlate: 'LicencePlate',
  Brakes: 'Brakes',
  Frame: 'Frame',
  FrontTrain: 'FrontTrain',
  Telematics: 'Telematics',
  General: 'General',
  Handlebar: 'Handlebar',
  Inspection: 'Inspection',
  BodyParts: 'BodyParts',
  Other: 'Other',
  Wheels: 'Wheels'
} as const;

export type ModelProblemCategory = (typeof ModelProblemCategory)[keyof typeof ModelProblemCategory];

/**
 *
 * @export
 * @interface ModelProblemCategoryApi
 */
export interface ModelProblemCategoryApi {
  /**
   *
   * @type {ModelProblemCategory}
   * @memberof ModelProblemCategoryApi
   */
  modelProblemCategory: ModelProblemCategory;
  /**
   *
   * @type {string}
   * @memberof ModelProblemCategoryApi
   */
  displayString: string;
}

/**
 *
 * @export
 * @interface NoJobsAvailable
 */
export interface NoJobsAvailable {
  /**
   *
   * @type {string}
   * @memberof NoJobsAvailable
   */
  message: string;
}
/**
 *
 * @export
 * @interface NotFound
 */
export interface NotFound {
  /**
   *
   * @type {string}
   * @memberof NotFound
   */
  message: string;
}
/**
 *
 * @export
 * @interface OIDCMachineToken
 */
export interface OIDCMachineToken {
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof OIDCMachineToken
   */
  scope: string;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  expiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  refreshExpiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCMachineToken
   */
  notBeforePolicy: number;
}
/**
 *
 * @export
 * @interface OIDCToken
 */
export interface OIDCToken {
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  idToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  sessionState: string;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  expiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  refreshExpiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  notBeforePolicy: number;
}
/**
 *
 * @export
 * @interface OpenRepairTaskExists
 */
export interface OpenRepairTaskExists {
  /**
   *
   * @type {string}
   * @memberof OpenRepairTaskExists
   */
  message: string;
}
/**
 *
 * @export
 * @interface OpenServiceTaskExists
 */
export interface OpenServiceTaskExists {
  /**
   *
   * @type {string}
   * @memberof OpenServiceTaskExists
   */
  message: string;
}
/**
 *
 * @export
 * @interface OpenTourFound
 */
export interface OpenTourFound {
  /**
   *
   * @type {string}
   * @memberof OpenTourFound
   */
  message: string;
}
/**
 *
 * @export
 * @interface OutputRepairTaskTransition
 */
export interface OutputRepairTaskTransition {
  /**
   *
   * @type {string}
   * @memberof OutputRepairTaskTransition
   */
  label: string;
  /**
   *
   * @type {RepairTaskState}
   * @memberof OutputRepairTaskTransition
   */
  targetState: RepairTaskState;
  /**
   *
   * @type {string}
   * @memberof OutputRepairTaskTransition
   */
  requiredUserRole: string;
  /**
   *
   * @type {boolean}
   * @memberof OutputRepairTaskTransition
   */
  isAllowed: boolean;
}

/**
 *
 * @export
 * @interface PaginatedResultAssetInfo
 */
export interface PaginatedResultAssetInfo {
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof PaginatedResultAssetInfo
   */
  items?: Array<AssetInfo>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultAssetInfo
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultPersistedVehicleReport
 */
export interface PaginatedResultPersistedVehicleReport {
  /**
   *
   * @type {Array<PersistedVehicleReport>}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  items?: Array<PersistedVehicleReport>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultRepairTask
 */
export interface PaginatedResultRepairTask {
  /**
   *
   * @type {Array<RepairTask>}
   * @memberof PaginatedResultRepairTask
   */
  items?: Array<RepairTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultRepairTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultServiceTask
 */
export interface PaginatedResultServiceTask {
  /**
   *
   * @type {Array<ServiceTask>}
   * @memberof PaginatedResultServiceTask
   */
  items?: Array<ServiceTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultServiceTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultSubTask
 */
export interface PaginatedResultSubTask {
  /**
   *
   * @type {Array<SubTask>}
   * @memberof PaginatedResultSubTask
   */
  items?: Array<SubTask>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultSubTask
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultTaskAssetInfo
 */
export interface PaginatedResultTaskAssetInfo {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PaginatedResultTaskAssetInfo
   */
  items?: Array<TaskAssetInfo>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTaskAssetInfo
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultTour
 */
export interface PaginatedResultTour {
  /**
   *
   * @type {Array<Tour>}
   * @memberof PaginatedResultTour
   */
  items?: Array<Tour>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultWorkshopEvent
 */
export interface PaginatedResultWorkshopEvent {
  /**
   *
   * @type {Array<WorkshopEvent>}
   * @memberof PaginatedResultWorkshopEvent
   */
  items?: Array<WorkshopEvent>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedTourFilterBody
 */
export interface PaginatedTourFilterBody {
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof PaginatedTourFilterBody
   */
  tourType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof PaginatedTourFilterBody
   */
  includeFinished: boolean;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  batteryLevel?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  assignee?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  vehicleLicencePlate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  fromDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  toDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  serviceVehicleLicencePlate?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PaginatedTourFilterBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  missionId?: number;
}

/**
 *
 * @export
 * @interface ParameterNotAllowed
 */
export interface ParameterNotAllowed {
  /**
   *
   * @type {string}
   * @memberof ParameterNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface PersistedVehicleReport
 */
export interface PersistedVehicleReport {
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleReportId: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reporter: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleId?: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reportedTime: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedThrough?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  incidents?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  imageUrls?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  reportedDuringTourId?: number;
}
/**
 *
 * @export
 * @interface PickupForRepairTaskOverview
 */
export interface PickupForRepairTaskOverview {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  pickupTasksForWaitingRepairTask?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  pickupTasksWithoutWaitingRepairTask?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof PickupForRepairTaskOverview
   */
  noPickupTaskForWaitingRepairTask?: Array<AssetInfo>;
}
/**
 *
 * @export
 * @interface Polygon
 */
export interface Polygon {
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  polygonId: number;
  /**
   *
   * @type {boolean}
   * @memberof Polygon
   */
  isCutout: boolean;
  /**
   *
   * @type {Array<Coordinate>}
   * @memberof Polygon
   */
  coordinates?: Array<Coordinate>;
}
/**
 *
 * @export
 * @interface PreconditionCheckResult
 */
export interface PreconditionCheckResult {
  /**
   *
   * @type {boolean}
   * @memberof PreconditionCheckResult
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  reasons?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  issuesToBeCanceled?: Array<string>;
}
/**
 *
 * @export
 * @interface PreconditionFailed
 */
export interface PreconditionFailed {
  /**
   *
   * @type {string}
   * @memberof PreconditionFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ProblemListTodo
 */
export interface ProblemListTodo {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodo
   */
  todoId: number;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  content: string;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodo
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoBody
 */
export interface ProblemListTodoBody {
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {AvailableProblem}
   * @memberof ProblemListTodoBody
   */
  problem: AvailableProblem;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoResolveBody
 */
export interface ProblemListTodoResolveBody {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodoResolveBody
   */
  todoId: number;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoResolveBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoResolveBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name: string;
}
/**
 *
 * @export
 * @interface PublicUserProfile
 */
export interface PublicUserProfile {
  /**
   *
   * @type {string}
   * @memberof PublicUserProfile
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof PublicUserProfile
   */
  email?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PublicUserProfile
   */
  groups?: Array<string>;
}
/**
 *
 * @export
 * @interface ReasonBody
 */
export interface ReasonBody {
  /**
   *
   * @type {string}
   * @memberof ReasonBody
   */
  reason: string;
}
/**
 * @export
 * @enum {string}
 */
export const ReasonForRepair = {
  SituativeRepair: 'SituativeRepair',
  Accident: 'Accident',
  OEMWarrantyCase: 'OEMWarrantyCase',
  ForceMajeure: 'ForceMajeure',
  Inspection: 'Inspection',
  Vandalism: 'Vandalism',
  Thievery: 'Thievery',
  DesignFaultFixing: 'DesignFaultFixing',
  FollowUpRepair: 'FollowUpRepair'
} as const;
export type ReasonForRepair = (typeof ReasonForRepair)[keyof typeof ReasonForRepair];

/**
 *
 * @export
 * @interface RepairTask
 */
export interface RepairTask {
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  title: string;
  /**
   *
   * @type {RepairTaskState}
   * @memberof RepairTask
   */
  state: RepairTaskState;
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  priority: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  description?: string;
  /**
   *
   * @type {ReasonForRepair}
   * @memberof RepairTask
   */
  reasonForRepair: ReasonForRepair;
  /**
   *
   * @type {number}
   * @memberof RepairTask
   */
  mileage: number;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  vehicleVin: string;
  /**
   *
   * @type {Workshop}
   * @memberof RepairTask
   */
  workshop: Workshop;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof RepairTask
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  assignee?: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  reporter: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTask
   */
  updatedAt: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof RepairTask
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<RepairTaskProblem>}
   * @memberof RepairTask
   */
  problems?: Array<RepairTaskProblem>;
}

/**
 *
 * @export
 * @interface RepairTaskProblem
 */
export interface RepairTaskProblem {
  /**
   *
   * @type {number}
   * @memberof RepairTaskProblem
   */
  problemId: number;
  /**
   *
   * @type {number}
   * @memberof RepairTaskProblem
   */
  repairTaskId: number;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  labelWhenAdded: string;
  /**
   *
   * @type {RepairTaskProblemState}
   * @memberof RepairTaskProblem
   */
  state: RepairTaskProblemState;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof RepairTaskProblem
   */
  updatedBy: string;
}
/**
 * @export
 * @emum {string}
 */
export const RepairTaskProblemState = {
  Open: 'Open',
  WaitingForSparePart: 'WaitingForSparePart',
  WaitingForOrderedPart: 'WaitingForOrderedPart',
  Failed: 'Failed',
  Done: 'Done',
  CannotReproduce: 'CannotReproduce'
} as const;
export type RepairTaskProblemState =
  (typeof RepairTaskProblemState)[keyof typeof RepairTaskProblemState];

/**
 *
 * @export
 * @enum {string}
 */

export const RepairTaskState = {
  AwaitingFirstPrequalification: 'AwaitingFirstPrequalification',
  AwaitingSecondPrequalification: 'AwaitingSecondPrequalification',
  WaitingForSpareParts: 'WaitingForSpareParts',
  WaitingForScooter: 'WaitingForScooter',
  AwaitingRepair: 'AwaitingRepair',
  RepairInOperation: 'RepairInOperation',
  InCheckup: 'InCheckup',
  Closed: 'Closed',
  WaitingForExternalWorkshop: 'WaitingForExternalWorkshop',
  RepairPaused: 'RepairPaused',
  PendingOemRepair: 'PendingOEMRepair',
  OemRepairInProgress: 'OEMRepairInProgress',
  FinishedOemRepair: 'FinishedOEMRepair',
  WaitingForHoo: 'WaitingForHOO',
  InExternalWorkshopTransferQueue: 'InExternalWorkshopTransferQueue',
  AwaitingOnStreetRepair: 'AwaitingOnStreetRepair',
  OnStreetRepairInOperation: 'OnStreetRepairInOperation'
} as const;

export type RepairTaskState = (typeof RepairTaskState)[keyof typeof RepairTaskState];

/**
 *
 * @export
 * @interface RepairTaskTransitionNotAllowed
 */
export interface RepairTaskTransitionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof RepairTaskTransitionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface RepairTicketFailBody
 */
export interface RepairTicketFailBody {
  /**
   *
   * @type {string}
   * @memberof RepairTicketFailBody
   */
  reason: string;
}
/**
 *
 * @export
 * @interface RequestBlocked
 */
export interface RequestBlocked {
  /**
   *
   * @type {string}
   * @memberof RequestBlocked
   */
  message: string;
}
/**
 *
 * @export
 * @interface ReservationLocation
 */
export interface ReservationLocation {
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  lng?: number;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  houseNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationLocation
   */
  city?: string;
  /**
   *
   * @type {number}
   * @memberof ReservationLocation
   */
  county?: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationActivity
 */
export interface RouteOptimizationActivity {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  location_id: string;
  /**
   *
   * @type {RouteOptimizationAddress}
   * @memberof RouteOptimizationActivity
   */
  address: RouteOptimizationAddress;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  distance: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  driving_time: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationAddress
 */
export interface RouteOptimizationAddress {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationAddress
   */
  location_id: string;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lon: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationPoint
 */
export interface RouteOptimizationPoint {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationPoint
   */
  type: string;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof RouteOptimizationPoint
   */
  coordinates?: Array<Array<number>>;
}
/**
 *
 * @export
 * @interface RouteOptimizationResult
 */
export interface RouteOptimizationResult {
  /**
   *
   * @type {RouteOptimizationSolution}
   * @memberof RouteOptimizationResult
   */
  solution: RouteOptimizationSolution;
}
/**
 *
 * @export
 * @interface RouteOptimizationRoute
 */
export interface RouteOptimizationRoute {
  /**
   *
   * @type {Array<RouteOptimizationActivity>}
   * @memberof RouteOptimizationRoute
   */
  activities?: Array<RouteOptimizationActivity>;
  /**
   *
   * @type {Array<RouteOptimizationPoint>}
   * @memberof RouteOptimizationRoute
   */
  points?: Array<RouteOptimizationPoint>;
}
/**
 *
 * @export
 * @interface RouteOptimizationSolution
 */
export interface RouteOptimizationSolution {
  /**
   *
   * @type {Array<RouteOptimizationRoute>}
   * @memberof RouteOptimizationSolution
   */
  routes?: Array<RouteOptimizationRoute>;
}
/**
 *
 * @export
 * @interface ServiceEmployee
 */
export interface ServiceEmployee {
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  email: string;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof ServiceEmployee
   */
  experienceLevel: ExperienceLevel;
}
/**
 *
 * @export
 * @interface ServiceHub
 */
export interface ServiceHub {
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  hubId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  name: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceHub
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isActive: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  deletedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isServicePoint: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isWorkshop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isExternalWorkshop: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  jiraGroupInCharge?: string;
}
/**
 *
 * @export
 * @interface ServiceMapTaskOverview
 */
export interface ServiceMapTaskOverview {
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  tasksInSync?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<TaskAssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  tasksOutOfSync?: Array<TaskAssetInfo>;
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof ServiceMapTaskOverview
   */
  assetsWithoutTasks?: Array<AssetInfo>;
  /**
   *
   * @type {Grid}
   * @memberof ServiceMapTaskOverview
   */
  grid?: Grid;
}
/**
 *
 * @export
 * @interface ServiceOverview
 */
export interface ServiceOverview {
  /**
   *
   * @type {number}
   * @memberof ServiceOverview
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof ServiceOverview
   */
  missionType: MissionType;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof ServiceOverview
   */
  availableVehicles?: Array<Vehicle>;
  /**
   *
   * @type {Grid}
   * @memberof ServiceOverview
   */
  grid: Grid;
}

/**
 *
 * @export
 * @interface ServiceParkingZone
 */
export interface ServiceParkingZone {
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  parkingZoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  hubId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  label: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceParkingZone
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isDropoff: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServicePhone
 */
export interface ServicePhone {
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  servicePhoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  phoneNumber: string;
  /**
   *
   * @type {boolean}
   * @memberof ServicePhone
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  deletedAt?: string;
}
/**
 * @export
 * @enum {string}
 */
export const ServiceSourceType = { Required: 'Required', Optional: 'Optional' } as const;
export type ServiceSourceType = (typeof ServiceSourceType)[keyof typeof ServiceSourceType];
/**
 *
 * @export
 * @interface ServiceTask
 */
export interface ServiceTask {
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  taskId: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof ServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof ServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof ServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof ServiceTask
   */
  sourceType?: ServiceSourceType;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedBy: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ServiceTask
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof ServiceTask
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {Asset}
   * @memberof ServiceTask
   */
  serviceAsset: Asset;
  /**
   *
   * @type {Array<SubTask>}
   * @memberof ServiceTask
   */
  subTasks?: Array<SubTask>;
}

/**
 *
 * @export
 * @interface ServiceVehicle
 */
export interface ServiceVehicle {
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceVehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof ServiceVehicle
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServiceVehiclesFilterSet
 */
export interface ServiceVehiclesFilterSet {
  /**
   *
   * @type {MissionType}
   * @memberof ServiceVehiclesFilterSet
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ServiceVehiclesFilterSet
   */
  vehicleTypeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface SourceTypeNotSet
 */
export interface SourceTypeNotSet {
  /**
   *
   * @type {string}
   * @memberof SourceTypeNotSet
   */
  message: string;
}
/**
 *
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  statusId: number;
  /**
   *
   * @type {VehicleStatus}
   * @memberof StatusInfo
   */
  statusName: VehicleStatus;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  amount: number;
}
/**
 *
 * @export
 * @interface SteeringTile
 */
export interface SteeringTile {
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  steeringTileId?: number;
  /**
   *
   * @type {string}
   * @memberof SteeringTile
   */
  tileId: string;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTile
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {GpsPosition}
   * @memberof SteeringTile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  usedByTourId?: number;
}

/**
 *
 * @export
 * @interface SteeringTileBody
 */
export interface SteeringTileBody {
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  steeringTileId?: number;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTileBody
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {string}
   * @memberof SteeringTileBody
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLat: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLon: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  index: number;
}

/**
 *
 * @export
 * @enum {string}
 */

export const SteeringTileType = {
  NoGo: 'NoGo',
  MustGo: 'MustGo'
} as const;

export type SteeringTileType = (typeof SteeringTileType)[keyof typeof SteeringTileType];

/**
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   *
   * @type {number}
   * @memberof Step
   */
  stepId?: number;
  /**
   *
   * @type {StepType}
   * @memberof Step
   */
  stepType: StepType;
  /**
   *
   * @type {StepCategory}
   * @memberof Step
   */
  stepCategory: StepCategory;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Step
   */
  stepDescription: LocalizedDescription;
  /**
   *
   * @type {boolean}
   * @memberof Step
   */
  isDoneOrFailed: boolean;
  /**
   *
   * @type {StepState}
   * @memberof Step
   */
  state: StepState;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  startAction: TransitionAction;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  autoTriggerActionsOnStart?: Array<TriggerAction>;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  completeAction: TransitionAction;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  autoTriggerActionsOnComplete?: Array<TriggerAction>;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  triggerActions?: Array<TriggerAction>;
  /**
   *
   * @type {Array<TransitionAction>}
   * @memberof Step
   */
  cancelActions?: Array<TransitionAction>;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  canceledAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  cancelReason?: string;
  /**
   *
   * @type {Vehicle}
   * @memberof Step
   */
  vehicle?: Vehicle;
  /**
   *
   * @type {GpsPosition}
   * @memberof Step
   */
  gpsPosition?: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Step
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  resolution?: string;
  /**
   *
   * @type {number}
   * @memberof Step
   */
  createdBySubTaskId?: number;
}

/**
 * @export
 * @enum {string}
 */
export const StepCategory = {
  DoSomething: 'DoSomething',
  BatterySwap: 'BatterySwap',
  ReplaceHelmets: 'ReplaceHelmets',
  DoSomethingWithSelection: 'DoSomethingWithSelection',
  DriveToScooter: 'DriveToScooter'
} as const;
export type StepCategory = (typeof StepCategory)[keyof typeof StepCategory];

/**
 *
 * @export
 * @enum {string}
 */

export const StepState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Considered: 'Considered'
} as const;

export type StepState = (typeof StepState)[keyof typeof StepState];

/**
 *
 * @export
 * @enum {string}
 */

export const StepType = {
  PreStep: 'PreStep',
  AtStep: 'AtStep',
  PostStep: 'PostStep'
} as const;

export type StepType = (typeof StepType)[keyof typeof StepType];

/**
 *
 * @export
 * @interface SubTask
 */
export interface SubTask {
  /**
   *
   * @type {number}
   * @memberof SubTask
   */
  subTaskId: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof SubTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  assetVin: string;
  /**
   *
   * @type {AssignmentState}
   * @memberof SubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  updatedBy: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof SubTask
   */
  usedByTaskId?: number;
  /**
   *
   * @type {string}
   * @memberof SubTask
   */
  licencePlate?: string;
  /**
   *
   * @type {StepCategory}
   * @memberof SubTask
   */
  category: StepCategory;
}

/**
 *
 * @export
 * @interface TaskAssetInfo
 */
export interface TaskAssetInfo {
  /**
   *
   * @type {ServiceTask}
   * @memberof TaskAssetInfo
   */
  task: ServiceTask;
  /**
   *
   * @type {AssetInfo}
   * @memberof TaskAssetInfo
   */
  assetInfo: AssetInfo;
}
/**
 *
 * @export
 * @interface TaskLog
 */
export interface TaskLog {
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  taskId: number;
  /**
   *
   * @type {LogTaskChangeType}
   * @memberof TaskLog
   */
  taskChange: LogTaskChangeType;
  /**
   *
   * @type {Action}
   * @memberof TaskLog
   */
  loggedAction: Action;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  at: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  by: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  summary: string;
}
/**
 *
 * @export
 * @interface TaskStatistics
 */
export interface TaskStatistics {
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  pickup?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  swap?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  custom?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  relocate?: Array<TaskStatisticsEntry>;
  /**
   *
   * @type {Array<TaskStatisticsEntry>}
   * @memberof TaskStatistics
   */
  subTasks?: Array<TaskStatisticsEntry>;
}
/**
 *
 * @export
 * @interface TaskStatisticsEntry
 */
export interface TaskStatisticsEntry {
  /**
   *
   * @type {TaskStatisticsState}
   * @memberof TaskStatisticsEntry
   */
  state: TaskStatisticsState;
  /**
   *
   * @type {number}
   * @memberof TaskStatisticsEntry
   */
  percentage: number;
  /**
   *
   * @type {number}
   * @memberof TaskStatisticsEntry
   */
  total: number;
}
/**
 * @type TaskStatisticsState
 * @export
 */
export type TaskStatisticsState = object;

/**
 *
 * @export
 * @interface TelematicsPosition
 */
export interface TelematicsPosition {
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface TextBody
 */
export interface TextBody {
  /**
   *
   * @type {string}
   * @memberof TextBody
   */
  body: string;
}
/**
 *
 * @export
 * @interface Tile
 */
export interface Tile {
  /**
   *
   * @type {string}
   * @memberof Tile
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  y: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topRightCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomRightCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  numberOfVehicles: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  normalizedDensity: number;
}
/**
 *
 * @export
 * @interface Timespan
 */
export interface Timespan {
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  to?: string;
}
/**
 * @type TodoStatusName
 * @export
 */
export type TodoStatusName = object;

/**
 *
 * @export
 * @interface Tour
 */
export interface Tour {
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof Tour
   */
  tourType: MissionType;
  /**
   *
   * @type {TourParameters}
   * @memberof Tour
   */
  tourParameters: TourParameters;
  /**
   *
   * @type {Array<Job>}
   * @memberof Tour
   */
  jobs?: Array<Job>;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  requestedAt: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failReason?: string;
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  tourId?: number;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof Tour
   */
  usedSteeringTiles?: Array<SteeringTile>;
  /**
   *
   * @type {RouteOptimizationResult}
   * @memberof Tour
   */
  routeOptimizationResult?: RouteOptimizationResult;
  /**
   *
   * @type {TourState}
   * @memberof Tour
   */
  state: TourState;
}

/**
 *
 * @export
 * @interface TourAdaptionBodyWithTasks
 */
export interface TourAdaptionBodyWithTasks {
  /**
   *
   * @type {Array<number>}
   * @memberof TourAdaptionBodyWithTasks
   */
  removeJobIds?: Array<number>;
  /**
   *
   * @type {Array<InjectedTaskBasedJob>}
   * @memberof TourAdaptionBodyWithTasks
   */
  injectedTaskBasedJobs?: Array<InjectedTaskBasedJob>;
  /**
   *
   * @type {Array<JobOrdering>}
   * @memberof TourAdaptionBodyWithTasks
   */
  remainingJobsOrdering?: Array<JobOrdering>;
  /**
   *
   * @type {ServiceEmployee}
   * @memberof TourAdaptionBodyWithTasks
   */
  newAssignee?: ServiceEmployee;
}
/**
 *
 * @export
 * @interface TourCorridor
 */
export interface TourCorridor {
  /**
   *
   * @type {Array<TourCorridorPolygon>}
   * @memberof TourCorridor
   */
  polygons?: Array<TourCorridorPolygon>;
  /**
   *
   * @type {Array<TourCorridorCircle>}
   * @memberof TourCorridor
   */
  circles?: Array<TourCorridorCircle>;
  /**
   *
   * @type {CorridorPolygon}
   * @memberof TourCorridor
   */
  boundingBox: CorridorPolygon;
}
/**
 *
 * @export
 * @interface TourCorridorAnchor
 */
export interface TourCorridorAnchor {
  /**
   *
   * @type {Job}
   * @memberof TourCorridorAnchor
   */
  job?: Job;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourCorridorAnchor
   */
  hub?: ServiceHub;
}
/**
 *
 * @export
 * @interface TourCorridorCircle
 */
export interface TourCorridorCircle {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorCircle
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof TourCorridorCircle
   */
  radius: number;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorCircle
   */
  anchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourCorridorPolygon
 */
export interface TourCorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomRight: GpsPosition;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  leftAnchor: TourCorridorAnchor;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  rightAnchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourInteractionNotAllowed
 */
export interface TourInteractionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TourInteractionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface TourParameterOptionsBundle
 */
export interface TourParameterOptionsBundle {
  /**
   *
   * @type {BusinessLocation1}
   * @memberof TourParameterOptionsBundle
   */
  location: BusinessLocation1;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameterOptionsBundle
   */
  serviceEmployees?: Array<ServiceEmployee>;
  /**
   *
   * @type {Array<ServicePhone>}
   * @memberof TourParameterOptionsBundle
   */
  servicePhones?: Array<ServicePhone>;
  /**
   *
   * @type {Array<ServiceVehicle>}
   * @memberof TourParameterOptionsBundle
   */
  serviceVehicle?: Array<ServiceVehicle>;
  /**
   *
   * @type {Array<ServiceHub>}
   * @memberof TourParameterOptionsBundle
   */
  serviceHubs?: Array<ServiceHub>;
}
/**
 *
 * @export
 * @interface TourParameters
 */
export interface TourParameters {
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourParameterId?: number;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameters
   */
  assignees?: Array<ServiceEmployee>;
  /**
   *
   * @type {ServiceVehicle}
   * @memberof TourParameters
   */
  serviceVehicle: ServiceVehicle;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  capacity: number;
  /**
   *
   * @type {ServicePhone}
   * @memberof TourParameters
   */
  phone: ServicePhone;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParameters
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  endHub: ServiceHub;
  /**
   *
   * @type {Array<VehicleType>}
   * @memberof TourParameters
   */
  vehicleTypes?: Array<VehicleType>;
}
/**
 *
 * @export
 * @interface TourParametersBody
 */
export interface TourParametersBody {
  /**
   *
   * @type {Array<string>}
   * @memberof TourParametersBody
   */
  assignees?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  serviceVehicleId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  phoneId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  endHubId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  startHubId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  capacity: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParametersBody
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {Array<number>}
   * @memberof TourParametersBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {MissionType}
   * @memberof TourParametersBody
   */
  missionType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof TourParametersBody
   */
  debug?: boolean;
}

/**
 *
 * @export
 * @interface TourResponse
 */
export interface TourResponse {
  /**
   *
   * @type {boolean}
   * @memberof TourResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TourResponse
   */
  error?: string;
  /**
   *
   * @type {CompiledTour}
   * @memberof TourResponse
   */
  updatedTour: CompiledTour;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TourState = {
  Requested: 'Requested',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed'
} as const;

export type TourState = (typeof TourState)[keyof typeof TourState];

/**
 *
 * @export
 * @interface TourStats
 */
export interface TourStats {
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourStatsId?: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageBefore: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageAfter: number;
}
/**
 *
 * @export
 * @interface TourTaskLogs
 */
export interface TourTaskLogs {
  /**
   *
   * @type {number}
   * @memberof TourTaskLogs
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TourTaskLogs
   */
  taskId: number;
  /**
   *
   * @type {Array<TaskLog>}
   * @memberof TourTaskLogs
   */
  taskLogs?: Array<TaskLog>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TransitionAction = {
  JiraTransitionActivatePickupTour: 'JiraTransitionActivatePickupTour',
  JiraTransitionApproachingScooter: 'JiraTransitionApproachingScooter',
  JiraTransitionScooterFound: 'JiraTransitionScooterFound',
  JiraTransitionPickedUp: 'JiraTransitionPickedUp',
  JiraTranistionFailedButDroppedOff: 'JiraTranistionFailedButDroppedOff',
  JiraTransitionDroppedOff: 'JiraTransitionDroppedOff',
  JiraTransitionScooterInaccessible: 'JiraTransitionScooterInaccessible',
  JiraTransitionScooterNotFound: 'JiraTransitionScooterNotFound',
  JiraTransitionCancelTour: 'JiraTransitionCancelTour',
  JiraTransitionSkipScooter: 'JiraTransitionSkipScooter',
  JiraTransitionPickupFailed: 'JiraTransitionPickupFailed',
  JiraTransitionClearingDropOff: 'JiraTransitionClearingDropOff',
  ServiceOnStreetTaskListRequestTour: 'ServiceOnStreetTaskListRequestTour',
  ServiceOnStreetTaskListActivateTour: 'ServiceOnStreetTaskListActivateTour',
  ServiceOnStreetTaskListCancelTour: 'ServiceOnStreetTaskListCancelTour',
  ServiceOnStreetTaskListCancelTourRequest: 'ServiceOnStreetTaskListCancelTourRequest',
  ServiceOnStreetTaskListApproachingOptional: 'ServiceOnStreetTaskListApproachingOptional',
  ServiceOnStreetTaskListCancelApproachingOptional:
    'ServiceOnStreetTaskListCancelApproachingOptional',
  ServiceOnStreetTaskListApproachingScooter: 'ServiceOnStreetTaskListApproachingScooter',
  ServiceOnStreetTaskListPerformingService: 'ServiceOnStreetTaskListPerformingService',
  ServiceOnStreetTaskListScooterNotFound: 'ServiceOnStreetTaskListScooterNotFound',
  ServiceOnStreetTaskListScooterInaccessible: 'ServiceOnStreetTaskListScooterInaccessible',
  ServiceOnStreetTaskListServiceSucceeded: 'ServiceOnStreetTaskListServiceSucceeded',
  ServiceOnStreetTaskListServiceFailed: 'ServiceOnStreetTaskListServiceFailed',
  ServiceTaskStartProgress: 'ServiceTaskStartProgress',
  ServiceTaskClose: 'ServiceTaskClose',
  ServiceTaskFail: 'ServiceTaskFail',
  ServiceTaskCancel: 'ServiceTaskCancel',
  JiraTransitionRequestOnsitePrequalification: 'JiraTransitionRequestOnsitePrequalification',
  BatterySwapTaskStartProgress: 'BatterySwapTaskStartProgress',
  BatterySwapTaskClose: 'BatterySwapTaskClose',
  BatterySwapTaskFail: 'BatterySwapTaskFail',
  BatterySwapTaskCancel: 'BatterySwapTaskCancel',
  RelocateActivate: 'RelocateActivate',
  RepairModeActivate: 'RepairModeActivate',
  Nothing: 'Nothing'
} as const;

export type TransitionAction = (typeof TransitionAction)[keyof typeof TransitionAction];

/**
 *
 * @export
 * @interface TransitionActionBody
 */
export interface TransitionActionBody {
  /**
   *
   * @type {Array<TransitionActionField>}
   * @memberof TransitionActionBody
   */
  fields?: Array<TransitionActionField>;
  /**
   *
   * @type {string}
   * @memberof TransitionActionBody
   */
  resolution?: string;
}
/**
 *
 * @export
 * @interface TransitionActionField
 */
export interface TransitionActionField {
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TransitionActionField
   */
  encodedValue?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TriggerAction = {
  DisableAlarm: 'DisableAlarm',
  EnableAlarm: 'EnableAlarm',
  Lock: 'Lock',
  Unlock: 'Unlock',
  UnlockSaddle: 'UnlockSaddle',
  Nothing: 'Nothing'
} as const;

export type TriggerAction = (typeof TriggerAction)[keyof typeof TriggerAction];

/**
 *
 * @export
 * @interface TriggerActionBody
 */
export interface TriggerActionBody {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody
   */
  action: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionBody1
 */
export interface TriggerActionBody1 {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody1
   */
  requestedTriggerAction: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionNotAllowed
 */
export interface TriggerActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TriggerActionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface TriggerEvent
 */
export interface TriggerEvent {
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  stepId: number;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  username: string;
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerEvent
   */
  triggerAction: TriggerAction;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  triggeredAt: string;
  /**
   *
   * @type {ActionResult}
   * @memberof TriggerEvent
   */
  triggerResult: ActionResult;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  error?: string;
}

/**
 *
 * @export
 * @interface Unauthorized
 */
export interface Unauthorized {
  /**
   *
   * @type {string}
   * @memberof Unauthorized
   */
  message: string;
}
/**
 *
 * @export
 * @interface Unknown
 */
export interface Unknown {
  /**
   *
   * @type {string}
   * @memberof Unknown
   */
  message: string;
}
/**
 *
 * @export
 * @interface UpdateSubTask
 */
export interface UpdateSubTask {
  /**
   *
   * @type {number}
   * @memberof UpdateSubTask
   */
  subTaskId: number;
  /**
   *
   * @type {AssignmentState}
   * @memberof UpdateSubTask
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof UpdateSubTask
   */
  cancelReason?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSubTask
   */
  reopen?: boolean;
}

/**
 *
 * @export
 * @interface UpdateSubTasks
 */
export interface UpdateSubTasks {
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateSubTasks
   */
  subTaskIds?: Array<number>;
  /**
   *
   * @type {AssignmentState}
   * @memberof UpdateSubTasks
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof UpdateSubTasks
   */
  cancelReason?: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateSubTasks
   */
  reopen?: boolean;
}

/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  emailVerified: boolean;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  givenName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserProfile
   */
  groups?: Array<string>;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserProfile
   */
  experienceLevel: ExperienceLevel;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface UserTargetGroup
 */
export interface UserTargetGroup {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroup
   */
  targetGroupId?: number;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof UserTargetGroup
   */
  serviceLocation: BusinessLocation1;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroup
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof UserTargetGroup
   */
  employees?: Array<ServiceEmployee>;
}
/**
 *
 * @export
 * @interface UserTargetGroupBody
 */
export interface UserTargetGroupBody {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  targetGroupId?: number;
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroupBody
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<string>}
   * @memberof UserTargetGroupBody
   */
  employees?: Array<string>;
}
/**
 *
 * @export
 * @interface ValidationResponse
 */
export interface ValidationResponse {
  /**
   *
   * @type {Array<AssetInfo>}
   * @memberof ValidationResponse
   */
  matched?: Array<AssetInfo>;
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationResponse
   */
  unmatched?: Array<string>;
}
/**
 *
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleTypeId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleStateId: number;
  /**
   *
   * @type {boolean}
   * @memberof Vehicle
   */
  isReserved: boolean;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  batteryLevel: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  mileage?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Vehicle
   */
  visibleDamages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  vin?: string;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  repairTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  serviceTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  pickupTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  relocateTicket?: JiraIssue;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  pickupTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  relocateTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  swapTask?: ServiceTask;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  customTask?: ServiceTask;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  assetUrl?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Vehicle
   */
  serviceSourceType?: ServiceSourceType;
}
/**
 *
 * @export
 * @interface VehicleDamageReport
 */
export interface VehicleDamageReport {
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  damageDescription: string;
  /**
   *
   * @type {number}
   * @memberof VehicleDamageReport
   */
  reservationId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  status: string;
  /**
   *
   * @type {boolean}
   * @memberof VehicleDamageReport
   */
  public: boolean;
  /**
   *
   * @type {string}
   * @memberof VehicleDamageReport
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface VehicleIdentifier
 */
export interface VehicleIdentifier {
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof VehicleIdentifier
   */
  vehicleId?: number;
}
/**
 *
 * @export
 * @interface VehicleReportBody
 */
export interface VehicleReportBody {
  /**
   *
   * @type {VehicleIdentifier}
   * @memberof VehicleReportBody
   */
  vehicleIdentifier: VehicleIdentifier;
  /**
   *
   * @type {Array<string>}
   * @memberof VehicleReportBody
   */
  incidents?: Array<VehicleReportBodyIncidentsEnum>;
  /**
   *
   * @type {string}
   * @memberof VehicleReportBody
   */
  description?: string;
  /**
   *
   * @type {Array<Base64Image>}
   * @memberof VehicleReportBody
   */
  images?: Array<Base64Image>;
  /**
   *
   * @type {GpsPosition1}
   * @memberof VehicleReportBody
   */
  position: GpsPosition1;
  /**
   *
   * @type {number}
   * @memberof VehicleReportBody
   */
  reportedDuringTourId?: number;
}

export const VehicleReportBodyIncidentsEnum = {
  Damage: 'Damage',
  BadlyParked: 'BadlyParked',
  BatteryStolen: 'BatteryStolen',
  PersonalItemsFound: 'PersonalItemsFound',
  MissingHelmet: 'MissingHelmet',
  WorkshopDocumentation: 'WorkshopDocumentation'
} as const;

export type VehicleReportBodyIncidentsEnum =
  (typeof VehicleReportBodyIncidentsEnum)[keyof typeof VehicleReportBodyIncidentsEnum];

/**
 *
 * @export
 * @interface VehicleReportResolveBody
 */
export interface VehicleReportResolveBody {
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolutionType: VehicleReportResolveBodyResolutionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolvedThrough?: string;
}

export const VehicleReportResolveBodyResolutionTypeEnum = {
  JiraRepairTicket: 'jira-repair-ticket',
  WunderfleetDamage: 'wunderfleet-damage',
  NoActionRequired: 'no-action-required'
} as const;

export type VehicleReportResolveBodyResolutionTypeEnum =
  (typeof VehicleReportResolveBodyResolutionTypeEnum)[keyof typeof VehicleReportResolveBodyResolutionTypeEnum];

/**
 *
 * @export
 * @interface VehicleState
 */
export interface VehicleState {
  /**
   *
   * @type {number}
   * @memberof VehicleState
   */
  vehicleStateId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleState
   */
  title: string;
}
/**
 *
 * @export
 * @interface VehicleStatistics
 */
export interface VehicleStatistics {
  /**
   *
   * @type {number}
   * @memberof VehicleStatistics
   */
  totalNumber: number;
  /**
   *
   * @type {number}
   * @memberof VehicleStatistics
   */
  cityId: number;
  /**
   *
   * @type {Array<StatusInfo>}
   * @memberof VehicleStatistics
   */
  numberPerStatus?: Array<StatusInfo>;
}
/**
 * @type VehicleStatus
 * @export
 */
export type VehicleStatus = object;

/**
 *
 * @export
 * @interface VehicleType
 */
export interface VehicleType {
  /**
   *
   * @type {number}
   * @memberof VehicleType
   */
  vehicleTypeId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  pricingLevel: string;
}
/**
 *
 * @export
 * @interface VehicleTypeEntry
 */
export interface VehicleTypeEntry {
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleTypeEntry
   */
  pricingLevel: VehicleTypeEntryPricingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof VehicleTypeEntry
   */
  vehicleTypeId: number;
}

export const VehicleTypeEntryPricingLevelEnum = {
  Default: 'default',
  Discounted: 'discounted',
  Overpriced: 'overpriced',
  Special: 'special',
  Unknown: 'unknown'
} as const;

export type VehicleTypeEntryPricingLevelEnum =
  (typeof VehicleTypeEntryPricingLevelEnum)[keyof typeof VehicleTypeEntryPricingLevelEnum];

/**
 *
 * @export
 * @interface VehiclesChangeStateBundle
 */
export interface VehiclesChangeStateBundle {
  /**
   *
   * @type {Array<string>}
   * @memberof VehiclesChangeStateBundle
   */
  vins?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof VehiclesChangeStateBundle
   */
  newState: string;
}
/**
 *
 * @export
 * @interface VinMissing
 */
export interface VinMissing {
  /**
   *
   * @type {string}
   * @memberof VinMissing
   */
  message: string;
}
/**
 *
 * @export
 * @interface Workshop
 */
export interface Workshop {
  /**
   *
   * @type {number}
   * @memberof Workshop
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  shortName: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  name: string;
  /**
   *
   * @type {BusinessLocation1}
   * @memberof Workshop
   */
  businessLocation: BusinessLocation1;
  /**
   *
   * @type {Array<BusinessLocation1>}
   * @memberof Workshop
   */
  serviceableBusinessLocations?: Array<BusinessLocation1>;
  /**
   *
   * @type {ServiceHub}
   * @memberof Workshop
   */
  physicalLocation: ServiceHub;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  basicAccessRole: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  privilegedAccessRole: string;
  /**
   *
   * @type {boolean}
   * @memberof Workshop
   */
  isExternal: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Workshop
   */
  deprecated: boolean;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Workshop
   */
  updatedAt: string;
}
/**
 *
 * @export
 * @interface WorkshopEvent
 */
export interface WorkshopEvent {
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  jiraIssueKey: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  workshopGroup?: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  vin: string;
  /**
   *
   * @type {WorkshopEventType}
   * @memberof WorkshopEvent
   */
  eventType: WorkshopEventType;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  eventPayload: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  error?: string;
}
/**
 * @type WorkshopEventType
 * @export
 */
export type WorkshopEventType = object;

/**
 *
 * @export
 * @interface WorkshopTicket
 */
export interface WorkshopTicket {
  /**
   *
   * @type {JiraIssue}
   * @memberof WorkshopTicket
   */
  jiraIssue: JiraIssue;
  /**
   *
   * @type {Vehicle}
   * @memberof WorkshopTicket
   */
  vehicle: Vehicle;
  /**
   *
   * @type {Array<ProblemListTodo>}
   * @memberof WorkshopTicket
   */
  todos?: Array<ProblemListTodo>;
}
/**
 *
 * @export
 * @interface WunderBulkEditStatus
 */
export interface WunderBulkEditStatus {
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedBy: string;
  /**
   *
   * @type {WunderBulkEditStatusType}
   * @memberof WunderBulkEditStatus
   */
  status: WunderBulkEditStatusType;
  /**
   *
   * @type {number}
   * @memberof WunderBulkEditStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkEditRowResult>}
   * @memberof WunderBulkEditStatus
   */
  results?: Array<BulkEditRowResult>;
}
/**
 * @type WunderBulkEditStatusType
 * @export
 */
export type WunderBulkEditStatusType = object;

/**
 *
 * @export
 * @interface WunderVehicleTag
 */
export interface WunderVehicleTag {
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  tagId?: number;
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  vehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof WunderVehicleTag
   */
  tagTypeId?: number;
  /**
   *
   * @type {string}
   * @memberof WunderVehicleTag
   */
  taggedAt?: string;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeEndpoint: async (
      redirectUrl: string,
      state: string,
      nonce: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUrl' is not null or undefined
      assertParamExists('authorizeEndpoint', 'redirectUrl', redirectUrl);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('authorizeEndpoint', 'state', state);
      // verify required parameter 'nonce' is not null or undefined
      assertParamExists('authorizeEndpoint', 'nonce', nonce);
      const localVarPath = `/oidc/authorize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirect_url'] = redirectUrl;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (nonce !== undefined) {
        localVarQueryParameter['nonce'] = nonce;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve AccessToken for service accounts (bots) using the Oauth2 client credentials flows
     * @summary Retrieve AccessToken for service accounts (bots).
     * @param {ClientCredentials} clientCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    botuserEndpoint: async (
      clientCredentials: ClientCredentials,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientCredentials' is not null or undefined
      assertParamExists('botuserEndpoint', 'clientCredentials', clientCredentials);
      const localVarPath = `/oidc/machine-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientCredentials,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {string} redirectUri
     * @param {string} idTokenHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endUserSession: async (
      redirectUri: string,
      idTokenHint: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUri' is not null or undefined
      assertParamExists('endUserSession', 'redirectUri', redirectUri);
      // verify required parameter 'idTokenHint' is not null or undefined
      assertParamExists('endUserSession', 'idTokenHint', idTokenHint);
      const localVarPath = `/oidc/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirect_uri'] = redirectUri;
      }

      if (idTokenHint !== undefined) {
        localVarQueryParameter['id_token_hint'] = idTokenHint;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getUserProfile', 'accessToken', accessToken);
      const localVarPath = `/oidc/user-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenEndpoint: async (
      refreshToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshToken' is not null or undefined
      assertParamExists('refreshTokenEndpoint', 'refreshToken', refreshToken);
      const localVarPath = `/oidc/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (refreshToken !== undefined) {
        localVarQueryParameter['refresh_token'] = refreshToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenEndpoint: async (
      code: string,
      redirectUrl: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('tokenEndpoint', 'code', code);
      // verify required parameter 'redirectUrl' is not null or undefined
      assertParamExists('tokenEndpoint', 'redirectUrl', redirectUrl);
      const localVarPath = `/oidc/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirect_url'] = redirectUrl;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration);
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorizeEndpoint(
      redirectUrl: string,
      state: string,
      nonce: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeEndpoint(
        redirectUrl,
        state,
        nonce,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AuthenticationApi.authorizeEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieve AccessToken for service accounts (bots) using the Oauth2 client credentials flows
     * @summary Retrieve AccessToken for service accounts (bots).
     * @param {ClientCredentials} clientCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async botuserEndpoint(
      clientCredentials: ClientCredentials,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OIDCMachineToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.botuserEndpoint(
        clientCredentials,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AuthenticationApi.botuserEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {string} redirectUri
     * @param {string} idTokenHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endUserSession(
      redirectUri: string,
      idTokenHint: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.endUserSession(
        redirectUri,
        idTokenHint,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AuthenticationApi.endUserSession']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AuthenticationApi.getUserProfile']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshTokenEndpoint(
      refreshToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OIDCToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokenEndpoint(
        refreshToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['AuthenticationApi.refreshTokenEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenEndpoint(
      code: string,
      redirectUrl: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OIDCToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenEndpoint(
        code,
        redirectUrl,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['AuthenticationApi.tokenEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthenticationApiFp(configuration);
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeEndpoint(
      redirectUrl: string,
      state: string,
      nonce: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authorizeEndpoint(redirectUrl, state, nonce, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve AccessToken for service accounts (bots) using the Oauth2 client credentials flows
     * @summary Retrieve AccessToken for service accounts (bots).
     * @param {ClientCredentials} clientCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    botuserEndpoint(
      clientCredentials: ClientCredentials,
      options?: any
    ): AxiosPromise<OIDCMachineToken> {
      return localVarFp
        .botuserEndpoint(clientCredentials, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {string} redirectUri
     * @param {string} idTokenHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endUserSession(redirectUri: string, idTokenHint: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .endUserSession(redirectUri, idTokenHint, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(accessToken: string, options?: any): AxiosPromise<UserProfile> {
      return localVarFp
        .getUserProfile(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenEndpoint(refreshToken: string, options?: any): AxiosPromise<OIDCToken> {
      return localVarFp
        .refreshTokenEndpoint(refreshToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenEndpoint(code: string, redirectUrl: string, options?: any): AxiosPromise<OIDCToken> {
      return localVarFp
        .tokenEndpoint(code, redirectUrl, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * AuthenticationApi - interface
 * @export
 * @interface AuthenticationApi
 */
export interface AuthenticationApiInterface {
  /**
   *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
   * @summary Let\'s you receive an access code via a callback url.
   * @param {string} redirectUrl
   * @param {string} state
   * @param {string} nonce
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  authorizeEndpoint(
    redirectUrl: string,
    state: string,
    nonce: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Retrieve AccessToken for service accounts (bots) using the Oauth2 client credentials flows
   * @summary Retrieve AccessToken for service accounts (bots).
   * @param {ClientCredentials} clientCredentials
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  botuserEndpoint(
    clientCredentials: ClientCredentials,
    options?: AxiosRequestConfig
  ): AxiosPromise<OIDCMachineToken>;

  /**
   *   By directing the browser at this url you can end the users  session at the identity provider level.
   * @summary End the user session at the IDP.
   * @param {string} redirectUri
   * @param {string} idTokenHint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  endUserSession(
    redirectUri: string,
    idTokenHint: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Get active UserProfile, including groups.
   * @summary Get active UserProfile
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  getUserProfile(accessToken: string, options?: AxiosRequestConfig): AxiosPromise<UserProfile>;

  /**
   *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
   * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
   * @param {string} refreshToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  refreshTokenEndpoint(refreshToken: string, options?: AxiosRequestConfig): AxiosPromise<OIDCToken>;

  /**
   *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
   * @summary Obtain the Access, Refresh and ID token using your access code.
   * @param {string} code
   * @param {string} redirectUrl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApiInterface
   */
  tokenEndpoint(
    code: string,
    redirectUrl: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<OIDCToken>;
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI implements AuthenticationApiInterface {
  /**
   *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
   * @summary Let\'s you receive an access code via a callback url.
   * @param {string} redirectUrl
   * @param {string} state
   * @param {string} nonce
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authorizeEndpoint(
    redirectUrl: string,
    state: string,
    nonce: string,
    options?: AxiosRequestConfig
  ) {
    return AuthenticationApiFp(this.configuration)
      .authorizeEndpoint(redirectUrl, state, nonce, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve AccessToken for service accounts (bots) using the Oauth2 client credentials flows
   * @summary Retrieve AccessToken for service accounts (bots).
   * @param {ClientCredentials} clientCredentials
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public botuserEndpoint(clientCredentials: ClientCredentials, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .botuserEndpoint(clientCredentials, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   By directing the browser at this url you can end the users  session at the identity provider level.
   * @summary End the user session at the IDP.
   * @param {string} redirectUri
   * @param {string} idTokenHint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public endUserSession(redirectUri: string, idTokenHint: string, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .endUserSession(redirectUri, idTokenHint, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get active UserProfile, including groups.
   * @summary Get active UserProfile
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public getUserProfile(accessToken: string, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .getUserProfile(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
   * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
   * @param {string} refreshToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public refreshTokenEndpoint(refreshToken: string, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .refreshTokenEndpoint(refreshToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
   * @summary Obtain the Access, Refresh and ID token using your access code.
   * @param {string} code
   * @param {string} redirectUrl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public tokenEndpoint(code: string, redirectUrl: string, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .tokenEndpoint(code, redirectUrl, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BasicsApi - axios parameter creator
 * @export
 */
export const BasicsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get business locations.
     * @summary Get business locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessLocations: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('businessLocations', 'accessToken', accessToken);
      const localVarPath = `/v2/basic/business-locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Disables an Asset Type for use in our service apps.
     * @summary Disables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAssetType: async (
      accessToken: string,
      assetTypeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('disableAssetType', 'accessToken', accessToken);
      // verify required parameter 'assetTypeId' is not null or undefined
      assertParamExists('disableAssetType', 'assetTypeId', assetTypeId);
      const localVarPath = `/v2/basic/asset-types/{assetTypeId}/active`.replace(
        `{${'assetTypeId'}}`,
        encodeURIComponent(String(assetTypeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Enables an Asset Type for use in our service apps.
     * @summary Enables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAssetType: async (
      accessToken: string,
      assetTypeId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('enableAssetType', 'accessToken', accessToken);
      // verify required parameter 'assetTypeId' is not null or undefined
      assertParamExists('enableAssetType', 'assetTypeId', assetTypeId);
      const localVarPath = `/v2/basic/asset-types/{assetTypeId}/active`.replace(
        `{${'assetTypeId'}}`,
        encodeURIComponent(String(assetTypeId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves Asset Types in use with Badgermole and Wunder-Fleet, you can optionally choose also to display deactived types
     * @summary Retrieves Asset Types in use with Badgermole and Wunder-Fleet
     * @param {string} accessToken
     * @param {boolean} [includeDeactivated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssetTypes: async (
      accessToken: string,
      includeDeactivated?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAssetTypes', 'accessToken', accessToken);
      const localVarPath = `/v2/basic/asset-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeDeactivated !== undefined) {
        localVarQueryParameter['includeDeactivated'] = includeDeactivated;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get Employees
     * @summary Get Employees
     * @param {string} accessToken
     * @param {number} [businessLocationId]
     * @param {string} [userRole]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployees: async (
      accessToken: string,
      businessLocationId?: number,
      userRole?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getEmployees', 'accessToken', accessToken);
      const localVarPath = `/v2/basic/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (userRole !== undefined) {
        localVarQueryParameter['userRole'] = userRole;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BasicsApi - functional programming interface
 * @export
 */
export const BasicsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BasicsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get business locations.
     * @summary Get business locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async businessLocations(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLocation1>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.businessLocations(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['BasicsApi.businessLocations']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Disables an Asset Type for use in our service apps.
     * @summary Disables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableAssetType(
      accessToken: string,
      assetTypeId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableAssetType(
        accessToken,
        assetTypeId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['BasicsApi.disableAssetType']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Enables an Asset Type for use in our service apps.
     * @summary Enables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableAssetType(
      accessToken: string,
      assetTypeId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enableAssetType(
        accessToken,
        assetTypeId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['BasicsApi.enableAssetType']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieves Asset Types in use with Badgermole and Wunder-Fleet, you can optionally choose also to display deactived types
     * @summary Retrieves Asset Types in use with Badgermole and Wunder-Fleet
     * @param {string} accessToken
     * @param {boolean} [includeDeactivated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssetTypes(
      accessToken: string,
      includeDeactivated?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetTypes(
        accessToken,
        includeDeactivated,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['BasicsApi.getAssetTypes']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get Employees
     * @summary Get Employees
     * @param {string} accessToken
     * @param {number} [businessLocationId]
     * @param {string} [userRole]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmployees(
      accessToken: string,
      businessLocationId?: number,
      userRole?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicUserProfile>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployees(
        accessToken,
        businessLocationId,
        userRole,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['BasicsApi.getEmployees']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * BasicsApi - factory interface
 * @export
 */
export const BasicsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BasicsApiFp(configuration);
  return {
    /**
     * Get business locations.
     * @summary Get business locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    businessLocations(accessToken: string, options?: any): AxiosPromise<Array<BusinessLocation1>> {
      return localVarFp
        .businessLocations(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Disables an Asset Type for use in our service apps.
     * @summary Disables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAssetType(accessToken: string, assetTypeId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .disableAssetType(accessToken, assetTypeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enables an Asset Type for use in our service apps.
     * @summary Enables an Asset Type
     * @param {string} accessToken
     * @param {number} assetTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAssetType(accessToken: string, assetTypeId: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .enableAssetType(accessToken, assetTypeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves Asset Types in use with Badgermole and Wunder-Fleet, you can optionally choose also to display deactived types
     * @summary Retrieves Asset Types in use with Badgermole and Wunder-Fleet
     * @param {string} accessToken
     * @param {boolean} [includeDeactivated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssetTypes(
      accessToken: string,
      includeDeactivated?: boolean,
      options?: any
    ): AxiosPromise<Array<AssetType>> {
      return localVarFp
        .getAssetTypes(accessToken, includeDeactivated, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Employees
     * @summary Get Employees
     * @param {string} accessToken
     * @param {number} [businessLocationId]
     * @param {string} [userRole]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmployees(
      accessToken: string,
      businessLocationId?: number,
      userRole?: string,
      options?: any
    ): AxiosPromise<Array<PublicUserProfile>> {
      return localVarFp
        .getEmployees(accessToken, businessLocationId, userRole, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * BasicsApi - interface
 * @export
 * @interface BasicsApi
 */
export interface BasicsApiInterface {
  /**
   * Get business locations.
   * @summary Get business locations.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApiInterface
   */
  businessLocations(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<BusinessLocation1>>;

  /**
   * Disables an Asset Type for use in our service apps.
   * @summary Disables an Asset Type
   * @param {string} accessToken
   * @param {number} assetTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApiInterface
   */
  disableAssetType(
    accessToken: string,
    assetTypeId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Enables an Asset Type for use in our service apps.
   * @summary Enables an Asset Type
   * @param {string} accessToken
   * @param {number} assetTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApiInterface
   */
  enableAssetType(
    accessToken: string,
    assetTypeId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Retrieves Asset Types in use with Badgermole and Wunder-Fleet, you can optionally choose also to display deactived types
   * @summary Retrieves Asset Types in use with Badgermole and Wunder-Fleet
   * @param {string} accessToken
   * @param {boolean} [includeDeactivated]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApiInterface
   */
  getAssetTypes(
    accessToken: string,
    includeDeactivated?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<AssetType>>;

  /**
   * Get Employees
   * @summary Get Employees
   * @param {string} accessToken
   * @param {number} [businessLocationId]
   * @param {string} [userRole]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApiInterface
   */
  getEmployees(
    accessToken: string,
    businessLocationId?: number,
    userRole?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<PublicUserProfile>>;
}

/**
 * BasicsApi - object-oriented interface
 * @export
 * @class BasicsApi
 * @extends {BaseAPI}
 */
export class BasicsApi extends BaseAPI implements BasicsApiInterface {
  /**
   * Get business locations.
   * @summary Get business locations.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApi
   */
  public businessLocations(accessToken: string, options?: AxiosRequestConfig) {
    return BasicsApiFp(this.configuration)
      .businessLocations(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Disables an Asset Type for use in our service apps.
   * @summary Disables an Asset Type
   * @param {string} accessToken
   * @param {number} assetTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApi
   */
  public disableAssetType(accessToken: string, assetTypeId: number, options?: AxiosRequestConfig) {
    return BasicsApiFp(this.configuration)
      .disableAssetType(accessToken, assetTypeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables an Asset Type for use in our service apps.
   * @summary Enables an Asset Type
   * @param {string} accessToken
   * @param {number} assetTypeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApi
   */
  public enableAssetType(accessToken: string, assetTypeId: number, options?: AxiosRequestConfig) {
    return BasicsApiFp(this.configuration)
      .enableAssetType(accessToken, assetTypeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves Asset Types in use with Badgermole and Wunder-Fleet, you can optionally choose also to display deactived types
   * @summary Retrieves Asset Types in use with Badgermole and Wunder-Fleet
   * @param {string} accessToken
   * @param {boolean} [includeDeactivated]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApi
   */
  public getAssetTypes(
    accessToken: string,
    includeDeactivated?: boolean,
    options?: AxiosRequestConfig
  ) {
    return BasicsApiFp(this.configuration)
      .getAssetTypes(accessToken, includeDeactivated, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Employees
   * @summary Get Employees
   * @param {string} accessToken
   * @param {number} [businessLocationId]
   * @param {string} [userRole]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BasicsApi
   */
  public getEmployees(
    accessToken: string,
    businessLocationId?: number,
    userRole?: string,
    options?: AxiosRequestConfig
  ) {
    return BasicsApiFp(this.configuration)
      .getEmployees(accessToken, businessLocationId, userRole, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CacheApi - axios parameter creator
 * @export
 */
export const CacheApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get a mapping of vehicle types, their PricingLevel and the corresponding name of the type
     * @summary Gets the cached vehicle type map
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCachedVehicleTypeMap: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCachedVehicleTypeMap', 'accessToken', accessToken);
      const localVarPath = `/v2/cache/active-vehicle-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('refreshCache', 'accessToken', accessToken);
      const localVarPath = `/v2/cache/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CacheApi - functional programming interface
 * @export
 */
export const CacheApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CacheApiAxiosParamCreator(configuration);
  return {
    /**
     * Get a mapping of vehicle types, their PricingLevel and the corresponding name of the type
     * @summary Gets the cached vehicle type map
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCachedVehicleTypeMap(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleTypeEntry>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCachedVehicleTypeMap(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['CacheApi.getCachedVehicleTypeMap']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshCache(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshCache(accessToken, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['CacheApi.refreshCache']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * CacheApi - factory interface
 * @export
 */
export const CacheApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CacheApiFp(configuration);
  return {
    /**
     * Get a mapping of vehicle types, their PricingLevel and the corresponding name of the type
     * @summary Gets the cached vehicle type map
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCachedVehicleTypeMap(
      accessToken: string,
      options?: any
    ): AxiosPromise<Array<VehicleTypeEntry>> {
      return localVarFp
        .getCachedVehicleTypeMap(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache(accessToken: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .refreshCache(accessToken, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CacheApi - interface
 * @export
 * @interface CacheApi
 */
export interface CacheApiInterface {
  /**
   * Get a mapping of vehicle types, their PricingLevel and the corresponding name of the type
   * @summary Gets the cached vehicle type map
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApiInterface
   */
  getCachedVehicleTypeMap(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<VehicleTypeEntry>>;

  /**
   * Refresh VehicleType and PricingLevel cache
   * @summary Refresh VehicleType and PricingLevel cache
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApiInterface
   */
  refreshCache(accessToken: string, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * CacheApi - object-oriented interface
 * @export
 * @class CacheApi
 * @extends {BaseAPI}
 */
export class CacheApi extends BaseAPI implements CacheApiInterface {
  /**
   * Get a mapping of vehicle types, their PricingLevel and the corresponding name of the type
   * @summary Gets the cached vehicle type map
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApi
   */
  public getCachedVehicleTypeMap(accessToken: string, options?: AxiosRequestConfig) {
    return CacheApiFp(this.configuration)
      .getCachedVehicleTypeMap(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refresh VehicleType and PricingLevel cache
   * @summary Refresh VehicleType and PricingLevel cache
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApi
   */
  public refreshCache(accessToken: string, options?: AxiosRequestConfig) {
    return CacheApiFp(this.configuration)
      .refreshCache(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeprecatedJiraBulkActionsV2Api - axios parameter creator
 * @export
 */
export const DeprecatedJiraBulkActionsV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsAbort: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsAbort', 'accessToken', accessToken);
      const localVarPath = `/v2/jira/assets/bulk-create/abort`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsComplete: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsComplete', 'accessToken', accessToken);
      const localVarPath = `/v2/jira/assets/bulk-create/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStart: async (
      accessToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStart', 'accessToken', accessToken);
      // verify required parameter 'bulkCreateAssetsFileValidation' is not null or undefined
      assertParamExists(
        'bulkCreateAssetsStart',
        'bulkCreateAssetsFileValidation',
        bulkCreateAssetsFileValidation
      );
      const localVarPath = `/v2/jira/assets/bulk-create/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkCreateAssetsFileValidation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStatus: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStatus', 'accessToken', accessToken);
      const localVarPath = `/v2/jira/assets/bulk-create/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsValidateFile: async (
      accessToken: string,
      file: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsValidateFile', 'accessToken', accessToken);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('bulkCreateAssetsValidateFile', 'file', file);
      const localVarPath = `/v2/jira/assets/bulk-create/validate-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateServiceOnStreetSubtask: async (
      accessToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateServiceOnStreetSubtask', 'accessToken', accessToken);
      // verify required parameter 'jiraBulkCreateSubtaskBody' is not null or undefined
      assertParamExists(
        'bulkCreateServiceOnStreetSubtask',
        'jiraBulkCreateSubtaskBody',
        jiraBulkCreateSubtaskBody
      );
      const localVarPath = `/v2/jira/service-on-street-task-list/subtasks/bulk-create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jiraBulkCreateSubtaskBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DeprecatedJiraBulkActionsV2Api - functional programming interface
 * @export
 */
export const DeprecatedJiraBulkActionsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeprecatedJiraBulkActionsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsAbort(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsAbort(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateAssetsAbort']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsComplete(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsComplete(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateAssetsComplete']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsStart(
      accessToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsStart(
        accessToken,
        bulkCreateAssetsFileValidation,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateAssetsStart']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsStatus(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsStatus(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateAssetsStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsValidateFile(
      accessToken: string,
      file: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateAssetsFileValidation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsValidateFile(
        accessToken,
        file,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateAssetsValidateFile']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateServiceOnStreetSubtask(
      accessToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateServiceOnStreetSubtask(
        accessToken,
        jiraBulkCreateSubtaskBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraBulkActionsV2Api.bulkCreateServiceOnStreetSubtask']?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * DeprecatedJiraBulkActionsV2Api - factory interface
 * @export
 */
export const DeprecatedJiraBulkActionsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeprecatedJiraBulkActionsV2ApiFp(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsAbort(accessToken: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .bulkCreateAssetsAbort(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsComplete(accessToken: string, options?: any): AxiosPromise<File> {
      return localVarFp
        .bulkCreateAssetsComplete(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStart(
      accessToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .bulkCreateAssetsStart(accessToken, bulkCreateAssetsFileValidation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStatus(accessToken: string, options?: any): AxiosPromise<BulkCreateStatus> {
      return localVarFp
        .bulkCreateAssetsStatus(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsValidateFile(
      accessToken: string,
      file: File,
      options?: any
    ): AxiosPromise<BulkCreateAssetsFileValidation> {
      return localVarFp
        .bulkCreateAssetsValidateFile(accessToken, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateServiceOnStreetSubtask(
      accessToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .bulkCreateServiceOnStreetSubtask(accessToken, jiraBulkCreateSubtaskBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DeprecatedJiraBulkActionsV2Api - interface
 * @export
 * @interface DeprecatedJiraBulkActionsV2Api
 */
export interface DeprecatedJiraBulkActionsV2ApiInterface {
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateAssetsAbort(accessToken: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Clear status report of previously submitted bulk create, download report
   * @summary Clear status report of previously submitted bulk create, download report
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateAssetsComplete(accessToken: string, options?: AxiosRequestConfig): AxiosPromise<File>;

  /**
   * Starts bulk creation of previously validated assets file
   * @summary Starts bulk creation of previously validated assets file
   * @param {string} accessToken
   * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateAssetsStart(
    accessToken: string,
    bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Get status report of previously submitted bulk create
   * @summary Get status report of previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateAssetsStatus(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<BulkCreateStatus>;

  /**
   * Validates an Excel file to be used for creating multiple Jira assets
   * @summary Validates an Excel file to be used for creating multiple Jira assets
   * @param {string} accessToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateAssetsValidateFile(
    accessToken: string,
    file: File,
    options?: AxiosRequestConfig
  ): AxiosPromise<BulkCreateAssetsFileValidation>;

  /**
   * This route requires credentials of an admin user or a user in the group oc_bulkoperations
   * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
   * @param {string} accessToken
   * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2ApiInterface
   */
  bulkCreateServiceOnStreetSubtask(
    accessToken: string,
    jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * DeprecatedJiraBulkActionsV2Api - object-oriented interface
 * @export
 * @class DeprecatedJiraBulkActionsV2Api
 * @extends {BaseAPI}
 */
export class DeprecatedJiraBulkActionsV2Api
  extends BaseAPI
  implements DeprecatedJiraBulkActionsV2ApiInterface
{
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateAssetsAbort(accessToken: string, options?: AxiosRequestConfig) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsAbort(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Clear status report of previously submitted bulk create, download report
   * @summary Clear status report of previously submitted bulk create, download report
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateAssetsComplete(accessToken: string, options?: AxiosRequestConfig) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsComplete(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts bulk creation of previously validated assets file
   * @summary Starts bulk creation of previously validated assets file
   * @param {string} accessToken
   * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateAssetsStart(
    accessToken: string,
    bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsStart(accessToken, bulkCreateAssetsFileValidation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status report of previously submitted bulk create
   * @summary Get status report of previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateAssetsStatus(accessToken: string, options?: AxiosRequestConfig) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsStatus(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates an Excel file to be used for creating multiple Jira assets
   * @summary Validates an Excel file to be used for creating multiple Jira assets
   * @param {string} accessToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateAssetsValidateFile(
    accessToken: string,
    file: File,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsValidateFile(accessToken, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This route requires credentials of an admin user or a user in the group oc_bulkoperations
   * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
   * @param {string} accessToken
   * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraBulkActionsV2Api
   */
  public bulkCreateServiceOnStreetSubtask(
    accessToken: string,
    jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedJiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateServiceOnStreetSubtask(accessToken, jiraBulkCreateSubtaskBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeprecatedJiraV2Api - axios parameter creator
 * @export
 */
export const DeprecatedJiraV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTicketConditionCheck: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createRepairTicketConditionCheck', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('createRepairTicketConditionCheck', 'vin', vin);
      const localVarPath = `/v2/jira/assets/{vin}/create-repair-ticket/condition-check`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getProjects', 'accessToken', accessToken);
      const localVarPath = `/v2/jira/projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setJiraIssuePriority: async (
      accessToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'accessToken', accessToken);
      // verify required parameter 'issueKey' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'issueKey', issueKey);
      // verify required parameter 'jiraPriorityBody' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'jiraPriorityBody', jiraPriorityBody);
      const localVarPath = `/v2/jira/issues/{issueKey}/set-priority`.replace(
        `{${'issueKey'}}`,
        encodeURIComponent(String(issueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jiraPriorityBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DeprecatedJiraV2Api - functional programming interface
 * @export
 */
export const DeprecatedJiraV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeprecatedJiraV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRepairTicketConditionCheck(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreconditionCheckResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRepairTicketConditionCheck(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraV2Api.createRepairTicketConditionCheck']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProjects(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(accessToken, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['DeprecatedJiraV2Api.getProjects']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setJiraIssuePriority(
      accessToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setJiraIssuePriority(
        accessToken,
        issueKey,
        jiraPriorityBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedJiraV2Api.setJiraIssuePriority']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * DeprecatedJiraV2Api - factory interface
 * @export
 */
export const DeprecatedJiraV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeprecatedJiraV2ApiFp(configuration);
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTicketConditionCheck(
      accessToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<PreconditionCheckResult> {
      return localVarFp
        .createRepairTicketConditionCheck(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(accessToken: string, options?: any): AxiosPromise<Array<Project>> {
      return localVarFp
        .getProjects(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setJiraIssuePriority(
      accessToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setJiraIssuePriority(accessToken, issueKey, jiraPriorityBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DeprecatedJiraV2Api - interface
 * @export
 * @interface DeprecatedJiraV2Api
 */
export interface DeprecatedJiraV2ApiInterface {
  /**
   * Checks if a repair ticket can be created on the given asset
   * @summary Checks if a repair ticket can be created on the given asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2ApiInterface
   */
  createRepairTicketConditionCheck(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<PreconditionCheckResult>;

  /**
   * List Jira projects
   * @summary List Jira projects
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2ApiInterface
   */
  getProjects(accessToken: string, options?: AxiosRequestConfig): AxiosPromise<Array<Project>>;

  /**
   * Sets the priority of the given Jira ticket
   * @summary Sets the priority of the given Jira ticket
   * @param {string} accessToken
   * @param {string} issueKey
   * @param {JiraPriorityBody} jiraPriorityBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2ApiInterface
   */
  setJiraIssuePriority(
    accessToken: string,
    issueKey: string,
    jiraPriorityBody: JiraPriorityBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * DeprecatedJiraV2Api - object-oriented interface
 * @export
 * @class DeprecatedJiraV2Api
 * @extends {BaseAPI}
 */
export class DeprecatedJiraV2Api extends BaseAPI implements DeprecatedJiraV2ApiInterface {
  /**
   * Checks if a repair ticket can be created on the given asset
   * @summary Checks if a repair ticket can be created on the given asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2Api
   */
  public createRepairTicketConditionCheck(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedJiraV2ApiFp(this.configuration)
      .createRepairTicketConditionCheck(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List Jira projects
   * @summary List Jira projects
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2Api
   */
  public getProjects(accessToken: string, options?: AxiosRequestConfig) {
    return DeprecatedJiraV2ApiFp(this.configuration)
      .getProjects(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sets the priority of the given Jira ticket
   * @summary Sets the priority of the given Jira ticket
   * @param {string} accessToken
   * @param {string} issueKey
   * @param {JiraPriorityBody} jiraPriorityBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedJiraV2Api
   */
  public setJiraIssuePriority(
    accessToken: string,
    issueKey: string,
    jiraPriorityBody: JiraPriorityBody,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedJiraV2ApiFp(this.configuration)
      .setJiraIssuePriority(accessToken, issueKey, jiraPriorityBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeprecatedWorkshopWithJiraV2Api - axios parameter creator
 * @export
 */
export const DeprecatedWorkshopWithJiraV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRepairTicketTodo: async (
      accessToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'problemListTodoBody' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'problemListTodoBody', problemListTodoBody);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/todos`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRepairTicket: async (
      accessToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('completeRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/complete`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failRepairTicket: async (
      accessToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('failRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTicketFailBody' is not null or undefined
      assertParamExists('failRepairTicket', 'repairTicketFailBody', repairTicketFailBody);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/fail`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        repairTicketFailBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfOpenRepairTickets: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getListOfOpenRepairTickets', 'accessToken', accessToken);
      const localVarPath = `/v2/workshop/repair-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForExternalWorkshop: async (
      accessToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'pageSize', pageSize);
      const localVarPath = `/v2/workshop/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (workshopIdentifier !== undefined) {
        localVarQueryParameter['workshopIdentifier'] = workshopIdentifier;
      }

      if (issueKey !== undefined) {
        localVarQueryParameter['issueKey'] = issueKey;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenRepairTicket: async (
      accessToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getOpenRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('getOpenRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTicketTodoProblemList: async (
      accessToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRepairTicketTodoProblemList', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists(
        'getRepairTicketTodoProblemList',
        'repairTicketIssueKey',
        repairTicketIssueKey
      );
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/problem-list`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveStatusRepairTicket: async (
      accessToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('liveStatusRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('liveStatusRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/live-data`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performTriggerAction: async (
      accessToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('performTriggerAction', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('performTriggerAction', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'triggerActionBody1' is not null or undefined
      assertParamExists('performTriggerAction', 'triggerActionBody1', triggerActionBody1);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/trigger-action`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveRepairTicketTodo: async (
      accessToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'accessToken', accessToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTodoId' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'repairTodoId', repairTodoId);
      // verify required parameter 'problemListTodoResolveBody' is not null or undefined
      assertParamExists(
        'resolveRepairTicketTodo',
        'problemListTodoResolveBody',
        problemListTodoResolveBody
      );
      const localVarPath =
        `/v2/workshop/repair-list/{repairTicketIssueKey}/todos/{repairTodoId}/resolve`
          .replace(`{${'repairTicketIssueKey'}}`, encodeURIComponent(String(repairTicketIssueKey)))
          .replace(`{${'repairTodoId'}}`, encodeURIComponent(String(repairTodoId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DeprecatedWorkshopWithJiraV2Api - functional programming interface
 * @export
 */
export const DeprecatedWorkshopWithJiraV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeprecatedWorkshopWithJiraV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addRepairTicketTodo(
      accessToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addRepairTicketTodo(
        accessToken,
        repairTicketIssueKey,
        problemListTodoBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.addRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeRepairTicket(
        accessToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.completeRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failRepairTicket(
        accessToken,
        repairTicketIssueKey,
        repairTicketFailBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.failRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListOfOpenRepairTickets(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkshopTicket>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfOpenRepairTickets(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.getListOfOpenRepairTickets']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogsForExternalWorkshop(
      accessToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultWorkshopEvent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsForExternalWorkshop(
        accessToken,
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.getLogsForExternalWorkshop']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopTicket>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenRepairTicket(
        accessToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.getOpenRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRepairTicketTodoProblemList(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableProblem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRepairTicketTodoProblemList(
        accessToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.getRepairTicketTodoProblemList']?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async liveStatusRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.liveStatusRepairTicket(
        accessToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.liveStatusRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async performTriggerAction(
      accessToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.performTriggerAction(
        accessToken,
        repairTicketIssueKey,
        triggerActionBody1,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.performTriggerAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolveRepairTicketTodo(
      accessToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resolveRepairTicketTodo(
        accessToken,
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DeprecatedWorkshopWithJiraV2Api.resolveRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * DeprecatedWorkshopWithJiraV2Api - factory interface
 * @export
 */
export const DeprecatedWorkshopWithJiraV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeprecatedWorkshopWithJiraV2ApiFp(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRepairTicketTodo(
      accessToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .addRepairTicketTodo(accessToken, repairTicketIssueKey, problemListTodoBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .completeRepairTicket(accessToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .failRepairTicket(accessToken, repairTicketIssueKey, repairTicketFailBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfOpenRepairTickets(
      accessToken: string,
      options?: any
    ): AxiosPromise<Array<WorkshopTicket>> {
      return localVarFp
        .getListOfOpenRepairTickets(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForExternalWorkshop(
      accessToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<PaginatedResultWorkshopEvent> {
      return localVarFp
        .getLogsForExternalWorkshop(
          accessToken,
          page,
          pageSize,
          workshopIdentifier,
          issueKey,
          vin,
          username,
          dateFrom,
          dateTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<WorkshopTicket> {
      return localVarFp
        .getOpenRepairTicket(accessToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTicketTodoProblemList(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<Array<AvailableProblem>> {
      return localVarFp
        .getRepairTicketTodoProblemList(accessToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveStatusRepairTicket(
      accessToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<LiveData> {
      return localVarFp
        .liveStatusRepairTicket(accessToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performTriggerAction(
      accessToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .performTriggerAction(accessToken, repairTicketIssueKey, triggerActionBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveRepairTicketTodo(
      accessToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resolveRepairTicketTodo(
          accessToken,
          repairTicketIssueKey,
          repairTodoId,
          problemListTodoResolveBody,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DeprecatedWorkshopWithJiraV2Api - interface
 * @export
 * @interface DeprecatedWorkshopWithJiraV2Api
 */
export interface DeprecatedWorkshopWithJiraV2ApiInterface {
  /**
   * Add a todo item on the repair ticket
   * @summary Add a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {ProblemListTodoBody} problemListTodoBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  addRepairTicketTodo(
    accessToken: string,
    repairTicketIssueKey: string,
    problemListTodoBody: ProblemListTodoBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Complete work on the repair ticket successfully
   * @summary Complete work on the repair ticket successfully
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  completeRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Fail work on the repair ticket
   * @summary Fail work on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {RepairTicketFailBody} repairTicketFailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  failRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    repairTicketFailBody: RepairTicketFailBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Get list of open repair tickets for the specified workshop
   * @summary Get list of open repair tickets for the specified workshop
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  getListOfOpenRepairTickets(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<WorkshopTicket>>;

  /**
   * Get logs for external workshop
   * @summary Get logs for external workshop
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [workshopIdentifier]
   * @param {string} [issueKey]
   * @param {string} [vin]
   * @param {string} [username]
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  getLogsForExternalWorkshop(
    accessToken: string,
    page: number,
    pageSize: number,
    workshopIdentifier?: string,
    issueKey?: string,
    vin?: string,
    username?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultWorkshopEvent>;

  /**
   * Get open repair ticket for the given issue key
   * @summary Get open repair ticket for the given issue key
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  getOpenRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<WorkshopTicket>;

  /**
   * Get the list of available problems to use in a repair ticket todo item
   * @summary Get the list of available problems to use in a repair ticket todo item
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  getRepairTicketTodoProblemList(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<AvailableProblem>>;

  /**
   * Get live vehicle status for a repair ticket
   * @summary Get live vehicle status for a repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  liveStatusRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<LiveData>;

  /**
   * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {TriggerActionBody1} triggerActionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  performTriggerAction(
    accessToken: string,
    repairTicketIssueKey: string,
    triggerActionBody1: TriggerActionBody1,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Resolve a todo item on the repair ticket
   * @summary Resolve a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {number} repairTodoId
   * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2ApiInterface
   */
  resolveRepairTicketTodo(
    accessToken: string,
    repairTicketIssueKey: string,
    repairTodoId: number,
    problemListTodoResolveBody: ProblemListTodoResolveBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * DeprecatedWorkshopWithJiraV2Api - object-oriented interface
 * @export
 * @class DeprecatedWorkshopWithJiraV2Api
 * @extends {BaseAPI}
 */
export class DeprecatedWorkshopWithJiraV2Api
  extends BaseAPI
  implements DeprecatedWorkshopWithJiraV2ApiInterface
{
  /**
   * Add a todo item on the repair ticket
   * @summary Add a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {ProblemListTodoBody} problemListTodoBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public addRepairTicketTodo(
    accessToken: string,
    repairTicketIssueKey: string,
    problemListTodoBody: ProblemListTodoBody,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .addRepairTicketTodo(accessToken, repairTicketIssueKey, problemListTodoBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete work on the repair ticket successfully
   * @summary Complete work on the repair ticket successfully
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public completeRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .completeRepairTicket(accessToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail work on the repair ticket
   * @summary Fail work on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {RepairTicketFailBody} repairTicketFailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public failRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    repairTicketFailBody: RepairTicketFailBody,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .failRepairTicket(accessToken, repairTicketIssueKey, repairTicketFailBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of open repair tickets for the specified workshop
   * @summary Get list of open repair tickets for the specified workshop
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public getListOfOpenRepairTickets(accessToken: string, options?: AxiosRequestConfig) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .getListOfOpenRepairTickets(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get logs for external workshop
   * @summary Get logs for external workshop
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [workshopIdentifier]
   * @param {string} [issueKey]
   * @param {string} [vin]
   * @param {string} [username]
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public getLogsForExternalWorkshop(
    accessToken: string,
    page: number,
    pageSize: number,
    workshopIdentifier?: string,
    issueKey?: string,
    vin?: string,
    username?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .getLogsForExternalWorkshop(
        accessToken,
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get open repair ticket for the given issue key
   * @summary Get open repair ticket for the given issue key
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public getOpenRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .getOpenRepairTicket(accessToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of available problems to use in a repair ticket todo item
   * @summary Get the list of available problems to use in a repair ticket todo item
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public getRepairTicketTodoProblemList(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .getRepairTicketTodoProblemList(accessToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get live vehicle status for a repair ticket
   * @summary Get live vehicle status for a repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public liveStatusRepairTicket(
    accessToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .liveStatusRepairTicket(accessToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {TriggerActionBody1} triggerActionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public performTriggerAction(
    accessToken: string,
    repairTicketIssueKey: string,
    triggerActionBody1: TriggerActionBody1,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .performTriggerAction(accessToken, repairTicketIssueKey, triggerActionBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Resolve a todo item on the repair ticket
   * @summary Resolve a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} repairTicketIssueKey
   * @param {number} repairTodoId
   * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeprecatedWorkshopWithJiraV2Api
   */
  public resolveRepairTicketTodo(
    accessToken: string,
    repairTicketIssueKey: string,
    repairTodoId: number,
    problemListTodoResolveBody: ProblemListTodoResolveBody,
    options?: AxiosRequestConfig
  ) {
    return DeprecatedWorkshopWithJiraV2ApiFp(this.configuration)
      .resolveRepairTicketTodo(
        accessToken,
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServiceMissionsAndToursApi - axios parameter creator
 * @export
 */
export const ServiceMissionsAndToursApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTour: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('adaptTour', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('adaptTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('adaptTour', 'tourId', tourId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('adaptTour', 'body', body);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/adapt`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Adapt a tour by adding / removing / reordering jobs with Tasks
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {TourAdaptionBodyWithTasks} tourAdaptionBodyWithTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTourWithTask: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      tourAdaptionBodyWithTasks: TourAdaptionBodyWithTasks,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('adaptTourWithTask', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('adaptTourWithTask', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('adaptTourWithTask', 'tourId', tourId);
      // verify required parameter 'tourAdaptionBodyWithTasks' is not null or undefined
      assertParamExists(
        'adaptTourWithTask',
        'tourAdaptionBodyWithTasks',
        tourAdaptionBodyWithTasks
      );
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/adapt-with-task`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourAdaptionBodyWithTasks,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkStartPreAndAtSteps: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'jobId', jobId);
      const localVarPath =
        `/v2/missions/{missionId}/tours/{tourId}/jobs/{jobId}/start-pre-and-at-steps`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeChecks: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeChecks', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeChecks', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeChecks', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('completeChecks', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-checks`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeStep: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeStep', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('completeStep', 'stepId', stepId);
      // verify required parameter 'transitionActionBody' is not null or undefined
      assertParamExists('completeStep', 'transitionActionBody', transitionActionBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transitionActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeTour: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeTour', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeTour', 'tourId', tourId);
      // verify required parameter 'completeTourBody' is not null or undefined
      assertParamExists('completeTour', 'completeTourBody', completeTourBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeTourBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMission: async (
      accessToken: string,
      missionBody: MissionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createMission', 'accessToken', accessToken);
      // verify required parameter 'missionBody' is not null or undefined
      assertParamExists('createMission', 'missionBody', missionBody);
      const localVarPath = `/v2/missions/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        missionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    estimatedDrivingTime: async (
      accessToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'accessToken', accessToken);
      // verify required parameter 'startLat' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'startLat', startLat);
      // verify required parameter 'startLon' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'startLon', startLon);
      // verify required parameter 'endLat' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'endLat', endLat);
      // verify required parameter 'endLon' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'endLon', endLon);
      const localVarPath = `/v2/missions/estimated-driving-time`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startLat !== undefined) {
        localVarQueryParameter['startLat'] = startLat;
      }

      if (startLon !== undefined) {
        localVarQueryParameter['startLon'] = startLon;
      }

      if (endLat !== undefined) {
        localVarQueryParameter['endLat'] = endLat;
      }

      if (endLon !== undefined) {
        localVarQueryParameter['endLon'] = endLon;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendByOneJob: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('extendByOneJob', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('extendByOneJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('extendByOneJob', 'tourId', tourId);
      // verify required parameter 'extendByOneBody' is not null or undefined
      assertParamExists('extendByOneJob', 'extendByOneBody', extendByOneBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/extend-by-one`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extendByOneBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failStep: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failStep', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('failStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('failStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('failStep', 'stepId', stepId);
      // verify required parameter 'failStepBody' is not null or undefined
      assertParamExists('failStep', 'failStepBody', failStepBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        failStepBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failTour: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failTour', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('failTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('failTour', 'tourId', tourId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('failTour', 'reasonBody', reasonBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndJob: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('forceEndJob', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('forceEndJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('forceEndJob', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('forceEndJob', 'jobId', jobId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('forceEndJob', 'reasonBody', reasonBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/jobs/{jobId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {EndTourBody} endTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndTour: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      endTourBody: EndTourBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('forceEndTour', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('forceEndTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('forceEndTour', 'tourId', tourId);
      // verify required parameter 'endTourBody' is not null or undefined
      assertParamExists('forceEndTour', 'endTourBody', endTourBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        endTourBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOpenTours: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAllOpenTours', 'accessToken', accessToken);
      const localVarPath = `/v2/missions/tours/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteActionOptions: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'stepId', stepId);
      const localVarPath =
        `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-action-options`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissionWithTourStatistics: async (
      accessToken: string,
      missionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getMissionWithTourStatistics', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getMissionWithTourStatistics', 'missionId', missionId);
      const localVarPath = `/v2/missions/mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (missionId !== undefined) {
        localVarQueryParameter['missionId'] = missionId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissions: async (
      accessToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getMissions', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getMissions', 'locationId', locationId);
      // verify required parameter 'includeExpired' is not null or undefined
      assertParamExists('getMissions', 'includeExpired', includeExpired);
      // verify required parameter 'includeDisabled' is not null or undefined
      assertParamExists('getMissions', 'includeDisabled', includeDisabled);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getMissions', 'missionType', missionType);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getMissions', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getMissions', 'pageSize', pageSize);
      const localVarPath = `/v2/missions/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeExpired !== undefined) {
        localVarQueryParameter['includeExpired'] = includeExpired;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextMission: async (
      accessToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getNextMission', 'accessToken', accessToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getNextMission', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getNextMission', 'lon', lon);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getNextMission', 'type', type);
      const localVarPath = `/v2/missions/next-mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTour: async (
      accessToken: string,
      tourType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getOpenTour', 'accessToken', accessToken);
      // verify required parameter 'tourType' is not null or undefined
      assertParamExists('getOpenTour', 'tourType', tourType);
      const localVarPath = `/v2/missions/tour/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tourType !== undefined) {
        localVarQueryParameter['tourType'] = tourType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingZones: async (
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getParkingZones', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getParkingZones', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceEmployees: async (
      accessToken: string,
      locationId: number,
      missionType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceEmployees', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceEmployees', 'locationId', locationId);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getServiceEmployees', 'missionType', missionType);
      const localVarPath = `/v2/missions/service-employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceHubs: async (
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceHubs', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceHubs', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicePhones: async (
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServicePhones', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServicePhones', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all the service task logs for a service tour by tourId
     * @summary Get service task logs for service tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTourTaskLogs: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTourTaskLogs', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getServiceTourTaskLogs', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getServiceTourTaskLogs', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tour/{tourId}/task-logs`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceVehicles: async (
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceVehicles', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceVehicles', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTour: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTour', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTour', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourCorridor: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourCorridor', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourCorridor', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourCorridor', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/corridor`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourLogs: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourLogs', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourLogs', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourLogs', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/logs`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourParameterOptions: async (
      accessToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourParameterOptions', 'accessToken', accessToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getTourParameterOptions', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getTourParameterOptions', 'lon', lon);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getTourParameterOptions', 'missionType', missionType);
      const localVarPath = `/v2/missions/tour-parameter-options`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourStats: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourStats', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourStats', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourStats', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/stats`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForLocation: async (
      accessToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getToursForLocation', 'accessToken', accessToken);
      // verify required parameter 'paginatedTourFilterBody' is not null or undefined
      assertParamExists('getToursForLocation', 'paginatedTourFilterBody', paginatedTourFilterBody);
      const localVarPath = `/v2/missions/tours`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paginatedTourFilterBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForMission: async (
      accessToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getToursForMission', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getToursForMission', 'missionId', missionId);
      // verify required parameter 'includeFinished' is not null or undefined
      assertParamExists('getToursForMission', 'includeFinished', includeFinished);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getToursForMission', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getToursForMission', 'pageSize', pageSize);
      const localVarPath = `/v2/missions/{missionId}/tours`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeFinished !== undefined) {
        localVarQueryParameter['includeFinished'] = includeFinished;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleTypes: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleTypes', 'accessToken', accessToken);
      const localVarPath = `/v2/missions/vehicle-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesAvailableForService: async (
      accessToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehiclesAvailableForService', 'accessToken', accessToken);
      // verify required parameter 'serviceVehiclesFilterSet' is not null or undefined
      assertParamExists(
        'getVehiclesAvailableForService',
        'serviceVehiclesFilterSet',
        serviceVehiclesFilterSet
      );
      const localVarPath = `/v2/missions/vehicles/service`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehiclesFilterSet,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshStepLocation: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('refreshStepLocation', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('refreshStepLocation', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('refreshStepLocation', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('refreshStepLocation', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/refresh-location`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestTourFromMission: async (
      accessToken: string,
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('requestTourFromMission', 'accessToken', accessToken);
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('requestTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/v2/missions/request-tour`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateTourFromMission: async (
      accessToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('simulateTourFromMission', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('simulateTourFromMission', 'missionId', missionId);
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('simulateTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/v2/missions/{missionId}/simulate-tour`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startStep: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('startStep', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('startStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('startStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('startStep', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/start`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerStepAction: async (
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('triggerStepAction', 'accessToken', accessToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('triggerStepAction', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('triggerStepAction', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('triggerStepAction', 'stepId', stepId);
      // verify required parameter 'triggerActionBody' is not null or undefined
      assertParamExists('triggerStepAction', 'triggerActionBody', triggerActionBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/trigger-action`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceHub: async (
      accessToken: string,
      serviceHub: ServiceHub,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceHub', 'accessToken', accessToken);
      // verify required parameter 'serviceHub' is not null or undefined
      assertParamExists('upsertServiceHub', 'serviceHub', serviceHub);
      const localVarPath = `/v2/missions/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceHub,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceParkingZone: async (
      accessToken: string,
      serviceParkingZone: ServiceParkingZone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceParkingZone', 'accessToken', accessToken);
      // verify required parameter 'serviceParkingZone' is not null or undefined
      assertParamExists('upsertServiceParkingZone', 'serviceParkingZone', serviceParkingZone);
      const localVarPath = `/v2/missions/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceParkingZone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServicePhone: async (
      accessToken: string,
      servicePhone: ServicePhone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServicePhone', 'accessToken', accessToken);
      // verify required parameter 'servicePhone' is not null or undefined
      assertParamExists('upsertServicePhone', 'servicePhone', servicePhone);
      const localVarPath = `/v2/missions/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        servicePhone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceVehicle: async (
      accessToken: string,
      serviceVehicle: ServiceVehicle,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceVehicle', 'accessToken', accessToken);
      // verify required parameter 'serviceVehicle' is not null or undefined
      assertParamExists('upsertServiceVehicle', 'serviceVehicle', serviceVehicle);
      const localVarPath = `/v2/missions/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehicle,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ServiceMissionsAndToursApi - functional programming interface
 * @export
 */
export const ServiceMissionsAndToursApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceMissionsAndToursApiAxiosParamCreator(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adaptTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adaptTour(
        accessToken,
        missionId,
        tourId,
        body,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.adaptTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Adapt a tour by adding / removing / reordering jobs with Tasks
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {TourAdaptionBodyWithTasks} tourAdaptionBodyWithTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adaptTourWithTask(
      accessToken: string,
      missionId: number,
      tourId: number,
      tourAdaptionBodyWithTasks: TourAdaptionBodyWithTasks,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adaptTourWithTask(
        accessToken,
        missionId,
        tourId,
        tourAdaptionBodyWithTasks,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.adaptTourWithTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkStartPreAndAtSteps(
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkStartPreAndAtSteps(
        accessToken,
        missionId,
        tourId,
        jobId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.bulkStartPreAndAtSteps']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeChecks(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteChecksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeChecks(
        accessToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.completeChecks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeStep(
        accessToken,
        missionId,
        tourId,
        stepId,
        transitionActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.completeStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeTour(
        accessToken,
        missionId,
        tourId,
        completeTourBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.completeTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMission(
      accessToken: string,
      missionBody: MissionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMission(
        accessToken,
        missionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.createMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async estimatedDrivingTime(
      accessToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedDrivingTime>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.estimatedDrivingTime(
        accessToken,
        startLat,
        startLon,
        endLat,
        endLon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.estimatedDrivingTime']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendByOneJob(
      accessToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.extendByOneJob(
        accessToken,
        missionId,
        tourId,
        extendByOneBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.extendByOneJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failStep(
        accessToken,
        missionId,
        tourId,
        stepId,
        failStepBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.failStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failTour(
        accessToken,
        missionId,
        tourId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.failTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceEndJob(
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forceEndJob(
        accessToken,
        missionId,
        tourId,
        jobId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.forceEndJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {EndTourBody} endTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceEndTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      endTourBody: EndTourBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forceEndTour(
        accessToken,
        missionId,
        tourId,
        endTourBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.forceEndTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllOpenTours(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompiledTour>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOpenTours(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getAllOpenTours']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompleteActionOptions(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitionActionField>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompleteActionOptions(
        accessToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getCompleteActionOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissionWithTourStatistics(
      accessToken: string,
      missionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionWithTourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionWithTourStatistics(
        accessToken,
        missionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getMissionWithTourStatistics']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissions(
      accessToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissions(
        accessToken,
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getMissions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNextMission(
      accessToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNextMission(
        accessToken,
        lat,
        lon,
        type,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getNextMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenTour(
      accessToken: string,
      tourType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenTour(
        accessToken,
        tourType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getOpenTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParkingZones(
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceParkingZone>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParkingZones(
        accessToken,
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getParkingZones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceEmployees(
      accessToken: string,
      locationId: number,
      missionType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceEmployee>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceEmployees(
        accessToken,
        locationId,
        missionType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getServiceEmployees']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceHubs(
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceHub>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceHubs(
        accessToken,
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getServiceHubs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServicePhones(
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServicePhone>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServicePhones(
        accessToken,
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getServicePhones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all the service task logs for a service tour by tourId
     * @summary Get service task logs for service tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTourTaskLogs(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TourTaskLogs>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTourTaskLogs(
        accessToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getServiceTourTaskLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceVehicles(
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceVehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceVehicles(
        accessToken,
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getServiceVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTour(
        accessToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourCorridor(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourCorridor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourCorridor(
        accessToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getTourCorridor']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourLogs(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericTourEvent>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourLogs(
        accessToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getTourLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourParameterOptions(
      accessToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourParameterOptionsBundle>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourParameterOptions(
        accessToken,
        lat,
        lon,
        missionType,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getTourParameterOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourStats(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourStats(
        accessToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getTourStats']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToursForLocation(
      accessToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToursForLocation(
        accessToken,
        paginatedTourFilterBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getToursForLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToursForMission(
      accessToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToursForMission(
        accessToken,
        missionId,
        includeFinished,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getToursForMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleTypes(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleTypes(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getVehicleTypes']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehiclesAvailableForService(
      accessToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehiclesAvailableForService(
        accessToken,
        serviceVehiclesFilterSet,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.getVehiclesAvailableForService']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshStepLocation(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshStepLocation(
        accessToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.refreshStepLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestTourFromMission(
      accessToken: string,
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestTourFromMission(
        accessToken,
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.requestTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simulateTourFromMission(
      accessToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.simulateTourFromMission(
        accessToken,
        missionId,
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.simulateTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startStep(
        accessToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.startStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async triggerStepAction(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.triggerStepAction(
        accessToken,
        missionId,
        tourId,
        stepId,
        triggerActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.triggerStepAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceHub(
      accessToken: string,
      serviceHub: ServiceHub,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHub>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceHub(
        accessToken,
        serviceHub,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.upsertServiceHub']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceParkingZone(
      accessToken: string,
      serviceParkingZone: ServiceParkingZone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceParkingZone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceParkingZone(
        accessToken,
        serviceParkingZone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.upsertServiceParkingZone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServicePhone(
      accessToken: string,
      servicePhone: ServicePhone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePhone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServicePhone(
        accessToken,
        servicePhone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.upsertServicePhone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceVehicle(
      accessToken: string,
      serviceVehicle: ServiceVehicle,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceVehicle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceVehicle(
        accessToken,
        serviceVehicle,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsAndToursApi.upsertServiceVehicle']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * ServiceMissionsAndToursApi - factory interface
 * @export
 */
export const ServiceMissionsAndToursApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServiceMissionsAndToursApiFp(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .adaptTour(accessToken, missionId, tourId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Adapt a tour by adding / removing / reordering jobs with Tasks
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {TourAdaptionBodyWithTasks} tourAdaptionBodyWithTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTourWithTask(
      accessToken: string,
      missionId: number,
      tourId: number,
      tourAdaptionBodyWithTasks: TourAdaptionBodyWithTasks,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .adaptTourWithTask(accessToken, missionId, tourId, tourAdaptionBodyWithTasks, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkStartPreAndAtSteps(
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .bulkStartPreAndAtSteps(accessToken, missionId, tourId, jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeChecks(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<CompleteChecksResponse> {
      return localVarFp
        .completeChecks(accessToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .completeStep(accessToken, missionId, tourId, stepId, transitionActionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .completeTour(accessToken, missionId, tourId, completeTourBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMission(
      accessToken: string,
      missionBody: MissionBody,
      options?: any
    ): AxiosPromise<Mission> {
      return localVarFp
        .createMission(accessToken, missionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    estimatedDrivingTime(
      accessToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: any
    ): AxiosPromise<EstimatedDrivingTime> {
      return localVarFp
        .estimatedDrivingTime(accessToken, startLat, startLon, endLat, endLon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendByOneJob(
      accessToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .extendByOneJob(accessToken, missionId, tourId, extendByOneBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .failStep(accessToken, missionId, tourId, stepId, failStepBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .failTour(accessToken, missionId, tourId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndJob(
      accessToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .forceEndJob(accessToken, missionId, tourId, jobId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {EndTourBody} endTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      endTourBody: EndTourBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .forceEndTour(accessToken, missionId, tourId, endTourBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOpenTours(accessToken: string, options?: any): AxiosPromise<Array<CompiledTour>> {
      return localVarFp
        .getAllOpenTours(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteActionOptions(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<Array<TransitionActionField>> {
      return localVarFp
        .getCompleteActionOptions(accessToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissionWithTourStatistics(
      accessToken: string,
      missionId: number,
      options?: any
    ): AxiosPromise<MissionWithTourStats> {
      return localVarFp
        .getMissionWithTourStatistics(accessToken, missionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissions(
      accessToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<MissionOverview> {
      return localVarFp
        .getMissions(
          accessToken,
          locationId,
          includeExpired,
          includeDisabled,
          missionType,
          page,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextMission(
      accessToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<Mission> {
      return localVarFp
        .getNextMission(accessToken, lat, lon, type, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTour(
      accessToken: string,
      tourType: MissionType,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .getOpenTour(accessToken, tourType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingZones(
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceParkingZone>> {
      return localVarFp
        .getParkingZones(accessToken, locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceEmployees(
      accessToken: string,
      locationId: number,
      missionType: MissionType,
      options?: any
    ): AxiosPromise<Array<ServiceEmployee>> {
      return localVarFp
        .getServiceEmployees(accessToken, locationId, missionType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceHubs(
      accessToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceHub>> {
      return localVarFp
        .getServiceHubs(accessToken, locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicePhones(
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServicePhone>> {
      return localVarFp
        .getServicePhones(accessToken, locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all the service task logs for a service tour by tourId
     * @summary Get service task logs for service tour
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTourTaskLogs(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Array<TourTaskLogs>> {
      return localVarFp
        .getServiceTourTaskLogs(accessToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceVehicles(
      accessToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceVehicle>> {
      return localVarFp
        .getServiceVehicles(accessToken, locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTour(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Tour> {
      return localVarFp
        .getTour(accessToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourCorridor(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<TourCorridor> {
      return localVarFp
        .getTourCorridor(accessToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourLogs(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Array<GenericTourEvent>> {
      return localVarFp
        .getTourLogs(accessToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourParameterOptions(
      accessToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<TourParameterOptionsBundle> {
      return localVarFp
        .getTourParameterOptions(accessToken, lat, lon, missionType, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourStats(
      accessToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<TourStats> {
      return localVarFp
        .getTourStats(accessToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForLocation(
      accessToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: any
    ): AxiosPromise<PaginatedResultTour> {
      return localVarFp
        .getToursForLocation(accessToken, paginatedTourFilterBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForMission(
      accessToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<PaginatedResultTour> {
      return localVarFp
        .getToursForMission(accessToken, missionId, includeFinished, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleTypes(accessToken: string, options?: any): AxiosPromise<Array<VehicleType>> {
      return localVarFp
        .getVehicleTypes(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesAvailableForService(
      accessToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: any
    ): AxiosPromise<ServiceOverview> {
      return localVarFp
        .getVehiclesAvailableForService(accessToken, serviceVehiclesFilterSet, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshStepLocation(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .refreshStepLocation(accessToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestTourFromMission(
      accessToken: string,
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .requestTourFromMission(accessToken, tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateTourFromMission(
      accessToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .simulateTourFromMission(accessToken, missionId, tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startStep(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .startStep(accessToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerStepAction(
      accessToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: any
    ): AxiosPromise<TriggerEvent> {
      return localVarFp
        .triggerStepAction(accessToken, missionId, tourId, stepId, triggerActionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceHub(
      accessToken: string,
      serviceHub: ServiceHub,
      options?: any
    ): AxiosPromise<ServiceHub> {
      return localVarFp
        .upsertServiceHub(accessToken, serviceHub, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceParkingZone(
      accessToken: string,
      serviceParkingZone: ServiceParkingZone,
      options?: any
    ): AxiosPromise<ServiceParkingZone> {
      return localVarFp
        .upsertServiceParkingZone(accessToken, serviceParkingZone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServicePhone(
      accessToken: string,
      servicePhone: ServicePhone,
      options?: any
    ): AxiosPromise<ServicePhone> {
      return localVarFp
        .upsertServicePhone(accessToken, servicePhone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceVehicle(
      accessToken: string,
      serviceVehicle: ServiceVehicle,
      options?: any
    ): AxiosPromise<ServiceVehicle> {
      return localVarFp
        .upsertServiceVehicle(accessToken, serviceVehicle, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ServiceMissionsAndToursApi - interface
 * @export
 * @interface ServiceMissionsAndToursApi
 */
export interface ServiceMissionsAndToursApiInterface {
  /**
   * Adapt a tour by adding / removing / reordering jobs
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {File} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  adaptTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    body: File,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Adapt a tour by adding / removing / reordering jobs with Tasks
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {TourAdaptionBodyWithTasks} tourAdaptionBodyWithTasks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  adaptTourWithTask(
    accessToken: string,
    missionId: number,
    tourId: number,
    tourAdaptionBodyWithTasks: TourAdaptionBodyWithTasks,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Bulk-starts pre and at steps
   * @summary Bulk-starts all open pre- and at-steps of the job
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  bulkStartPreAndAtSteps(
    accessToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Check if step can be completed
   * @summary Check if step can be completed
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  completeChecks(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompleteChecksResponse>;

  /**
   * Complete the specified step
   * @summary Complete the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TransitionActionBody} transitionActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  completeStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    transitionActionBody: TransitionActionBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Completes the tour
   * @summary Completes the tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {CompleteTourBody} completeTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  completeTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    completeTourBody: CompleteTourBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Create a new mission
   * @summary Create a new mission
   * @param {string} accessToken
   * @param {MissionBody} missionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  createMission(
    accessToken: string,
    missionBody: MissionBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<Mission>;

  /**
   * Calculate estimated driving time between two coordinates
   * @summary Calculate estimated driving time between two coordinates
   * @param {string} accessToken
   * @param {number} startLat
   * @param {number} startLon
   * @param {number} endLat
   * @param {number} endLon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  estimatedDrivingTime(
    accessToken: string,
    startLat: number,
    startLon: number,
    endLat: number,
    endLon: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<EstimatedDrivingTime>;

  /**
   * Extends the tour by adding the nearest service job
   * @summary Extends the tour by adding the nearest service job
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ExtendByOneBody} extendByOneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  extendByOneJob(
    accessToken: string,
    missionId: number,
    tourId: number,
    extendByOneBody: ExtendByOneBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Fail the specified step
   * @summary Fail the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {FailStepBody} failStepBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  failStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    failStepBody: FailStepBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Fail the tour
   * @summary Fail the tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  failTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Forcefully fails / ends the specified job without trying any Jira transition
   * @summary Forcefully fails / ends the specified job without trying any Jira transition
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  forceEndJob(
    accessToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Forcefully fails / ends the specified tour without trying any Jira transitions
   * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {EndTourBody} endTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  forceEndTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    endTourBody: EndTourBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Get all open tours for the user
   * @summary Get all open tours for the user
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getAllOpenTours(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<CompiledTour>>;

  /**
   * Get the available complete action options for the specified step
   * @summary Get the available complete action options for the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getCompleteActionOptions(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<TransitionActionField>>;

  /**
   * Gets mission detail with tour statistics
   * @summary Gets mission detail with tour statistics
   * @param {string} accessToken
   * @param {number} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getMissionWithTourStatistics(
    accessToken: string,
    missionId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<MissionWithTourStats>;

  /**
   * Gets missions
   * @summary Gets missions
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} includeExpired
   * @param {boolean} includeDisabled
   * @param {MissionType} missionType
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getMissions(
    accessToken: string,
    locationId: number,
    includeExpired: boolean,
    includeDisabled: boolean,
    missionType: MissionType,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<MissionOverview>;

  /**
   * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
   * @summary Gets the highest prioritized mission
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} type
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getNextMission(
    accessToken: string,
    lat: number,
    lon: number,
    type: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Mission>;

  /**
   * Get the open tour for the specified tour type (if there is one)
   * @summary Get the open tour for the specified tour type (if there is one)
   * @param {string} accessToken
   * @param {MissionType} tourType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getOpenTour(
    accessToken: string,
    tourType: MissionType,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Get available parking zones for location
   * @summary Get available parking zones for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getParkingZones(
    accessToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceParkingZone>>;

  /**
   * Get list of service employees
   * @summary Get list of service employees
   * @param {string} accessToken
   * @param {number} locationId
   * @param {MissionType} missionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getServiceEmployees(
    accessToken: string,
    locationId: number,
    missionType: MissionType,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceEmployee>>;

  /**
   * Get available hubs for location
   * @summary Get available hubs for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getServiceHubs(
    accessToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceHub>>;

  /**
   * Get available service phones for location
   * @summary Get available service phones for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getServicePhones(
    accessToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServicePhone>>;

  /**
   * Get all the service task logs for a service tour by tourId
   * @summary Get service task logs for service tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getServiceTourTaskLogs(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<TourTaskLogs>>;

  /**
   * Get available service vehicles for location
   * @summary Get available service vehicles for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getServiceVehicles(
    accessToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceVehicle>>;

  /**
   * Get tour detail
   * @summary Get tour detail
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Tour>;

  /**
   * Get tour corridor
   * @summary Get tour corridor
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getTourCorridor(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourCorridor>;

  /**
   * Get detailed tour logs
   * @summary Get detailed tour logs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getTourLogs(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<GenericTourEvent>>;

  /**
   * Get available hubs, service vehicles, service phones and service employees as a bundle
   * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} missionType
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getTourParameterOptions(
    accessToken: string,
    lat: number,
    lon: number,
    missionType: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourParameterOptionsBundle>;

  /**
   * Get tour statistics, if available
   * @summary Get tour statistics, if available
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getTourStats(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourStats>;

  /**
   * Get paginated list of recent tours
   * @summary Get paginated list of recent tours
   * @param {string} accessToken
   * @param {PaginatedTourFilterBody} paginatedTourFilterBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getToursForLocation(
    accessToken: string,
    paginatedTourFilterBody: PaginatedTourFilterBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultTour>;

  /**
   * Get list of tours for the specified mission
   * @summary Get list of tours for the specified mission
   * @param {string} accessToken
   * @param {number} missionId
   * @param {boolean} includeFinished
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getToursForMission(
    accessToken: string,
    missionId: number,
    includeFinished: boolean,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultTour>;

  /**
   * Get list of available vehicle types
   * @summary Get list of available vehicle types
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getVehicleTypes(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<VehicleType>>;

  /**
   * Get a list of vehicles with their status being in the specified service type
   * @summary Get a list of vehicles with their status being in the specified service type
   * @param {string} accessToken
   * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  getVehiclesAvailableForService(
    accessToken: string,
    serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceOverview>;

  /**
   * Refresh the GPS location of the step
   * @summary Refresh the GPS location of the step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  refreshStepLocation(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Request tour from mission
   * @summary Request tour from mission
   * @param {string} accessToken
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  requestTourFromMission(
    accessToken: string,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Simulate tour
   * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
   * @param {string} accessToken
   * @param {number} missionId
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  simulateTourFromMission(
    accessToken: string,
    missionId: number,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<CompiledTour>;

  /**
   * Start working on the step
   * @summary Start working on the step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  startStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TourResponse>;

  /**
   * Trigger the specified step action
   * @summary Trigger the specified step action
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TriggerActionBody} triggerActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  triggerStepAction(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    triggerActionBody: TriggerActionBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<TriggerEvent>;

  /**
   * Create or update a service hub
   * @summary Create or update a service hub
   * @param {string} accessToken
   * @param {ServiceHub} serviceHub
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  upsertServiceHub(
    accessToken: string,
    serviceHub: ServiceHub,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceHub>;

  /**
   * Create or update a service parking zone
   * @summary Create or update a service parking zone
   * @param {string} accessToken
   * @param {ServiceParkingZone} serviceParkingZone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  upsertServiceParkingZone(
    accessToken: string,
    serviceParkingZone: ServiceParkingZone,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceParkingZone>;

  /**
   * Create or update a service phone
   * @summary Create or update a service phone
   * @param {string} accessToken
   * @param {ServicePhone} servicePhone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  upsertServicePhone(
    accessToken: string,
    servicePhone: ServicePhone,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServicePhone>;

  /**
   * Create or update a service vehicle
   * @summary Create or update a service vehicle
   * @param {string} accessToken
   * @param {ServiceVehicle} serviceVehicle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApiInterface
   */
  upsertServiceVehicle(
    accessToken: string,
    serviceVehicle: ServiceVehicle,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceVehicle>;
}

/**
 * ServiceMissionsAndToursApi - object-oriented interface
 * @export
 * @class ServiceMissionsAndToursApi
 * @extends {BaseAPI}
 */
export class ServiceMissionsAndToursApi
  extends BaseAPI
  implements ServiceMissionsAndToursApiInterface
{
  /**
   * Adapt a tour by adding / removing / reordering jobs
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {File} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public adaptTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    body: File,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .adaptTour(accessToken, missionId, tourId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Adapt a tour by adding / removing / reordering jobs with Tasks
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {TourAdaptionBodyWithTasks} tourAdaptionBodyWithTasks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public adaptTourWithTask(
    accessToken: string,
    missionId: number,
    tourId: number,
    tourAdaptionBodyWithTasks: TourAdaptionBodyWithTasks,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .adaptTourWithTask(accessToken, missionId, tourId, tourAdaptionBodyWithTasks, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Bulk-starts pre and at steps
   * @summary Bulk-starts all open pre- and at-steps of the job
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public bulkStartPreAndAtSteps(
    accessToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .bulkStartPreAndAtSteps(accessToken, missionId, tourId, jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Check if step can be completed
   * @summary Check if step can be completed
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public completeChecks(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .completeChecks(accessToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete the specified step
   * @summary Complete the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TransitionActionBody} transitionActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public completeStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    transitionActionBody: TransitionActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .completeStep(accessToken, missionId, tourId, stepId, transitionActionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Completes the tour
   * @summary Completes the tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {CompleteTourBody} completeTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public completeTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    completeTourBody: CompleteTourBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .completeTour(accessToken, missionId, tourId, completeTourBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new mission
   * @summary Create a new mission
   * @param {string} accessToken
   * @param {MissionBody} missionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public createMission(
    accessToken: string,
    missionBody: MissionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .createMission(accessToken, missionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calculate estimated driving time between two coordinates
   * @summary Calculate estimated driving time between two coordinates
   * @param {string} accessToken
   * @param {number} startLat
   * @param {number} startLon
   * @param {number} endLat
   * @param {number} endLon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public estimatedDrivingTime(
    accessToken: string,
    startLat: number,
    startLon: number,
    endLat: number,
    endLon: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .estimatedDrivingTime(accessToken, startLat, startLon, endLat, endLon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Extends the tour by adding the nearest service job
   * @summary Extends the tour by adding the nearest service job
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ExtendByOneBody} extendByOneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public extendByOneJob(
    accessToken: string,
    missionId: number,
    tourId: number,
    extendByOneBody: ExtendByOneBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .extendByOneJob(accessToken, missionId, tourId, extendByOneBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the specified step
   * @summary Fail the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {FailStepBody} failStepBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public failStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    failStepBody: FailStepBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .failStep(accessToken, missionId, tourId, stepId, failStepBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the tour
   * @summary Fail the tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public failTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .failTour(accessToken, missionId, tourId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified job without trying any Jira transition
   * @summary Forcefully fails / ends the specified job without trying any Jira transition
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public forceEndJob(
    accessToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .forceEndJob(accessToken, missionId, tourId, jobId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified tour without trying any Jira transitions
   * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {EndTourBody} endTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public forceEndTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    endTourBody: EndTourBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .forceEndTour(accessToken, missionId, tourId, endTourBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all open tours for the user
   * @summary Get all open tours for the user
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getAllOpenTours(accessToken: string, options?: AxiosRequestConfig) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getAllOpenTours(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the available complete action options for the specified step
   * @summary Get the available complete action options for the specified step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getCompleteActionOptions(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getCompleteActionOptions(accessToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets mission detail with tour statistics
   * @summary Gets mission detail with tour statistics
   * @param {string} accessToken
   * @param {number} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getMissionWithTourStatistics(
    accessToken: string,
    missionId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getMissionWithTourStatistics(accessToken, missionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets missions
   * @summary Gets missions
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} includeExpired
   * @param {boolean} includeDisabled
   * @param {MissionType} missionType
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getMissions(
    accessToken: string,
    locationId: number,
    includeExpired: boolean,
    includeDisabled: boolean,
    missionType: MissionType,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getMissions(
        accessToken,
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
   * @summary Gets the highest prioritized mission
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} type
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getNextMission(
    accessToken: string,
    lat: number,
    lon: number,
    type: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getNextMission(accessToken, lat, lon, type, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the open tour for the specified tour type (if there is one)
   * @summary Get the open tour for the specified tour type (if there is one)
   * @param {string} accessToken
   * @param {MissionType} tourType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getOpenTour(accessToken: string, tourType: MissionType, options?: AxiosRequestConfig) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getOpenTour(accessToken, tourType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available parking zones for location
   * @summary Get available parking zones for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getParkingZones(
    accessToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getParkingZones(accessToken, locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of service employees
   * @summary Get list of service employees
   * @param {string} accessToken
   * @param {number} locationId
   * @param {MissionType} missionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getServiceEmployees(
    accessToken: string,
    locationId: number,
    missionType: MissionType,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getServiceEmployees(accessToken, locationId, missionType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs for location
   * @summary Get available hubs for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getServiceHubs(
    accessToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getServiceHubs(accessToken, locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service phones for location
   * @summary Get available service phones for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getServicePhones(
    accessToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getServicePhones(accessToken, locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all the service task logs for a service tour by tourId
   * @summary Get service task logs for service tour
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getServiceTourTaskLogs(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getServiceTourTaskLogs(accessToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service vehicles for location
   * @summary Get available service vehicles for location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getServiceVehicles(
    accessToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getServiceVehicles(accessToken, locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour detail
   * @summary Get tour detail
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getTour(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getTour(accessToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour corridor
   * @summary Get tour corridor
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getTourCorridor(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getTourCorridor(accessToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get detailed tour logs
   * @summary Get detailed tour logs
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getTourLogs(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getTourLogs(accessToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs, service vehicles, service phones and service employees as a bundle
   * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} missionType
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getTourParameterOptions(
    accessToken: string,
    lat: number,
    lon: number,
    missionType: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getTourParameterOptions(accessToken, lat, lon, missionType, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour statistics, if available
   * @summary Get tour statistics, if available
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getTourStats(
    accessToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getTourStats(accessToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get paginated list of recent tours
   * @summary Get paginated list of recent tours
   * @param {string} accessToken
   * @param {PaginatedTourFilterBody} paginatedTourFilterBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getToursForLocation(
    accessToken: string,
    paginatedTourFilterBody: PaginatedTourFilterBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getToursForLocation(accessToken, paginatedTourFilterBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of tours for the specified mission
   * @summary Get list of tours for the specified mission
   * @param {string} accessToken
   * @param {number} missionId
   * @param {boolean} includeFinished
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getToursForMission(
    accessToken: string,
    missionId: number,
    includeFinished: boolean,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getToursForMission(accessToken, missionId, includeFinished, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of available vehicle types
   * @summary Get list of available vehicle types
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getVehicleTypes(accessToken: string, options?: AxiosRequestConfig) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getVehicleTypes(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of vehicles with their status being in the specified service type
   * @summary Get a list of vehicles with their status being in the specified service type
   * @param {string} accessToken
   * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public getVehiclesAvailableForService(
    accessToken: string,
    serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .getVehiclesAvailableForService(accessToken, serviceVehiclesFilterSet, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refresh the GPS location of the step
   * @summary Refresh the GPS location of the step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public refreshStepLocation(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .refreshStepLocation(accessToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Request tour from mission
   * @summary Request tour from mission
   * @param {string} accessToken
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public requestTourFromMission(
    accessToken: string,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .requestTourFromMission(accessToken, tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Simulate tour
   * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
   * @param {string} accessToken
   * @param {number} missionId
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public simulateTourFromMission(
    accessToken: string,
    missionId: number,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .simulateTourFromMission(accessToken, missionId, tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start working on the step
   * @summary Start working on the step
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public startStep(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .startStep(accessToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger the specified step action
   * @summary Trigger the specified step action
   * @param {string} accessToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TriggerActionBody} triggerActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public triggerStepAction(
    accessToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    triggerActionBody: TriggerActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .triggerStepAction(accessToken, missionId, tourId, stepId, triggerActionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service hub
   * @summary Create or update a service hub
   * @param {string} accessToken
   * @param {ServiceHub} serviceHub
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public upsertServiceHub(
    accessToken: string,
    serviceHub: ServiceHub,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .upsertServiceHub(accessToken, serviceHub, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service parking zone
   * @summary Create or update a service parking zone
   * @param {string} accessToken
   * @param {ServiceParkingZone} serviceParkingZone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public upsertServiceParkingZone(
    accessToken: string,
    serviceParkingZone: ServiceParkingZone,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .upsertServiceParkingZone(accessToken, serviceParkingZone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service phone
   * @summary Create or update a service phone
   * @param {string} accessToken
   * @param {ServicePhone} servicePhone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public upsertServicePhone(
    accessToken: string,
    servicePhone: ServicePhone,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .upsertServicePhone(accessToken, servicePhone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service vehicle
   * @summary Create or update a service vehicle
   * @param {string} accessToken
   * @param {ServiceVehicle} serviceVehicle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsAndToursApi
   */
  public upsertServiceVehicle(
    accessToken: string,
    serviceVehicle: ServiceVehicle,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsAndToursApiFp(this.configuration)
      .upsertServiceVehicle(accessToken, serviceVehicle, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServiceTaskApi - axios parameter creator
 * @export
 */
export const ServiceTaskApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create subtasks in one batch for multiple assets (by vins)
     * @summary Create subtasks for multiple assets (by vins)
     * @param {string} accessToken
     * @param {Array<InputSubTask>} [inputSubTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchCreateSubTasks: async (
      accessToken: string,
      inputSubTask?: Array<InputSubTask>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('batchCreateSubTasks', 'accessToken', accessToken);
      const localVarPath = `/v2/service/sub-tasks/batch-create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputSubTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Change the task type of a task from service to custom or from custom to service
     * @summary Change the task type of a task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTaskType: async (
      accessToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('changeTaskType', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('changeTaskType', 'taskId', taskId);
      const localVarPath = `/v2/service/tasks/{taskId}/change-task-type`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a comment for a service asset by VIN
     * @summary Create comment on service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceAsset: async (
      accessToken: string,
      vin: string,
      inputComment: InputComment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createCommentOnServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('createCommentOnServiceAsset', 'vin', vin);
      // verify required parameter 'inputComment' is not null or undefined
      assertParamExists('createCommentOnServiceAsset', 'inputComment', inputComment);
      const localVarPath = `/v2/service/asset/{vin}/comment`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputComment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceTask: async (
      accessToken: string,
      taskId: number,
      inputComment: InputComment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'taskId', taskId);
      // verify required parameter 'inputComment' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'inputComment', inputComment);
      const localVarPath = `/v2/service/task/{taskId}/comment`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputComment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTask: async (
      accessToken: string,
      inputServiceTask: InputServiceTask,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createServiceTask', 'accessToken', accessToken);
      // verify required parameter 'inputServiceTask' is not null or undefined
      assertParamExists('createServiceTask', 'inputServiceTask', inputServiceTask);
      const localVarPath = `/v2/service/task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTasks: async (
      accessToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createServiceTasks', 'accessToken', accessToken);
      const localVarPath = `/v2/service/tasks/bulk-create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create and return an Unassigned SubTask linked to a service asset by vin
     * @summary Create a single sub task for an asset (by vin)
     * @param {string} accessToken
     * @param {InputSubTask} inputSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubTask: async (
      accessToken: string,
      inputSubTask: InputSubTask,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createSubTask', 'accessToken', accessToken);
      // verify required parameter 'inputSubTask' is not null or undefined
      assertParamExists('createSubTask', 'inputSubTask', inputSubTask);
      const localVarPath = `/v2/service/sub-tasks/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputSubTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all comments on a service asset by VIN
     * @summary Get comments for service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceAsset: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCommentOnServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('getCommentOnServiceAsset', 'vin', vin);
      const localVarPath = `/v2/service/asset/{vin}/comment`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceTask: async (
      accessToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCommentOnServiceTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getCommentOnServiceTask', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}/comment`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all matching results across the database models for a given search term.
     * @summary Get Search Results Across Data Model
     * @param {string} accessToken
     * @param {string} q
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommonSearch: async (
      accessToken: string,
      q: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCommonSearch', 'accessToken', accessToken);
      // verify required parameter 'q' is not null or undefined
      assertParamExists('getCommonSearch', 'q', q);
      const localVarPath = `/v2/service/common/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all open tasks mapped to the asset info this task relates to
     * @summary Get open tasks with asset info
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} assignmentType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTasksWithAssetInfo: async (
      accessToken: string,
      businessLocationId: number,
      assignmentType: AssignmentType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getOpenTasksWithAssetInfo', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('getOpenTasksWithAssetInfo', 'businessLocationId', businessLocationId);
      // verify required parameter 'assignmentType' is not null or undefined
      assertParamExists('getOpenTasksWithAssetInfo', 'assignmentType', assignmentType);
      const localVarPath = `/v2/service/tasks/open-tasks-with-asset-info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (assignmentType !== undefined) {
        localVarQueryParameter['assignmentType'] = assignmentType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get an overview over open pickup tasks and repair tasks waiting for pickup-task creation
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPickupForRepairTasksOverview: async (
      accessToken: string,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getPickupForRepairTasksOverview', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists(
        'getPickupForRepairTasksOverview',
        'businessLocationId',
        businessLocationId
      );
      const localVarPath = `/v2/service/pickup-task-overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the latest reservations for a given VIN and amount (default 3).
     * @summary Get Reservation History for VIN
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservationHistory: async (
      accessToken: string,
      vin: string,
      amount?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getReservationHistory', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('getReservationHistory', 'vin', vin);
      const localVarPath = `/v2/service/reservations/{vin}/latest`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (amount !== undefined) {
        localVarQueryParameter['amount'] = amount;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} assetVIN
     * @param {boolean} live
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAsset: async (
      accessToken: string,
      assetVIN: string,
      live: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'assetVIN' is not null or undefined
      assertParamExists('getServiceAsset', 'assetVIN', assetVIN);
      // verify required parameter 'live' is not null or undefined
      assertParamExists('getServiceAsset', 'live', live);
      const localVarPath = `/v2/service/asset/{assetVIN}`.replace(
        `{${'assetVIN'}}`,
        encodeURIComponent(String(assetVIN))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (live !== undefined) {
        localVarQueryParameter['live'] = live;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a list of service assets for which a repair tasks in jira is in \'WAITING FOR SCOOTER\'
     * @summary Get a list of service assets waiting for pickup in jira
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetWaitingForPickupInJira: async (
      accessToken: string,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAssetWaitingForPickupInJira', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists(
        'getServiceAssetWaitingForPickupInJira',
        'businessLocationId',
        businessLocationId
      );
      const localVarPath = `/v2/service/assets-waiting-for-pickup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} businessLocationId
     * @param {Array<number>} [vehicleStateIds]
     * @param {string} [licencePlate]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssets: async (
      accessToken: string,
      page: number,
      pageSize: number,
      businessLocationId: number,
      vehicleStateIds?: Array<number>,
      licencePlate?: string,
      vin?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAssets', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getServiceAssets', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getServiceAssets', 'pageSize', pageSize);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('getServiceAssets', 'businessLocationId', businessLocationId);
      const localVarPath = `/v2/service/asset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (vehicleStateIds) {
        localVarQueryParameter['vehicleStateIds'] = vehicleStateIds;
      }

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {AssetFilters} assetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetsFiltered: async (
      accessToken: string,
      assetFilters: AssetFilters,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAssetsFiltered', 'accessToken', accessToken);
      // verify required parameter 'assetFilters' is not null or undefined
      assertParamExists('getServiceAssetsFiltered', 'assetFilters', assetFilters);
      const localVarPath = `/v2/service/assetsfiltered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        assetFilters,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocation: async (
      accessToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceLocation', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceLocation', 'locationId', locationId);
      const localVarPath = `/v2/service/business-location/{locationId}`.replace(
        `{${'locationId'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocations: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceLocations', 'accessToken', accessToken);
      const localVarPath = `/v2/service/business-location`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get an overview over open tasks and related assets
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} taskType
     * @param {number} [missionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceMapTaskOverview: async (
      accessToken: string,
      businessLocationId: number,
      taskType: AssignmentType,
      missionId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceMapTaskOverview', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('getServiceMapTaskOverview', 'businessLocationId', businessLocationId);
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists('getServiceMapTaskOverview', 'taskType', taskType);
      const localVarPath = `/v2/service/service-map-task-overview`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (taskType !== undefined) {
        localVarQueryParameter['taskType'] = taskType;
      }

      if (missionId !== undefined) {
        localVarQueryParameter['missionId'] = missionId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTask: async (
      accessToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getServiceTask', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskLogs: async (
      accessToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTaskLogs', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getServiceTaskLogs', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}/logs`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets a service task by id, and retrieves additional information about the included Asset. Use query parameter live to force updating the AssetInfo (slow request)
     * @summary Get service task alongside the AssetInfo
     * @param {string} accessToken
     * @param {number} taskId
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskWithAssetInfo: async (
      accessToken: string,
      taskId: number,
      live?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTaskWithAssetInfo', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getServiceTaskWithAssetInfo', 'taskId', taskId);
      const localVarPath = `/v2/service/task-with-info/{taskId}`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (live !== undefined) {
        localVarQueryParameter['live'] = live;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {Array<string>} [vins]
     * @param {Array<AssignmentState>} [taskStatus]
     * @param {number} [businessLocationId]
     * @param {string} [licencePlate]
     * @param {number} [taskId]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasks: async (
      accessToken: string,
      page: number,
      pageSize: number,
      vins?: Array<string>,
      taskStatus?: Array<AssignmentState>,
      businessLocationId?: number,
      licencePlate?: string,
      taskId?: number,
      createdAt?: string,
      updatedAt?: string,
      taskType?: AssignmentType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTasks', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getServiceTasks', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getServiceTasks', 'pageSize', pageSize);
      const localVarPath = `/v2/service/task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (vins) {
        localVarQueryParameter['vins'] = vins;
      }

      if (taskStatus) {
        localVarQueryParameter['taskStatus'] = taskStatus;
      }

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (taskId !== undefined) {
        localVarQueryParameter['taskId'] = taskId;
      }

      if (createdAt !== undefined) {
        localVarQueryParameter['createdAt'] =
          (createdAt as any) instanceof Date ? (createdAt as any).toISOString() : createdAt;
      }

      if (updatedAt !== undefined) {
        localVarQueryParameter['updatedAt'] =
          (updatedAt as any) instanceof Date ? (updatedAt as any).toISOString() : updatedAt;
      }

      if (taskType !== undefined) {
        localVarQueryParameter['taskType'] = taskType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get service tasks alongside the AssetInfo
     * @param {string} accessToken
     * @param {AssignmentState} taskStatus
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [businessLocationId]
     * @param {AssignmentType} [taskType]
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasksWithAssetInfos: async (
      accessToken: string,
      taskStatus: AssignmentState,
      page: number,
      pageSize: number,
      businessLocationId?: number,
      taskType?: AssignmentType,
      live?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTasksWithAssetInfos', 'accessToken', accessToken);
      // verify required parameter 'taskStatus' is not null or undefined
      assertParamExists('getServiceTasksWithAssetInfos', 'taskStatus', taskStatus);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getServiceTasksWithAssetInfos', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getServiceTasksWithAssetInfos', 'pageSize', pageSize);
      const localVarPath = `/v2/service/tasks-with-infos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (taskStatus !== undefined) {
        localVarQueryParameter['taskStatus'] = taskStatus;
      }

      if (taskType !== undefined) {
        localVarQueryParameter['taskType'] = taskType;
      }

      if (live !== undefined) {
        localVarQueryParameter['live'] = live;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all subtasks with filters of state, category, vin
     * @summary Fetch paginated filtered subtasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} withLicencePlate
     * @param {Array<AssignmentState>} [states]
     * @param {string} [vin]
     * @param {StepCategory} [category]
     * @param {string} [licencePlate]
     * @param {string} [title]
     * @param {boolean} [titleEqual]
     * @param {string} [createdBefore]
     * @param {string} [createdAfter]
     * @param {number} [locationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubTasks: async (
      accessToken: string,
      page: number,
      pageSize: number,
      withLicencePlate: boolean,
      states?: Array<AssignmentState>,
      vin?: string,
      category?: StepCategory,
      licencePlate?: string,
      title?: string,
      titleEqual?: boolean,
      createdBefore?: string,
      createdAfter?: string,
      locationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getSubTasks', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getSubTasks', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getSubTasks', 'pageSize', pageSize);
      // verify required parameter 'withLicencePlate' is not null or undefined
      assertParamExists('getSubTasks', 'withLicencePlate', withLicencePlate);
      const localVarPath = `/v2/service/sub-tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (withLicencePlate !== undefined) {
        localVarQueryParameter['withLicencePlate'] = withLicencePlate;
      }

      if (states) {
        localVarQueryParameter['states'] = states;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (category !== undefined) {
        for (const [key, value] of Object.entries(category)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (title !== undefined) {
        localVarQueryParameter['title'] = title;
      }

      if (titleEqual !== undefined) {
        localVarQueryParameter['titleEqual'] = titleEqual;
      }

      if (createdBefore !== undefined) {
        localVarQueryParameter['createdBefore'] =
          (createdBefore as any) instanceof Date
            ? (createdBefore as any).toISOString()
            : createdBefore;
      }

      if (createdAfter !== undefined) {
        localVarQueryParameter['createdAfter'] =
          (createdAfter as any) instanceof Date
            ? (createdAfter as any).toISOString()
            : createdAfter;
      }

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the latest notes history for a given VIN and amount (default 3).
     * @summary Get Vehicle Notes History
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleNotesHistory: async (
      accessToken: string,
      vin: string,
      amount?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleNotesHistory', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('getVehicleNotesHistory', 'vin', vin);
      const localVarPath = `/v2/service/notes/{vin}/latest`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (amount !== undefined) {
        localVarQueryParameter['amount'] = amount;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\", returns cancelled tasks
     * @summary Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickupTaskBulkCancel: async (
      accessToken: string,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('pickupTaskBulkCancel', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('pickupTaskBulkCancel', 'businessLocationId', businessLocationId);
      const localVarPath = `/v2/service/pickup/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\", returns created tasks.
     * @summary Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickupTaskBulkCreate: async (
      accessToken: string,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('pickupTaskBulkCreate', 'accessToken', accessToken);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('pickupTaskBulkCreate', 'businessLocationId', businessLocationId);
      const localVarPath = `/v2/service/pickup/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * The failed task will be canceled and then recreated as a new task. All failed subtasks used by the old task will also be canceled and recreated.
     * @summary Reschedule a failed task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rescheduleFailedTask: async (
      accessToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('rescheduleFailedTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('rescheduleFailedTask', 'taskId', taskId);
      const localVarPath = `/v2/service/tasks/{taskId}/reschedule`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets vehicles in \'Relocate/ServiceMode/CustomTour\' state and creates tasks for them as well as cancel outdated tasks.
     * @summary Sync (Create pending and Cancel old) Tasks
     * @param {string} accessToken
     * @param {AssignmentType} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTasksWithWunder: async (
      accessToken: string,
      taskType: AssignmentType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('syncTasksWithWunder', 'accessToken', accessToken);
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists('syncTasksWithWunder', 'taskType', taskType);
      const localVarPath = `/v2/service/tasks/sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (taskType !== undefined) {
        localVarQueryParameter['taskType'] = taskType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update/Give a reason for canceling a task.
     * @summary Update cancel reason for task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {string} cancelReason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCancelReasonOfTask: async (
      accessToken: string,
      taskId: number,
      cancelReason: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateCancelReasonOfTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('updateCancelReasonOfTask', 'taskId', taskId);
      // verify required parameter 'cancelReason' is not null or undefined
      assertParamExists('updateCancelReasonOfTask', 'cancelReason', cancelReason);
      const localVarPath = `/v2/service/task/{taskId}/update-reason`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (cancelReason !== undefined) {
        localVarQueryParameter['cancelReason'] = cancelReason;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update priority of task, allowed values are 1 (highest prio) - 5 (lowest prio).
     * @summary Update priority of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {number} newPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePriorityOfTask: async (
      accessToken: string,
      taskId: number,
      newPriority: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updatePriorityOfTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('updatePriorityOfTask', 'taskId', taskId);
      // verify required parameter 'newPriority' is not null or undefined
      assertParamExists('updatePriorityOfTask', 'newPriority', newPriority);
      const localVarPath = `/v2/service/task/{taskId}/update-priority`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (newPriority !== undefined) {
        localVarQueryParameter['newPriority'] = newPriority;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update state of task.
     * @summary Update state of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {AssignmentState} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfTask: async (
      accessToken: string,
      taskId: number,
      newState: AssignmentState,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateStateOfTask', 'accessToken', accessToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('updateStateOfTask', 'taskId', taskId);
      // verify required parameter 'newState' is not null or undefined
      assertParamExists('updateStateOfTask', 'newState', newState);
      const localVarPath = `/v2/service/task/{taskId}/update-state`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (newState !== undefined) {
        localVarQueryParameter['newState'] = newState;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update a subtask by id to a new state
     * @summary Update a subtask
     * @param {string} accessToken
     * @param {number} subTaskId
     * @param {UpdateSubTask} updateSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubTask: async (
      accessToken: string,
      subTaskId: number,
      updateSubTask: UpdateSubTask,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateSubTask', 'accessToken', accessToken);
      // verify required parameter 'subTaskId' is not null or undefined
      assertParamExists('updateSubTask', 'subTaskId', subTaskId);
      // verify required parameter 'updateSubTask' is not null or undefined
      assertParamExists('updateSubTask', 'updateSubTask', updateSubTask);
      const localVarPath = `/v2/service/sub-tasks/{subTaskId}/update`.replace(
        `{${'subTaskId'}}`,
        encodeURIComponent(String(subTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSubTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update a list of subtasks given a list of ids
     * @summary Update a list of subtasks
     * @param {string} accessToken
     * @param {UpdateSubTasks} updateSubTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubTasks: async (
      accessToken: string,
      updateSubTasks: UpdateSubTasks,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateSubTasks', 'accessToken', accessToken);
      // verify required parameter 'updateSubTasks' is not null or undefined
      assertParamExists('updateSubTasks', 'updateSubTasks', updateSubTasks);
      const localVarPath = `/v2/service/sub-tasks/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSubTasks,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Validate a list of VINs
     * @summary Validate VINs
     * @param {string} accessToken
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVins: async (
      accessToken: string,
      requestBody?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('validateVins', 'accessToken', accessToken);
      const localVarPath = `/v2/service/validate-vins`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ServiceTaskApi - functional programming interface
 * @export
 */
export const ServiceTaskApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceTaskApiAxiosParamCreator(configuration);
  return {
    /**
     * Create subtasks in one batch for multiple assets (by vins)
     * @summary Create subtasks for multiple assets (by vins)
     * @param {string} accessToken
     * @param {Array<InputSubTask>} [inputSubTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchCreateSubTasks(
      accessToken: string,
      inputSubTask?: Array<InputSubTask>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchCreateSubTasks(
        accessToken,
        inputSubTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.batchCreateSubTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Change the task type of a task from service to custom or from custom to service
     * @summary Change the task type of a task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeTaskType(
      accessToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeTaskType(
        accessToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.changeTaskType']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a comment for a service asset by VIN
     * @summary Create comment on service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommentOnServiceAsset(
      accessToken: string,
      vin: string,
      inputComment: InputComment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentOnServiceAsset(
        accessToken,
        vin,
        inputComment,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.createCommentOnServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommentOnServiceTask(
      accessToken: string,
      taskId: number,
      inputComment: InputComment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentOnServiceTask(
        accessToken,
        taskId,
        inputComment,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.createCommentOnServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createServiceTask(
      accessToken: string,
      inputServiceTask: InputServiceTask,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceTask(
        accessToken,
        inputServiceTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.createServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createServiceTasks(
      accessToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceTasks(
        accessToken,
        inputServiceTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.createServiceTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create and return an Unassigned SubTask linked to a service asset by vin
     * @summary Create a single sub task for an asset (by vin)
     * @param {string} accessToken
     * @param {InputSubTask} inputSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSubTask(
      accessToken: string,
      inputSubTask: InputSubTask,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSubTask(
        accessToken,
        inputSubTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.createSubTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all comments on a service asset by VIN
     * @summary Get comments for service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentOnServiceAsset(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentOnServiceAsset(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getCommentOnServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentOnServiceTask(
      accessToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentOnServiceTask(
        accessToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getCommentOnServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all matching results across the database models for a given search term.
     * @summary Get Search Results Across Data Model
     * @param {string} accessToken
     * @param {string} q
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommonSearch(
      accessToken: string,
      q: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonSearchResult>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommonSearch(
        accessToken,
        q,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getCommonSearch']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all open tasks mapped to the asset info this task relates to
     * @summary Get open tasks with asset info
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} assignmentType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenTasksWithAssetInfo(
      accessToken: string,
      businessLocationId: number,
      assignmentType: AssignmentType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskAssetInfo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenTasksWithAssetInfo(
        accessToken,
        businessLocationId,
        assignmentType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getOpenTasksWithAssetInfo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get an overview over open pickup tasks and repair tasks waiting for pickup-task creation
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPickupForRepairTasksOverview(
      accessToken: string,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupForRepairTaskOverview>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPickupForRepairTasksOverview(
        accessToken,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getPickupForRepairTasksOverview']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the latest reservations for a given VIN and amount (default 3).
     * @summary Get Reservation History for VIN
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReservationHistory(
      accessToken: string,
      vin: string,
      amount?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericReservation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReservationHistory(
        accessToken,
        vin,
        amount,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getReservationHistory']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} assetVIN
     * @param {boolean} live
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAsset(
      accessToken: string,
      assetVIN: string,
      live: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAsset(
        accessToken,
        assetVIN,
        live,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a list of service assets for which a repair tasks in jira is in \'WAITING FOR SCOOTER\'
     * @summary Get a list of service assets waiting for pickup in jira
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAssetWaitingForPickupInJira(
      accessToken: string,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetInfo>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getServiceAssetWaitingForPickupInJira(
          accessToken,
          businessLocationId,
          options
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceAssetWaitingForPickupInJira']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} businessLocationId
     * @param {Array<number>} [vehicleStateIds]
     * @param {string} [licencePlate]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAssets(
      accessToken: string,
      page: number,
      pageSize: number,
      businessLocationId: number,
      vehicleStateIds?: Array<number>,
      licencePlate?: string,
      vin?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultAssetInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAssets(
        accessToken,
        page,
        pageSize,
        businessLocationId,
        vehicleStateIds,
        licencePlate,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getServiceAssets']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {AssetFilters} assetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAssetsFiltered(
      accessToken: string,
      assetFilters: AssetFilters,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetInfo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAssetsFiltered(
        accessToken,
        assetFilters,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceAssetsFiltered']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLocation(
      accessToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessLocation1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceLocation(
        accessToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLocations(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLocation1>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceLocations(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceLocations']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get an overview over open tasks and related assets
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} taskType
     * @param {number} [missionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceMapTaskOverview(
      accessToken: string,
      businessLocationId: number,
      taskType: AssignmentType,
      missionId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceMapTaskOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceMapTaskOverview(
        accessToken,
        businessLocationId,
        taskType,
        missionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceMapTaskOverview']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTask(
      accessToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTask(
        accessToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTaskLogs(
      accessToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskLog>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTaskLogs(
        accessToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceTaskLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets a service task by id, and retrieves additional information about the included Asset. Use query parameter live to force updating the AssetInfo (slow request)
     * @summary Get service task alongside the AssetInfo
     * @param {string} accessToken
     * @param {number} taskId
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTaskWithAssetInfo(
      accessToken: string,
      taskId: number,
      live?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskAssetInfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTaskWithAssetInfo(
        accessToken,
        taskId,
        live,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceTaskWithAssetInfo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {Array<string>} [vins]
     * @param {Array<AssignmentState>} [taskStatus]
     * @param {number} [businessLocationId]
     * @param {string} [licencePlate]
     * @param {number} [taskId]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      vins?: Array<string>,
      taskStatus?: Array<AssignmentState>,
      businessLocationId?: number,
      licencePlate?: string,
      taskId?: number,
      createdAt?: string,
      updatedAt?: string,
      taskType?: AssignmentType,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultServiceTask>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTasks(
        accessToken,
        page,
        pageSize,
        vins,
        taskStatus,
        businessLocationId,
        licencePlate,
        taskId,
        createdAt,
        updatedAt,
        taskType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getServiceTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get service tasks alongside the AssetInfo
     * @param {string} accessToken
     * @param {AssignmentState} taskStatus
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [businessLocationId]
     * @param {AssignmentType} [taskType]
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTasksWithAssetInfos(
      accessToken: string,
      taskStatus: AssignmentState,
      page: number,
      pageSize: number,
      businessLocationId?: number,
      taskType?: AssignmentType,
      live?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultTaskAssetInfo>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTasksWithAssetInfos(
        accessToken,
        taskStatus,
        page,
        pageSize,
        businessLocationId,
        taskType,
        live,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getServiceTasksWithAssetInfos']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all subtasks with filters of state, category, vin
     * @summary Fetch paginated filtered subtasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} withLicencePlate
     * @param {Array<AssignmentState>} [states]
     * @param {string} [vin]
     * @param {StepCategory} [category]
     * @param {string} [licencePlate]
     * @param {string} [title]
     * @param {boolean} [titleEqual]
     * @param {string} [createdBefore]
     * @param {string} [createdAfter]
     * @param {number} [locationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSubTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      withLicencePlate: boolean,
      states?: Array<AssignmentState>,
      vin?: string,
      category?: StepCategory,
      licencePlate?: string,
      title?: string,
      titleEqual?: boolean,
      createdBefore?: string,
      createdAfter?: string,
      locationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultSubTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSubTasks(
        accessToken,
        page,
        pageSize,
        withLicencePlate,
        states,
        vin,
        category,
        licencePlate,
        title,
        titleEqual,
        createdBefore,
        createdAfter,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.getSubTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the latest notes history for a given VIN and amount (default 3).
     * @summary Get Vehicle Notes History
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleNotesHistory(
      accessToken: string,
      vin: string,
      amount?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericVehicleNote>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleNotesHistory(
        accessToken,
        vin,
        amount,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.getVehicleNotesHistory']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\", returns cancelled tasks
     * @summary Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pickupTaskBulkCancel(
      accessToken: string,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pickupTaskBulkCancel(
        accessToken,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.pickupTaskBulkCancel']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\", returns created tasks.
     * @summary Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pickupTaskBulkCreate(
      accessToken: string,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pickupTaskBulkCreate(
        accessToken,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.pickupTaskBulkCreate']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * The failed task will be canceled and then recreated as a new task. All failed subtasks used by the old task will also be canceled and recreated.
     * @summary Reschedule a failed task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rescheduleFailedTask(
      accessToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rescheduleFailedTask(
        accessToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.rescheduleFailedTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets vehicles in \'Relocate/ServiceMode/CustomTour\' state and creates tasks for them as well as cancel outdated tasks.
     * @summary Sync (Create pending and Cancel old) Tasks
     * @param {string} accessToken
     * @param {AssignmentType} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncTasksWithWunder(
      accessToken: string,
      taskType: AssignmentType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncTasksWithWunder(
        accessToken,
        taskType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.syncTasksWithWunder']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update/Give a reason for canceling a task.
     * @summary Update cancel reason for task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {string} cancelReason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCancelReasonOfTask(
      accessToken: string,
      taskId: number,
      cancelReason: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCancelReasonOfTask(
        accessToken,
        taskId,
        cancelReason,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.updateCancelReasonOfTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update priority of task, allowed values are 1 (highest prio) - 5 (lowest prio).
     * @summary Update priority of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {number} newPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePriorityOfTask(
      accessToken: string,
      taskId: number,
      newPriority: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePriorityOfTask(
        accessToken,
        taskId,
        newPriority,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.updatePriorityOfTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update state of task.
     * @summary Update state of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {AssignmentState} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateOfTask(
      accessToken: string,
      taskId: number,
      newState: AssignmentState,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateOfTask(
        accessToken,
        taskId,
        newState,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceTaskApi.updateStateOfTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update a subtask by id to a new state
     * @summary Update a subtask
     * @param {string} accessToken
     * @param {number} subTaskId
     * @param {UpdateSubTask} updateSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubTask(
      accessToken: string,
      subTaskId: number,
      updateSubTask: UpdateSubTask,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubTask(
        accessToken,
        subTaskId,
        updateSubTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.updateSubTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update a list of subtasks given a list of ids
     * @summary Update a list of subtasks
     * @param {string} accessToken
     * @param {UpdateSubTasks} updateSubTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSubTasks(
      accessToken: string,
      updateSubTasks: UpdateSubTasks,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubTasks(
        accessToken,
        updateSubTasks,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.updateSubTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validate a list of VINs
     * @summary Validate VINs
     * @param {string} accessToken
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateVins(
      accessToken: string,
      requestBody?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateVins(
        accessToken,
        requestBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceTaskApi.validateVins']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * ServiceTaskApi - factory interface
 * @export
 */
export const ServiceTaskApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServiceTaskApiFp(configuration);
  return {
    /**
     * Create subtasks in one batch for multiple assets (by vins)
     * @summary Create subtasks for multiple assets (by vins)
     * @param {string} accessToken
     * @param {Array<InputSubTask>} [inputSubTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchCreateSubTasks(
      accessToken: string,
      inputSubTask?: Array<InputSubTask>,
      options?: any
    ): AxiosPromise<Array<SubTask>> {
      return localVarFp
        .batchCreateSubTasks(accessToken, inputSubTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Change the task type of a task from service to custom or from custom to service
     * @summary Change the task type of a task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTaskType(accessToken: string, taskId: number, options?: any): AxiosPromise<ServiceTask> {
      return localVarFp
        .changeTaskType(accessToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a comment for a service asset by VIN
     * @summary Create comment on service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceAsset(
      accessToken: string,
      vin: string,
      inputComment: InputComment,
      options?: any
    ): AxiosPromise<Asset> {
      return localVarFp
        .createCommentOnServiceAsset(accessToken, vin, inputComment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceTask(
      accessToken: string,
      taskId: number,
      inputComment: InputComment,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .createCommentOnServiceTask(accessToken, taskId, inputComment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTask(
      accessToken: string,
      inputServiceTask: InputServiceTask,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .createServiceTask(accessToken, inputServiceTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTasks(
      accessToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .createServiceTasks(accessToken, inputServiceTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create and return an Unassigned SubTask linked to a service asset by vin
     * @summary Create a single sub task for an asset (by vin)
     * @param {string} accessToken
     * @param {InputSubTask} inputSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSubTask(
      accessToken: string,
      inputSubTask: InputSubTask,
      options?: any
    ): AxiosPromise<SubTask> {
      return localVarFp
        .createSubTask(accessToken, inputSubTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all comments on a service asset by VIN
     * @summary Get comments for service asset
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceAsset(
      accessToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<Array<Comment>> {
      return localVarFp
        .getCommentOnServiceAsset(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceTask(
      accessToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<Array<Comment>> {
      return localVarFp
        .getCommentOnServiceTask(accessToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all matching results across the database models for a given search term.
     * @summary Get Search Results Across Data Model
     * @param {string} accessToken
     * @param {string} q
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommonSearch(
      accessToken: string,
      q: string,
      options?: any
    ): AxiosPromise<Array<CommonSearchResult>> {
      return localVarFp
        .getCommonSearch(accessToken, q, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all open tasks mapped to the asset info this task relates to
     * @summary Get open tasks with asset info
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} assignmentType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTasksWithAssetInfo(
      accessToken: string,
      businessLocationId: number,
      assignmentType: AssignmentType,
      options?: any
    ): AxiosPromise<Array<TaskAssetInfo>> {
      return localVarFp
        .getOpenTasksWithAssetInfo(accessToken, businessLocationId, assignmentType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an overview over open pickup tasks and repair tasks waiting for pickup-task creation
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPickupForRepairTasksOverview(
      accessToken: string,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<PickupForRepairTaskOverview> {
      return localVarFp
        .getPickupForRepairTasksOverview(accessToken, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the latest reservations for a given VIN and amount (default 3).
     * @summary Get Reservation History for VIN
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservationHistory(
      accessToken: string,
      vin: string,
      amount?: number,
      options?: any
    ): AxiosPromise<Array<GenericReservation>> {
      return localVarFp
        .getReservationHistory(accessToken, vin, amount, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} assetVIN
     * @param {boolean} live
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAsset(
      accessToken: string,
      assetVIN: string,
      live: boolean,
      options?: any
    ): AxiosPromise<AssetInfo> {
      return localVarFp
        .getServiceAsset(accessToken, assetVIN, live, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of service assets for which a repair tasks in jira is in \'WAITING FOR SCOOTER\'
     * @summary Get a list of service assets waiting for pickup in jira
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetWaitingForPickupInJira(
      accessToken: string,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<Array<AssetInfo>> {
      return localVarFp
        .getServiceAssetWaitingForPickupInJira(accessToken, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} businessLocationId
     * @param {Array<number>} [vehicleStateIds]
     * @param {string} [licencePlate]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssets(
      accessToken: string,
      page: number,
      pageSize: number,
      businessLocationId: number,
      vehicleStateIds?: Array<number>,
      licencePlate?: string,
      vin?: string,
      options?: any
    ): AxiosPromise<PaginatedResultAssetInfo> {
      return localVarFp
        .getServiceAssets(
          accessToken,
          page,
          pageSize,
          businessLocationId,
          vehicleStateIds,
          licencePlate,
          vin,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {AssetFilters} assetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetsFiltered(
      accessToken: string,
      assetFilters: AssetFilters,
      options?: any
    ): AxiosPromise<Array<AssetInfo>> {
      return localVarFp
        .getServiceAssetsFiltered(accessToken, assetFilters, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocation(
      accessToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<BusinessLocation1> {
      return localVarFp
        .getServiceLocation(accessToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocations(
      accessToken: string,
      options?: any
    ): AxiosPromise<Array<BusinessLocation1>> {
      return localVarFp
        .getServiceLocations(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get an overview over open tasks and related assets
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {AssignmentType} taskType
     * @param {number} [missionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceMapTaskOverview(
      accessToken: string,
      businessLocationId: number,
      taskType: AssignmentType,
      missionId?: number,
      options?: any
    ): AxiosPromise<ServiceMapTaskOverview> {
      return localVarFp
        .getServiceMapTaskOverview(accessToken, businessLocationId, taskType, missionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTask(accessToken: string, taskId: number, options?: any): AxiosPromise<ServiceTask> {
      return localVarFp
        .getServiceTask(accessToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskLogs(
      accessToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<Array<TaskLog>> {
      return localVarFp
        .getServiceTaskLogs(accessToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a service task by id, and retrieves additional information about the included Asset. Use query parameter live to force updating the AssetInfo (slow request)
     * @summary Get service task alongside the AssetInfo
     * @param {string} accessToken
     * @param {number} taskId
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskWithAssetInfo(
      accessToken: string,
      taskId: number,
      live?: boolean,
      options?: any
    ): AxiosPromise<TaskAssetInfo> {
      return localVarFp
        .getServiceTaskWithAssetInfo(accessToken, taskId, live, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {Array<string>} [vins]
     * @param {Array<AssignmentState>} [taskStatus]
     * @param {number} [businessLocationId]
     * @param {string} [licencePlate]
     * @param {number} [taskId]
     * @param {string} [createdAt]
     * @param {string} [updatedAt]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      vins?: Array<string>,
      taskStatus?: Array<AssignmentState>,
      businessLocationId?: number,
      licencePlate?: string,
      taskId?: number,
      createdAt?: string,
      updatedAt?: string,
      taskType?: AssignmentType,
      options?: any
    ): AxiosPromise<PaginatedResultServiceTask> {
      return localVarFp
        .getServiceTasks(
          accessToken,
          page,
          pageSize,
          vins,
          taskStatus,
          businessLocationId,
          licencePlate,
          taskId,
          createdAt,
          updatedAt,
          taskType,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get service tasks alongside the AssetInfo
     * @param {string} accessToken
     * @param {AssignmentState} taskStatus
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [businessLocationId]
     * @param {AssignmentType} [taskType]
     * @param {boolean} [live]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasksWithAssetInfos(
      accessToken: string,
      taskStatus: AssignmentState,
      page: number,
      pageSize: number,
      businessLocationId?: number,
      taskType?: AssignmentType,
      live?: boolean,
      options?: any
    ): AxiosPromise<PaginatedResultTaskAssetInfo> {
      return localVarFp
        .getServiceTasksWithAssetInfos(
          accessToken,
          taskStatus,
          page,
          pageSize,
          businessLocationId,
          taskType,
          live,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all subtasks with filters of state, category, vin
     * @summary Fetch paginated filtered subtasks
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {boolean} withLicencePlate
     * @param {Array<AssignmentState>} [states]
     * @param {string} [vin]
     * @param {StepCategory} [category]
     * @param {string} [licencePlate]
     * @param {string} [title]
     * @param {boolean} [titleEqual]
     * @param {string} [createdBefore]
     * @param {string} [createdAfter]
     * @param {number} [locationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSubTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      withLicencePlate: boolean,
      states?: Array<AssignmentState>,
      vin?: string,
      category?: StepCategory,
      licencePlate?: string,
      title?: string,
      titleEqual?: boolean,
      createdBefore?: string,
      createdAfter?: string,
      locationId?: number,
      options?: any
    ): AxiosPromise<PaginatedResultSubTask> {
      return localVarFp
        .getSubTasks(
          accessToken,
          page,
          pageSize,
          withLicencePlate,
          states,
          vin,
          category,
          licencePlate,
          title,
          titleEqual,
          createdBefore,
          createdAfter,
          locationId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the latest notes history for a given VIN and amount (default 3).
     * @summary Get Vehicle Notes History
     * @param {string} accessToken
     * @param {string} vin
     * @param {number} [amount]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleNotesHistory(
      accessToken: string,
      vin: string,
      amount?: number,
      options?: any
    ): AxiosPromise<Array<GenericVehicleNote>> {
      return localVarFp
        .getVehicleNotesHistory(accessToken, vin, amount, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\", returns cancelled tasks
     * @summary Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickupTaskBulkCancel(
      accessToken: string,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .pickupTaskBulkCancel(accessToken, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\", returns created tasks.
     * @summary Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\"
     * @param {string} accessToken
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pickupTaskBulkCreate(
      accessToken: string,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .pickupTaskBulkCreate(accessToken, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * The failed task will be canceled and then recreated as a new task. All failed subtasks used by the old task will also be canceled and recreated.
     * @summary Reschedule a failed task
     * @param {string} accessToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rescheduleFailedTask(
      accessToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .rescheduleFailedTask(accessToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets vehicles in \'Relocate/ServiceMode/CustomTour\' state and creates tasks for them as well as cancel outdated tasks.
     * @summary Sync (Create pending and Cancel old) Tasks
     * @param {string} accessToken
     * @param {AssignmentType} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTasksWithWunder(
      accessToken: string,
      taskType: AssignmentType,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .syncTasksWithWunder(accessToken, taskType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update/Give a reason for canceling a task.
     * @summary Update cancel reason for task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {string} cancelReason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCancelReasonOfTask(
      accessToken: string,
      taskId: number,
      cancelReason: string,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .updateCancelReasonOfTask(accessToken, taskId, cancelReason, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update priority of task, allowed values are 1 (highest prio) - 5 (lowest prio).
     * @summary Update priority of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {number} newPriority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePriorityOfTask(
      accessToken: string,
      taskId: number,
      newPriority: number,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .updatePriorityOfTask(accessToken, taskId, newPriority, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update state of task.
     * @summary Update state of task.
     * @param {string} accessToken
     * @param {number} taskId
     * @param {AssignmentState} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfTask(
      accessToken: string,
      taskId: number,
      newState: AssignmentState,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .updateStateOfTask(accessToken, taskId, newState, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a subtask by id to a new state
     * @summary Update a subtask
     * @param {string} accessToken
     * @param {number} subTaskId
     * @param {UpdateSubTask} updateSubTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubTask(
      accessToken: string,
      subTaskId: number,
      updateSubTask: UpdateSubTask,
      options?: any
    ): AxiosPromise<SubTask> {
      return localVarFp
        .updateSubTask(accessToken, subTaskId, updateSubTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a list of subtasks given a list of ids
     * @summary Update a list of subtasks
     * @param {string} accessToken
     * @param {UpdateSubTasks} updateSubTasks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSubTasks(
      accessToken: string,
      updateSubTasks: UpdateSubTasks,
      options?: any
    ): AxiosPromise<Array<SubTask>> {
      return localVarFp
        .updateSubTasks(accessToken, updateSubTasks, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validate a list of VINs
     * @summary Validate VINs
     * @param {string} accessToken
     * @param {Array<string>} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVins(
      accessToken: string,
      requestBody?: Array<string>,
      options?: any
    ): AxiosPromise<ValidationResponse> {
      return localVarFp
        .validateVins(accessToken, requestBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ServiceTaskApi - interface
 * @export
 * @interface ServiceTaskApi
 */
export interface ServiceTaskApiInterface {
  /**
   * Create subtasks in one batch for multiple assets (by vins)
   * @summary Create subtasks for multiple assets (by vins)
   * @param {string} accessToken
   * @param {Array<InputSubTask>} [inputSubTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  batchCreateSubTasks(
    accessToken: string,
    inputSubTask?: Array<InputSubTask>,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<SubTask>>;

  /**
   * Change the task type of a task from service to custom or from custom to service
   * @summary Change the task type of a task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  changeTaskType(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Creates a comment for a service asset by VIN
   * @summary Create comment on service asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  createCommentOnServiceAsset(
    accessToken: string,
    vin: string,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ): AxiosPromise<Asset>;

  /**
   * Creates a comment for a service task by taskId
   * @summary Create comment on service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  createCommentOnServiceTask(
    accessToken: string,
    taskId: number,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Creates a new service task
   * @summary Create service task
   * @param {string} accessToken
   * @param {InputServiceTask} inputServiceTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  createServiceTask(
    accessToken: string,
    inputServiceTask: InputServiceTask,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Creates a new list of service tasks
   * @summary Create bulk service tasks
   * @param {string} accessToken
   * @param {Array<InputServiceTask>} [inputServiceTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  createServiceTasks(
    accessToken: string,
    inputServiceTask?: Array<InputServiceTask>,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceTask>>;

  /**
   * Create and return an Unassigned SubTask linked to a service asset by vin
   * @summary Create a single sub task for an asset (by vin)
   * @param {string} accessToken
   * @param {InputSubTask} inputSubTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  createSubTask(
    accessToken: string,
    inputSubTask: InputSubTask,
    options?: AxiosRequestConfig
  ): AxiosPromise<SubTask>;

  /**
   * Gets all comments on a service asset by VIN
   * @summary Get comments for service asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getCommentOnServiceAsset(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Comment>>;

  /**
   * Gets all comments on a service task by taskId
   * @summary Get comments for service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getCommentOnServiceTask(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Comment>>;

  /**
   * Get all matching results across the database models for a given search term.
   * @summary Get Search Results Across Data Model
   * @param {string} accessToken
   * @param {string} q
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getCommonSearch(
    accessToken: string,
    q: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<CommonSearchResult>>;

  /**
   * Get all open tasks mapped to the asset info this task relates to
   * @summary Get open tasks with asset info
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {AssignmentType} assignmentType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getOpenTasksWithAssetInfo(
    accessToken: string,
    businessLocationId: number,
    assignmentType: AssignmentType,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<TaskAssetInfo>>;

  /**
   *
   * @summary Get an overview over open pickup tasks and repair tasks waiting for pickup-task creation
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getPickupForRepairTasksOverview(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<PickupForRepairTaskOverview>;

  /**
   * Get the latest reservations for a given VIN and amount (default 3).
   * @summary Get Reservation History for VIN
   * @param {string} accessToken
   * @param {string} vin
   * @param {number} [amount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getReservationHistory(
    accessToken: string,
    vin: string,
    amount?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<GenericReservation>>;

  /**
   * Get a specific service asset (vehicle) by the assets vin
   * @summary Get service asset (vehicle) by vin
   * @param {string} accessToken
   * @param {string} assetVIN
   * @param {boolean} live
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceAsset(
    accessToken: string,
    assetVIN: string,
    live: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<AssetInfo>;

  /**
   * Get a list of service assets for which a repair tasks in jira is in \'WAITING FOR SCOOTER\'
   * @summary Get a list of service assets waiting for pickup in jira
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceAssetWaitingForPickupInJira(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<AssetInfo>>;

  /**
   * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
   * @summary Get service assets (vehicles)
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} businessLocationId
   * @param {Array<number>} [vehicleStateIds]
   * @param {string} [licencePlate]
   * @param {string} [vin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceAssets(
    accessToken: string,
    page: number,
    pageSize: number,
    businessLocationId: number,
    vehicleStateIds?: Array<number>,
    licencePlate?: string,
    vin?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultAssetInfo>;

  /**
   * Retrieve service assets (vehicles) filtered based on provided criteria.
   * @summary Get filtered service assets (vehicles)
   * @param {string} accessToken
   * @param {AssetFilters} assetFilters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceAssetsFiltered(
    accessToken: string,
    assetFilters: AssetFilters,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<AssetInfo>>;

  /**
   * Get a specific service location.
   * @summary Get a specific service location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceLocation(
    accessToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<BusinessLocation1>;

  /**
   * Get service locations.
   * @summary Get service locations.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceLocations(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<BusinessLocation1>>;

  /**
   *
   * @summary Get an overview over open tasks and related assets
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {AssignmentType} taskType
   * @param {number} [missionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceMapTaskOverview(
    accessToken: string,
    businessLocationId: number,
    taskType: AssignmentType,
    missionId?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceMapTaskOverview>;

  /**
   * Gets a service task by id
   * @summary Get service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceTask(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Get all the logs for a service task by taskId
   * @summary Get logs for service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceTaskLogs(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<TaskLog>>;

  /**
   * Gets a service task by id, and retrieves additional information about the included Asset. Use query parameter live to force updating the AssetInfo (slow request)
   * @summary Get service task alongside the AssetInfo
   * @param {string} accessToken
   * @param {number} taskId
   * @param {boolean} [live]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceTaskWithAssetInfo(
    accessToken: string,
    taskId: number,
    live?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskAssetInfo>;

  /**
   *
   * @summary Get service tasks
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {Array<string>} [vins]
   * @param {Array<AssignmentState>} [taskStatus]
   * @param {number} [businessLocationId]
   * @param {string} [licencePlate]
   * @param {number} [taskId]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {AssignmentType} [taskType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    vins?: Array<string>,
    taskStatus?: Array<AssignmentState>,
    businessLocationId?: number,
    licencePlate?: string,
    taskId?: number,
    createdAt?: string,
    updatedAt?: string,
    taskType?: AssignmentType,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultServiceTask>;

  /**
   *
   * @summary Get service tasks alongside the AssetInfo
   * @param {string} accessToken
   * @param {AssignmentState} taskStatus
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [businessLocationId]
   * @param {AssignmentType} [taskType]
   * @param {boolean} [live]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getServiceTasksWithAssetInfos(
    accessToken: string,
    taskStatus: AssignmentState,
    page: number,
    pageSize: number,
    businessLocationId?: number,
    taskType?: AssignmentType,
    live?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultTaskAssetInfo>;

  /**
   * Get all subtasks with filters of state, category, vin
   * @summary Fetch paginated filtered subtasks
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {boolean} withLicencePlate
   * @param {Array<AssignmentState>} [states]
   * @param {string} [vin]
   * @param {StepCategory} [category]
   * @param {string} [licencePlate]
   * @param {string} [title]
   * @param {boolean} [titleEqual]
   * @param {string} [createdBefore]
   * @param {string} [createdAfter]
   * @param {number} [locationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getSubTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    withLicencePlate: boolean,
    states?: Array<AssignmentState>,
    vin?: string,
    category?: StepCategory,
    licencePlate?: string,
    title?: string,
    titleEqual?: boolean,
    createdBefore?: string,
    createdAfter?: string,
    locationId?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultSubTask>;

  /**
   * Get the latest notes history for a given VIN and amount (default 3).
   * @summary Get Vehicle Notes History
   * @param {string} accessToken
   * @param {string} vin
   * @param {number} [amount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  getVehicleNotesHistory(
    accessToken: string,
    vin: string,
    amount?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<GenericVehicleNote>>;

  /**
   * Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\", returns cancelled tasks
   * @summary Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\"
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  pickupTaskBulkCancel(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceTask>>;

  /**
   * Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\", returns created tasks.
   * @summary Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\"
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  pickupTaskBulkCreate(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceTask>>;

  /**
   * The failed task will be canceled and then recreated as a new task. All failed subtasks used by the old task will also be canceled and recreated.
   * @summary Reschedule a failed task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  rescheduleFailedTask(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Gets vehicles in \'Relocate/ServiceMode/CustomTour\' state and creates tasks for them as well as cancel outdated tasks.
   * @summary Sync (Create pending and Cancel old) Tasks
   * @param {string} accessToken
   * @param {AssignmentType} taskType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  syncTasksWithWunder(
    accessToken: string,
    taskType: AssignmentType,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ServiceTask>>;

  /**
   * Update/Give a reason for canceling a task.
   * @summary Update cancel reason for task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {string} cancelReason
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  updateCancelReasonOfTask(
    accessToken: string,
    taskId: number,
    cancelReason: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Update priority of task, allowed values are 1 (highest prio) - 5 (lowest prio).
   * @summary Update priority of task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {number} newPriority
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  updatePriorityOfTask(
    accessToken: string,
    taskId: number,
    newPriority: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Update state of task.
   * @summary Update state of task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {AssignmentState} newState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  updateStateOfTask(
    accessToken: string,
    taskId: number,
    newState: AssignmentState,
    options?: AxiosRequestConfig
  ): AxiosPromise<ServiceTask>;

  /**
   * Update a subtask by id to a new state
   * @summary Update a subtask
   * @param {string} accessToken
   * @param {number} subTaskId
   * @param {UpdateSubTask} updateSubTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  updateSubTask(
    accessToken: string,
    subTaskId: number,
    updateSubTask: UpdateSubTask,
    options?: AxiosRequestConfig
  ): AxiosPromise<SubTask>;

  /**
   * Update a list of subtasks given a list of ids
   * @summary Update a list of subtasks
   * @param {string} accessToken
   * @param {UpdateSubTasks} updateSubTasks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  updateSubTasks(
    accessToken: string,
    updateSubTasks: UpdateSubTasks,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<SubTask>>;

  /**
   * Validate a list of VINs
   * @summary Validate VINs
   * @param {string} accessToken
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApiInterface
   */
  validateVins(
    accessToken: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ): AxiosPromise<ValidationResponse>;
}

/**
 * ServiceTaskApi - object-oriented interface
 * @export
 * @class ServiceTaskApi
 * @extends {BaseAPI}
 */
export class ServiceTaskApi extends BaseAPI implements ServiceTaskApiInterface {
  /**
   * Create subtasks in one batch for multiple assets (by vins)
   * @summary Create subtasks for multiple assets (by vins)
   * @param {string} accessToken
   * @param {Array<InputSubTask>} [inputSubTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public batchCreateSubTasks(
    accessToken: string,
    inputSubTask?: Array<InputSubTask>,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .batchCreateSubTasks(accessToken, inputSubTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Change the task type of a task from service to custom or from custom to service
   * @summary Change the task type of a task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public changeTaskType(accessToken: string, taskId: number, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .changeTaskType(accessToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a comment for a service asset by VIN
   * @summary Create comment on service asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public createCommentOnServiceAsset(
    accessToken: string,
    vin: string,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .createCommentOnServiceAsset(accessToken, vin, inputComment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a comment for a service task by taskId
   * @summary Create comment on service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public createCommentOnServiceTask(
    accessToken: string,
    taskId: number,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .createCommentOnServiceTask(accessToken, taskId, inputComment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new service task
   * @summary Create service task
   * @param {string} accessToken
   * @param {InputServiceTask} inputServiceTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public createServiceTask(
    accessToken: string,
    inputServiceTask: InputServiceTask,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .createServiceTask(accessToken, inputServiceTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new list of service tasks
   * @summary Create bulk service tasks
   * @param {string} accessToken
   * @param {Array<InputServiceTask>} [inputServiceTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public createServiceTasks(
    accessToken: string,
    inputServiceTask?: Array<InputServiceTask>,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .createServiceTasks(accessToken, inputServiceTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create and return an Unassigned SubTask linked to a service asset by vin
   * @summary Create a single sub task for an asset (by vin)
   * @param {string} accessToken
   * @param {InputSubTask} inputSubTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public createSubTask(
    accessToken: string,
    inputSubTask: InputSubTask,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .createSubTask(accessToken, inputSubTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all comments on a service asset by VIN
   * @summary Get comments for service asset
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getCommentOnServiceAsset(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getCommentOnServiceAsset(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all comments on a service task by taskId
   * @summary Get comments for service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getCommentOnServiceTask(
    accessToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getCommentOnServiceTask(accessToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all matching results across the database models for a given search term.
   * @summary Get Search Results Across Data Model
   * @param {string} accessToken
   * @param {string} q
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getCommonSearch(accessToken: string, q: string, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getCommonSearch(accessToken, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all open tasks mapped to the asset info this task relates to
   * @summary Get open tasks with asset info
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {AssignmentType} assignmentType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getOpenTasksWithAssetInfo(
    accessToken: string,
    businessLocationId: number,
    assignmentType: AssignmentType,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getOpenTasksWithAssetInfo(accessToken, businessLocationId, assignmentType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an overview over open pickup tasks and repair tasks waiting for pickup-task creation
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getPickupForRepairTasksOverview(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getPickupForRepairTasksOverview(accessToken, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the latest reservations for a given VIN and amount (default 3).
   * @summary Get Reservation History for VIN
   * @param {string} accessToken
   * @param {string} vin
   * @param {number} [amount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getReservationHistory(
    accessToken: string,
    vin: string,
    amount?: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getReservationHistory(accessToken, vin, amount, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a specific service asset (vehicle) by the assets vin
   * @summary Get service asset (vehicle) by vin
   * @param {string} accessToken
   * @param {string} assetVIN
   * @param {boolean} live
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceAsset(
    accessToken: string,
    assetVIN: string,
    live: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceAsset(accessToken, assetVIN, live, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of service assets for which a repair tasks in jira is in \'WAITING FOR SCOOTER\'
   * @summary Get a list of service assets waiting for pickup in jira
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceAssetWaitingForPickupInJira(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceAssetWaitingForPickupInJira(accessToken, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
   * @summary Get service assets (vehicles)
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} businessLocationId
   * @param {Array<number>} [vehicleStateIds]
   * @param {string} [licencePlate]
   * @param {string} [vin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceAssets(
    accessToken: string,
    page: number,
    pageSize: number,
    businessLocationId: number,
    vehicleStateIds?: Array<number>,
    licencePlate?: string,
    vin?: string,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceAssets(
        accessToken,
        page,
        pageSize,
        businessLocationId,
        vehicleStateIds,
        licencePlate,
        vin,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve service assets (vehicles) filtered based on provided criteria.
   * @summary Get filtered service assets (vehicles)
   * @param {string} accessToken
   * @param {AssetFilters} assetFilters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceAssetsFiltered(
    accessToken: string,
    assetFilters: AssetFilters,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceAssetsFiltered(accessToken, assetFilters, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a specific service location.
   * @summary Get a specific service location
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceLocation(accessToken: string, locationId: number, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceLocation(accessToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get service locations.
   * @summary Get service locations.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceLocations(accessToken: string, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceLocations(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get an overview over open tasks and related assets
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {AssignmentType} taskType
   * @param {number} [missionId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceMapTaskOverview(
    accessToken: string,
    businessLocationId: number,
    taskType: AssignmentType,
    missionId?: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceMapTaskOverview(accessToken, businessLocationId, taskType, missionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a service task by id
   * @summary Get service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceTask(accessToken: string, taskId: number, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceTask(accessToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all the logs for a service task by taskId
   * @summary Get logs for service task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceTaskLogs(accessToken: string, taskId: number, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceTaskLogs(accessToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a service task by id, and retrieves additional information about the included Asset. Use query parameter live to force updating the AssetInfo (slow request)
   * @summary Get service task alongside the AssetInfo
   * @param {string} accessToken
   * @param {number} taskId
   * @param {boolean} [live]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceTaskWithAssetInfo(
    accessToken: string,
    taskId: number,
    live?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceTaskWithAssetInfo(accessToken, taskId, live, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get service tasks
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {Array<string>} [vins]
   * @param {Array<AssignmentState>} [taskStatus]
   * @param {number} [businessLocationId]
   * @param {string} [licencePlate]
   * @param {number} [taskId]
   * @param {string} [createdAt]
   * @param {string} [updatedAt]
   * @param {AssignmentType} [taskType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    vins?: Array<string>,
    taskStatus?: Array<AssignmentState>,
    businessLocationId?: number,
    licencePlate?: string,
    taskId?: number,
    createdAt?: string,
    updatedAt?: string,
    taskType?: AssignmentType,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceTasks(
        accessToken,
        page,
        pageSize,
        vins,
        taskStatus,
        businessLocationId,
        licencePlate,
        taskId,
        createdAt,
        updatedAt,
        taskType,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get service tasks alongside the AssetInfo
   * @param {string} accessToken
   * @param {AssignmentState} taskStatus
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [businessLocationId]
   * @param {AssignmentType} [taskType]
   * @param {boolean} [live]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getServiceTasksWithAssetInfos(
    accessToken: string,
    taskStatus: AssignmentState,
    page: number,
    pageSize: number,
    businessLocationId?: number,
    taskType?: AssignmentType,
    live?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getServiceTasksWithAssetInfos(
        accessToken,
        taskStatus,
        page,
        pageSize,
        businessLocationId,
        taskType,
        live,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all subtasks with filters of state, category, vin
   * @summary Fetch paginated filtered subtasks
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {boolean} withLicencePlate
   * @param {Array<AssignmentState>} [states]
   * @param {string} [vin]
   * @param {StepCategory} [category]
   * @param {string} [licencePlate]
   * @param {string} [title]
   * @param {boolean} [titleEqual]
   * @param {string} [createdBefore]
   * @param {string} [createdAfter]
   * @param {number} [locationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getSubTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    withLicencePlate: boolean,
    states?: Array<AssignmentState>,
    vin?: string,
    category?: StepCategory,
    licencePlate?: string,
    title?: string,
    titleEqual?: boolean,
    createdBefore?: string,
    createdAfter?: string,
    locationId?: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getSubTasks(
        accessToken,
        page,
        pageSize,
        withLicencePlate,
        states,
        vin,
        category,
        licencePlate,
        title,
        titleEqual,
        createdBefore,
        createdAfter,
        locationId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the latest notes history for a given VIN and amount (default 3).
   * @summary Get Vehicle Notes History
   * @param {string} accessToken
   * @param {string} vin
   * @param {number} [amount]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public getVehicleNotesHistory(
    accessToken: string,
    vin: string,
    amount?: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .getVehicleNotesHistory(accessToken, vin, amount, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\", returns cancelled tasks
   * @summary Cancels unassigned pickup tasks that no longer have a Jira repair issue in state \"waiting for scooter\"
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public pickupTaskBulkCancel(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .pickupTaskBulkCancel(accessToken, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\", returns created tasks.
   * @summary Creates pickup tasks for all Jira repair issues in state \"waiting for scooter\"
   * @param {string} accessToken
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public pickupTaskBulkCreate(
    accessToken: string,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .pickupTaskBulkCreate(accessToken, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * The failed task will be canceled and then recreated as a new task. All failed subtasks used by the old task will also be canceled and recreated.
   * @summary Reschedule a failed task
   * @param {string} accessToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public rescheduleFailedTask(accessToken: string, taskId: number, options?: AxiosRequestConfig) {
    return ServiceTaskApiFp(this.configuration)
      .rescheduleFailedTask(accessToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets vehicles in \'Relocate/ServiceMode/CustomTour\' state and creates tasks for them as well as cancel outdated tasks.
   * @summary Sync (Create pending and Cancel old) Tasks
   * @param {string} accessToken
   * @param {AssignmentType} taskType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public syncTasksWithWunder(
    accessToken: string,
    taskType: AssignmentType,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .syncTasksWithWunder(accessToken, taskType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update/Give a reason for canceling a task.
   * @summary Update cancel reason for task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {string} cancelReason
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public updateCancelReasonOfTask(
    accessToken: string,
    taskId: number,
    cancelReason: string,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .updateCancelReasonOfTask(accessToken, taskId, cancelReason, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update priority of task, allowed values are 1 (highest prio) - 5 (lowest prio).
   * @summary Update priority of task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {number} newPriority
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public updatePriorityOfTask(
    accessToken: string,
    taskId: number,
    newPriority: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .updatePriorityOfTask(accessToken, taskId, newPriority, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update state of task.
   * @summary Update state of task.
   * @param {string} accessToken
   * @param {number} taskId
   * @param {AssignmentState} newState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public updateStateOfTask(
    accessToken: string,
    taskId: number,
    newState: AssignmentState,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .updateStateOfTask(accessToken, taskId, newState, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a subtask by id to a new state
   * @summary Update a subtask
   * @param {string} accessToken
   * @param {number} subTaskId
   * @param {UpdateSubTask} updateSubTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public updateSubTask(
    accessToken: string,
    subTaskId: number,
    updateSubTask: UpdateSubTask,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .updateSubTask(accessToken, subTaskId, updateSubTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a list of subtasks given a list of ids
   * @summary Update a list of subtasks
   * @param {string} accessToken
   * @param {UpdateSubTasks} updateSubTasks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public updateSubTasks(
    accessToken: string,
    updateSubTasks: UpdateSubTasks,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .updateSubTasks(accessToken, updateSubTasks, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validate a list of VINs
   * @summary Validate VINs
   * @param {string} accessToken
   * @param {Array<string>} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceTaskApi
   */
  public validateVins(
    accessToken: string,
    requestBody?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return ServiceTaskApiFp(this.configuration)
      .validateVins(accessToken, requestBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatus: async (
      accessToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getFleetStatus', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getFleetStatus', 'locationId', locationId);
      const localVarPath = `/v2/statistics/vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Provides a stasticial overview of all Task Types by a given location id States are split into: Pending: Unassigned, Skipped Ongoing: Considered, InProgress, PreStepsCompleted, AtStepsCompleted Failed:  Failed Total: Unassigned, Skipped, Considered, InProgress, PreStepsCompleted, AtStepsCompleted, Failed
     * @summary Get task statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskStatus: async (
      accessToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTaskStatus', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getTaskStatus', 'locationId', locationId);
      const localVarPath = `/v2/statistics/tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetStatus(
      accessToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VehicleStatistics>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetStatus(
        accessToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['StatisticsApi.getFleetStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Provides a stasticial overview of all Task Types by a given location id States are split into: Pending: Unassigned, Skipped Ongoing: Considered, InProgress, PreStepsCompleted, AtStepsCompleted Failed:  Failed Total: Unassigned, Skipped, Considered, InProgress, PreStepsCompleted, AtStepsCompleted, Failed
     * @summary Get task statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskStatus(
      accessToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskStatistics>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskStatus(
        accessToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['StatisticsApi.getTaskStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StatisticsApiFp(configuration);
  return {
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatus(
      accessToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<VehicleStatistics> {
      return localVarFp
        .getFleetStatus(accessToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Provides a stasticial overview of all Task Types by a given location id States are split into: Pending: Unassigned, Skipped Ongoing: Considered, InProgress, PreStepsCompleted, AtStepsCompleted Failed:  Failed Total: Unassigned, Skipped, Considered, InProgress, PreStepsCompleted, AtStepsCompleted, Failed
     * @summary Get task statistics for location id
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskStatus(
      accessToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<TaskStatistics> {
      return localVarFp
        .getTaskStatus(accessToken, locationId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * StatisticsApi - interface
 * @export
 * @interface StatisticsApi
 */
export interface StatisticsApiInterface {
  /**
   * Provides a statistical overview of vehicles for a given location
   * @summary Get vehicles statistics for location id
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApiInterface
   */
  getFleetStatus(
    accessToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<VehicleStatistics>;

  /**
   * Provides a stasticial overview of all Task Types by a given location id States are split into: Pending: Unassigned, Skipped Ongoing: Considered, InProgress, PreStepsCompleted, AtStepsCompleted Failed:  Failed Total: Unassigned, Skipped, Considered, InProgress, PreStepsCompleted, AtStepsCompleted, Failed
   * @summary Get task statistics for location id
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApiInterface
   */
  getTaskStatus(
    accessToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskStatistics>;
}

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI implements StatisticsApiInterface {
  /**
   * Provides a statistical overview of vehicles for a given location
   * @summary Get vehicles statistics for location id
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getFleetStatus(accessToken: string, locationId: number, options?: AxiosRequestConfig) {
    return StatisticsApiFp(this.configuration)
      .getFleetStatus(accessToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Provides a stasticial overview of all Task Types by a given location id States are split into: Pending: Unassigned, Skipped Ongoing: Considered, InProgress, PreStepsCompleted, AtStepsCompleted Failed:  Failed Total: Unassigned, Skipped, Considered, InProgress, PreStepsCompleted, AtStepsCompleted, Failed
   * @summary Get task statistics for location id
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatisticsApi
   */
  public getTaskStatus(accessToken: string, locationId: number, options?: AxiosRequestConfig) {
    return StatisticsApiFp(this.configuration)
      .getTaskStatus(accessToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleInteractionApi - axios parameter creator
 * @export
 */
export const VehicleInteractionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAlarm: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('disableAlarm', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('disableAlarm', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/alarm/disable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAlarm: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('enableAlarm', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('enableAlarm', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/alarm/enable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Immobilizes the Vehicle
     * @summary Immobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    immobilizeEndpoint: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('immobilizeEndpoint', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('immobilizeEndpoint', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/immobilize`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Locks the Helmet Box
     * @summary Locks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockHelmetBoxEndpoint: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('lockHelmetBoxEndpoint', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('lockHelmetBoxEndpoint', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/helmet-box/lock`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Mobilizes the Vehicle
     * @summary Mobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobilizeEndpoint: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('mobilizeEndpoint', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('mobilizeEndpoint', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/mobilize`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Unlocks the Helmet Box
     * @summary Unlocks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockHelmetBoxEndpoint: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('unlockHelmetBoxEndpoint', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('unlockHelmetBoxEndpoint', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/helmet-box/unlock`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderVehicleDataEndpoint: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderVehicleDataEndpoint', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('wunderVehicleDataEndpoint', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleInteractionApi - functional programming interface
 * @export
 */
export const VehicleInteractionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleInteractionApiAxiosParamCreator(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableAlarm(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableAlarm(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.disableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableAlarm(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enableAlarm(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.enableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Immobilizes the Vehicle
     * @summary Immobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async immobilizeEndpoint(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.immobilizeEndpoint(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.immobilizeEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Locks the Helmet Box
     * @summary Locks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockHelmetBoxEndpoint(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.lockHelmetBoxEndpoint(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.lockHelmetBoxEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Mobilizes the Vehicle
     * @summary Mobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobilizeEndpoint(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobilizeEndpoint(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.mobilizeEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Unlocks the Helmet Box
     * @summary Unlocks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockHelmetBoxEndpoint(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlockHelmetBoxEndpoint(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.unlockHelmetBoxEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderVehicleDataEndpoint(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderVehicleDataEndpoint(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.wunderVehicleDataEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleInteractionApi - factory interface
 * @export
 */
export const VehicleInteractionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleInteractionApiFp(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAlarm(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .disableAlarm(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAlarm(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .enableAlarm(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Immobilizes the Vehicle
     * @summary Immobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    immobilizeEndpoint(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .immobilizeEndpoint(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Locks the Helmet Box
     * @summary Locks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockHelmetBoxEndpoint(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .lockHelmetBoxEndpoint(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Mobilizes the Vehicle
     * @summary Mobilizes the Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobilizeEndpoint(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .mobilizeEndpoint(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Unlocks the Helmet Box
     * @summary Unlocks the Helmet Box
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockHelmetBoxEndpoint(accessToken: string, vin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .unlockHelmetBoxEndpoint(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderVehicleDataEndpoint(
      accessToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<LiveData> {
      return localVarFp
        .wunderVehicleDataEndpoint(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleInteractionApi - interface
 * @export
 * @interface VehicleInteractionApi
 */
export interface VehicleInteractionApiInterface {
  /**
   * Disables the vehicle alarm
   * @summary Disables the vehicle alarm
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  disableAlarm(accessToken: string, vin: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Enables the vehicle alarm
   * @summary Enables the vehicle alarm
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  enableAlarm(accessToken: string, vin: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Immobilizes the Vehicle
   * @summary Immobilizes the Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  immobilizeEndpoint(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Locks the Helmet Box
   * @summary Locks the Helmet Box
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  lockHelmetBoxEndpoint(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Mobilizes the Vehicle
   * @summary Mobilizes the Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  mobilizeEndpoint(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Unlocks the Helmet Box
   * @summary Unlocks the Helmet Box
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  unlockHelmetBoxEndpoint(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApiInterface
   */
  wunderVehicleDataEndpoint(
    accessToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<LiveData>;
}

/**
 * VehicleInteractionApi - object-oriented interface
 * @export
 * @class VehicleInteractionApi
 * @extends {BaseAPI}
 */
export class VehicleInteractionApi extends BaseAPI implements VehicleInteractionApiInterface {
  /**
   * Disables the vehicle alarm
   * @summary Disables the vehicle alarm
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public disableAlarm(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .disableAlarm(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables the vehicle alarm
   * @summary Enables the vehicle alarm
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public enableAlarm(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .enableAlarm(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Immobilizes the Vehicle
   * @summary Immobilizes the Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public immobilizeEndpoint(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .immobilizeEndpoint(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Locks the Helmet Box
   * @summary Locks the Helmet Box
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public lockHelmetBoxEndpoint(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .lockHelmetBoxEndpoint(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Mobilizes the Vehicle
   * @summary Mobilizes the Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public mobilizeEndpoint(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .mobilizeEndpoint(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Unlocks the Helmet Box
   * @summary Unlocks the Helmet Box
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public unlockHelmetBoxEndpoint(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .unlockHelmetBoxEndpoint(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public wunderVehicleDataEndpoint(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .wunderVehicleDataEndpoint(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleReportsApi - axios parameter creator
 * @export
 */
export const VehicleReportsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVehicleReport: async (
      accessToken: string,
      vehicleReportBody: VehicleReportBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createVehicleReport', 'accessToken', accessToken);
      // verify required parameter 'vehicleReportBody' is not null or undefined
      assertParamExists('createVehicleReport', 'vehicleReportBody', vehicleReportBody);
      const localVarPath = `/v2/vehicles/reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleReports: async (
      accessToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleReports', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getVehicleReports', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getVehicleReports', 'pageSize', pageSize);
      const localVarPath = `/v2/vehicles/reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (reporter !== undefined) {
        localVarQueryParameter['reporter'] = reporter;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (description !== undefined) {
        localVarQueryParameter['description'] = description;
      }

      if (tags !== undefined) {
        localVarQueryParameter['tags'] = tags;
      }

      if (showResolved !== undefined) {
        localVarQueryParameter['showResolved'] = showResolved;
      }

      if (reportedDuringTourId !== undefined) {
        localVarQueryParameter['reportedDuringTourId'] = reportedDuringTourId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveVehicleReport: async (
      accessToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('resolveVehicleReport', 'accessToken', accessToken);
      // verify required parameter 'vehicleReportId' is not null or undefined
      assertParamExists('resolveVehicleReport', 'vehicleReportId', vehicleReportId);
      // verify required parameter 'vehicleReportResolveBody' is not null or undefined
      assertParamExists(
        'resolveVehicleReport',
        'vehicleReportResolveBody',
        vehicleReportResolveBody
      );
      const localVarPath = `/v2/vehicles/reports/{vehicleReportId}/resolve`.replace(
        `{${'vehicleReportId'}}`,
        encodeURIComponent(String(vehicleReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleReportsApi - functional programming interface
 * @export
 */
export const VehicleReportsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleReportsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVehicleReport(
      accessToken: string,
      vehicleReportBody: VehicleReportBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVehicleReport(
        accessToken,
        vehicleReportBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.createVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleReports(
      accessToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedResultPersistedVehicleReport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleReports(
        accessToken,
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.getVehicleReports']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolveVehicleReport(
      accessToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resolveVehicleReport(
        accessToken,
        vehicleReportId,
        vehicleReportResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.resolveVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleReportsApi - factory interface
 * @export
 */
export const VehicleReportsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleReportsApiFp(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVehicleReport(
      accessToken: string,
      vehicleReportBody: VehicleReportBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .createVehicleReport(accessToken, vehicleReportBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleReports(
      accessToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: any
    ): AxiosPromise<PaginatedResultPersistedVehicleReport> {
      return localVarFp
        .getVehicleReports(
          accessToken,
          page,
          pageSize,
          locationId,
          start,
          end,
          reporter,
          vin,
          licencePlate,
          description,
          tags,
          showResolved,
          reportedDuringTourId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveVehicleReport(
      accessToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .resolveVehicleReport(accessToken, vehicleReportId, vehicleReportResolveBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleReportsApi - interface
 * @export
 * @interface VehicleReportsApi
 */
export interface VehicleReportsApiInterface {
  /**
   * Create a vehicle report
   * @summary Create a vehicle report
   * @param {string} accessToken
   * @param {VehicleReportBody} vehicleReportBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApiInterface
   */
  createVehicleReport(
    accessToken: string,
    vehicleReportBody: VehicleReportBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<PersistedVehicleReport>;

  /**
   * Gets all vehicle reports
   * @summary Gets all vehicle reports
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [locationId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [reporter]
   * @param {string} [vin]
   * @param {string} [licencePlate]
   * @param {string} [description]
   * @param {string} [tags]
   * @param {boolean} [showResolved]
   * @param {number} [reportedDuringTourId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApiInterface
   */
  getVehicleReports(
    accessToken: string,
    page: number,
    pageSize: number,
    locationId?: number,
    start?: string,
    end?: string,
    reporter?: string,
    vin?: string,
    licencePlate?: string,
    description?: string,
    tags?: string,
    showResolved?: boolean,
    reportedDuringTourId?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultPersistedVehicleReport>;

  /**
   * Marks a vehicle report as being resolved by the currently logged in user
   * @summary Marks a vehicle report as being resolved by the currently logged in user
   * @param {string} accessToken
   * @param {number} vehicleReportId
   * @param {VehicleReportResolveBody} vehicleReportResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApiInterface
   */
  resolveVehicleReport(
    accessToken: string,
    vehicleReportId: number,
    vehicleReportResolveBody: VehicleReportResolveBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<PersistedVehicleReport>;
}

/**
 * VehicleReportsApi - object-oriented interface
 * @export
 * @class VehicleReportsApi
 * @extends {BaseAPI}
 */
export class VehicleReportsApi extends BaseAPI implements VehicleReportsApiInterface {
  /**
   * Create a vehicle report
   * @summary Create a vehicle report
   * @param {string} accessToken
   * @param {VehicleReportBody} vehicleReportBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public createVehicleReport(
    accessToken: string,
    vehicleReportBody: VehicleReportBody,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsApiFp(this.configuration)
      .createVehicleReport(accessToken, vehicleReportBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all vehicle reports
   * @summary Gets all vehicle reports
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [locationId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [reporter]
   * @param {string} [vin]
   * @param {string} [licencePlate]
   * @param {string} [description]
   * @param {string} [tags]
   * @param {boolean} [showResolved]
   * @param {number} [reportedDuringTourId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public getVehicleReports(
    accessToken: string,
    page: number,
    pageSize: number,
    locationId?: number,
    start?: string,
    end?: string,
    reporter?: string,
    vin?: string,
    licencePlate?: string,
    description?: string,
    tags?: string,
    showResolved?: boolean,
    reportedDuringTourId?: number,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsApiFp(this.configuration)
      .getVehicleReports(
        accessToken,
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Marks a vehicle report as being resolved by the currently logged in user
   * @summary Marks a vehicle report as being resolved by the currently logged in user
   * @param {string} accessToken
   * @param {number} vehicleReportId
   * @param {VehicleReportResolveBody} vehicleReportResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public resolveVehicleReport(
    accessToken: string,
    vehicleReportId: number,
    vehicleReportResolveBody: VehicleReportResolveBody,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsApiFp(this.configuration)
      .resolveVehicleReport(accessToken, vehicleReportId, vehicleReportResolveBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Checks if the Vehicle is in an currently active running Tour.
     * @summary Get the active Tour of vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveTour: async (
      accessToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getActiveTour', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('getActiveTour', 'vin', vin);
      const localVarPath = `/v2/vehicles/active-tour`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveVehicles: async (
      accessToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getActiveVehicles', 'accessToken', accessToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getActiveVehicles', 'locationId', locationId);
      const localVarPath = `/v2/vehicles/active-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get vehicles for which an open Repair Task exists on Jira
     * @summary Get vehicles for which an open Repair Task exists on Jira
     * @param {string} accessToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableVehicles: async (
      accessToken: string,
      projectKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAvailableVehicles', 'accessToken', accessToken);
      // verify required parameter 'projectKey' is not null or undefined
      assertParamExists('getAvailableVehicles', 'projectKey', projectKey);
      const localVarPath = `/v2/vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectKey !== undefined) {
        localVarQueryParameter['projectKey'] = projectKey;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the latest history for a given VIN.
     * @summary Get Damage Report History
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDamageReportHistory: async (
      accessToken: string,
      vehicleId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getDamageReportHistory', 'accessToken', accessToken);
      // verify required parameter 'vehicleId' is not null or undefined
      assertParamExists('getDamageReportHistory', 'vehicleId', vehicleId);
      const localVarPath = `/v2/vehicles/damage/{vehicleId}/history`.replace(
        `{${'vehicleId'}}`,
        encodeURIComponent(String(vehicleId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleByIdIncludingDamageDescription: async (
      accessToken: string,
      vehicleId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleByIdIncludingDamageDescription', 'accessToken', accessToken);
      // verify required parameter 'vehicleId' is not null or undefined
      assertParamExists('getVehicleByIdIncludingDamageDescription', 'vehicleId', vehicleId);
      const localVarPath = `/v2/vehicles/{vehicleId}/include-damages`.replace(
        `{${'vehicleId'}}`,
        encodeURIComponent(String(vehicleId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all Vehicle States defined in Wunder
     * @summary Get all Vehicle States defined in Wunder
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleStates: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleStates', 'accessToken', accessToken);
      const localVarPath = `/v2/vehicles/states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesNearby: async (
      accessToken: string,
      lat: number,
      lon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehiclesNearby', 'accessToken', accessToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getVehiclesNearby', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getVehiclesNearby', 'lon', lon);
      const localVarPath = `/v2/vehicles/nearby`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchForVehicles: async (
      accessToken: string,
      licencePlate: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('searchForVehicles', 'accessToken', accessToken);
      // verify required parameter 'licencePlate' is not null or undefined
      assertParamExists('searchForVehicles', 'licencePlate', licencePlate);
      const localVarPath = `/v2/vehicles/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (licencePlate !== undefined) {
        localVarQueryParameter['licence-plate'] = licencePlate;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update the state of a vehicle for a given VIN.
     * @summary Update State of Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {string} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfVehicle: async (
      accessToken: string,
      vin: string,
      newState: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateStateOfVehicle', 'accessToken', accessToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('updateStateOfVehicle', 'vin', vin);
      // verify required parameter 'newState' is not null or undefined
      assertParamExists('updateStateOfVehicle', 'newState', newState);
      const localVarPath = `/v2/vehicles/{vin}/change-state`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (newState !== undefined) {
        localVarQueryParameter['newState'] = newState;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Update the state of vehicles for a given VINS. Valid States are defined in Fleetbird
     * @summary Update State of multiple Vehicles
     * @param {string} accessToken
     * @param {VehiclesChangeStateBundle} vehiclesChangeStateBundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfVehicles: async (
      accessToken: string,
      vehiclesChangeStateBundle: VehiclesChangeStateBundle,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateStateOfVehicles', 'accessToken', accessToken);
      // verify required parameter 'vehiclesChangeStateBundle' is not null or undefined
      assertParamExists(
        'updateStateOfVehicles',
        'vehiclesChangeStateBundle',
        vehiclesChangeStateBundle
      );
      const localVarPath = `/v2/vehicles/change-state`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehiclesChangeStateBundle,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration);
  return {
    /**
     * Checks if the Vehicle is in an currently active running Tour.
     * @summary Get the active Tour of vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActiveTour(
      accessToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveTour(
        accessToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.getActiveTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActiveVehicles(
      accessToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveVehicles(
        accessToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.getActiveVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get vehicles for which an open Repair Task exists on Jira
     * @summary Get vehicles for which an open Repair Task exists on Jira
     * @param {string} accessToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableVehicles(
      accessToken: string,
      projectKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableVehicles(
        accessToken,
        projectKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.getAvailableVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the latest history for a given VIN.
     * @summary Get Damage Report History
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDamageReportHistory(
      accessToken: string,
      vehicleId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleDamageReport>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDamageReportHistory(
        accessToken,
        vehicleId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.getDamageReportHistory']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleByIdIncludingDamageDescription(
      accessToken: string,
      vehicleId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getVehicleByIdIncludingDamageDescription(
          accessToken,
          vehicleId,
          options
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.getVehicleByIdIncludingDamageDescription']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all Vehicle States defined in Wunder
     * @summary Get all Vehicle States defined in Wunder
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleStates(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleState>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleStates(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.getVehicleStates']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehiclesNearby(
      accessToken: string,
      lat: number,
      lon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehiclesNearby(
        accessToken,
        lat,
        lon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.getVehiclesNearby']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchForVehicles(
      accessToken: string,
      licencePlate: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchForVehicles(
        accessToken,
        licencePlate,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.searchForVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update the state of a vehicle for a given VIN.
     * @summary Update State of Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {string} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateOfVehicle(
      accessToken: string,
      vin: string,
      newState: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateOfVehicle(
        accessToken,
        vin,
        newState,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.updateStateOfVehicle']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Update the state of vehicles for a given VINS. Valid States are defined in Fleetbird
     * @summary Update State of multiple Vehicles
     * @param {string} accessToken
     * @param {VehiclesChangeStateBundle} vehiclesChangeStateBundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateOfVehicles(
      accessToken: string,
      vehiclesChangeStateBundle: VehiclesChangeStateBundle,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateOfVehicles(
        accessToken,
        vehiclesChangeStateBundle,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.updateStateOfVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehiclesApiFp(configuration);
  return {
    /**
     * Checks if the Vehicle is in an currently active running Tour.
     * @summary Get the active Tour of vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveTour(accessToken: string, vin: string, options?: any): AxiosPromise<Tour> {
      return localVarFp
        .getActiveTour(accessToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveVehicles(
      accessToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getActiveVehicles(accessToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get vehicles for which an open Repair Task exists on Jira
     * @summary Get vehicles for which an open Repair Task exists on Jira
     * @param {string} accessToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableVehicles(
      accessToken: string,
      projectKey: string,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getAvailableVehicles(accessToken, projectKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the latest history for a given VIN.
     * @summary Get Damage Report History
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDamageReportHistory(
      accessToken: string,
      vehicleId: number,
      options?: any
    ): AxiosPromise<Array<VehicleDamageReport>> {
      return localVarFp
        .getDamageReportHistory(accessToken, vehicleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleByIdIncludingDamageDescription(
      accessToken: string,
      vehicleId: number,
      options?: any
    ): AxiosPromise<Vehicle> {
      return localVarFp
        .getVehicleByIdIncludingDamageDescription(accessToken, vehicleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all Vehicle States defined in Wunder
     * @summary Get all Vehicle States defined in Wunder
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleStates(accessToken: string, options?: any): AxiosPromise<Array<VehicleState>> {
      return localVarFp
        .getVehicleStates(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesNearby(
      accessToken: string,
      lat: number,
      lon: number,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getVehiclesNearby(accessToken, lat, lon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchForVehicles(
      accessToken: string,
      licencePlate: string,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .searchForVehicles(accessToken, licencePlate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the state of a vehicle for a given VIN.
     * @summary Update State of Vehicle
     * @param {string} accessToken
     * @param {string} vin
     * @param {string} newState
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfVehicle(
      accessToken: string,
      vin: string,
      newState: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateStateOfVehicle(accessToken, vin, newState, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the state of vehicles for a given VINS. Valid States are defined in Fleetbird
     * @summary Update State of multiple Vehicles
     * @param {string} accessToken
     * @param {VehiclesChangeStateBundle} vehiclesChangeStateBundle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfVehicles(
      accessToken: string,
      vehiclesChangeStateBundle: VehiclesChangeStateBundle,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .updateStateOfVehicles(accessToken, vehiclesChangeStateBundle, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehiclesApi - interface
 * @export
 * @interface VehiclesApi
 */
export interface VehiclesApiInterface {
  /**
   * Checks if the Vehicle is in an currently active running Tour.
   * @summary Get the active Tour of vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getActiveTour(accessToken: string, vin: string, options?: AxiosRequestConfig): AxiosPromise<Tour>;

  /**
   * Get active vehicles in the specified location (WunderFleet locationId)
   * @summary Get active vehicles
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getActiveVehicles(
    accessToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Vehicle>>;

  /**
   * Get vehicles for which an open Repair Task exists on Jira
   * @summary Get vehicles for which an open Repair Task exists on Jira
   * @param {string} accessToken
   * @param {string} projectKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getAvailableVehicles(
    accessToken: string,
    projectKey: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Vehicle>>;

  /**
   * Get the latest history for a given VIN.
   * @summary Get Damage Report History
   * @param {string} accessToken
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getDamageReportHistory(
    accessToken: string,
    vehicleId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<VehicleDamageReport>>;

  /**
   * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
   * @summary Get vehicle by vehicleId including damage description (uncached)
   * @param {string} accessToken
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getVehicleByIdIncludingDamageDescription(
    accessToken: string,
    vehicleId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Vehicle>;

  /**
   * Get all Vehicle States defined in Wunder
   * @summary Get all Vehicle States defined in Wunder
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getVehicleStates(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<VehicleState>>;

  /**
   * Get vehicles nearby the users position
   * @summary Get vehicles nearby the users position
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  getVehiclesNearby(
    accessToken: string,
    lat: number,
    lon: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Vehicle>>;

  /**
   * Search for vehicles by licence plate
   * @summary Search for vehicles by licence plate
   * @param {string} accessToken
   * @param {string} licencePlate Licence plate of the vehicle to search for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  searchForVehicles(
    accessToken: string,
    licencePlate: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Vehicle>>;

  /**
   * Update the state of a vehicle for a given VIN.
   * @summary Update State of Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {string} newState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  updateStateOfVehicle(
    accessToken: string,
    vin: string,
    newState: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<object>;

  /**
   * Update the state of vehicles for a given VINS. Valid States are defined in Fleetbird
   * @summary Update State of multiple Vehicles
   * @param {string} accessToken
   * @param {VehiclesChangeStateBundle} vehiclesChangeStateBundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApiInterface
   */
  updateStateOfVehicles(
    accessToken: string,
    vehiclesChangeStateBundle: VehiclesChangeStateBundle,
    options?: AxiosRequestConfig
  ): AxiosPromise<object>;
}

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI implements VehiclesApiInterface {
  /**
   * Checks if the Vehicle is in an currently active running Tour.
   * @summary Get the active Tour of vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getActiveTour(accessToken: string, vin: string, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .getActiveTour(accessToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get active vehicles in the specified location (WunderFleet locationId)
   * @summary Get active vehicles
   * @param {string} accessToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getActiveVehicles(accessToken: string, locationId: number, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .getActiveVehicles(accessToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get vehicles for which an open Repair Task exists on Jira
   * @summary Get vehicles for which an open Repair Task exists on Jira
   * @param {string} accessToken
   * @param {string} projectKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getAvailableVehicles(
    accessToken: string,
    projectKey: string,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .getAvailableVehicles(accessToken, projectKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the latest history for a given VIN.
   * @summary Get Damage Report History
   * @param {string} accessToken
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getDamageReportHistory(
    accessToken: string,
    vehicleId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .getDamageReportHistory(accessToken, vehicleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
   * @summary Get vehicle by vehicleId including damage description (uncached)
   * @param {string} accessToken
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getVehicleByIdIncludingDamageDescription(
    accessToken: string,
    vehicleId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .getVehicleByIdIncludingDamageDescription(accessToken, vehicleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all Vehicle States defined in Wunder
   * @summary Get all Vehicle States defined in Wunder
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getVehicleStates(accessToken: string, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .getVehicleStates(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get vehicles nearby the users position
   * @summary Get vehicles nearby the users position
   * @param {string} accessToken
   * @param {number} lat
   * @param {number} lon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public getVehiclesNearby(
    accessToken: string,
    lat: number,
    lon: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .getVehiclesNearby(accessToken, lat, lon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for vehicles by licence plate
   * @summary Search for vehicles by licence plate
   * @param {string} accessToken
   * @param {string} licencePlate Licence plate of the vehicle to search for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public searchForVehicles(
    accessToken: string,
    licencePlate: string,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .searchForVehicles(accessToken, licencePlate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the state of a vehicle for a given VIN.
   * @summary Update State of Vehicle
   * @param {string} accessToken
   * @param {string} vin
   * @param {string} newState
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public updateStateOfVehicle(
    accessToken: string,
    vin: string,
    newState: string,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .updateStateOfVehicle(accessToken, vin, newState, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the state of vehicles for a given VINS. Valid States are defined in Fleetbird
   * @summary Update State of multiple Vehicles
   * @param {string} accessToken
   * @param {VehiclesChangeStateBundle} vehiclesChangeStateBundle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public updateStateOfVehicles(
    accessToken: string,
    vehiclesChangeStateBundle: VehiclesChangeStateBundle,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .updateStateOfVehicles(accessToken, vehiclesChangeStateBundle, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkshopApi - axios parameter creator
 * @export
 */
export const WorkshopApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add a Problem from the ModelProblem List for the Given Model Type to this Repair Task.
     * @summary Add a Problem to this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProblemToRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('addProblemToRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('addProblemToRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'modelProblemId' is not null or undefined
      assertParamExists('addProblemToRepairTask', 'modelProblemId', modelProblemId);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/problem/{modelProblemId}`
        .replace(`{${'repairTaskId'}}`, encodeURIComponent(String(repairTaskId)))
        .replace(`{${'modelProblemId'}}`, encodeURIComponent(String(modelProblemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Enables this workshop to service given business location.
     * @summary Enables this workshop to service given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addServiceableLocationWorkshop: async (
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('addServiceableLocationWorkshop', 'accessToken', accessToken);
      // verify required parameter 'workshopId' is not null or undefined
      assertParamExists('addServiceableLocationWorkshop', 'workshopId', workshopId);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists('addServiceableLocationWorkshop', 'businessLocationId', businessLocationId);
      const localVarPath =
        `/v2/workshop/workshop-config/{workshopId}/withLocation/{businessLocationId}`
          .replace(`{${'workshopId'}}`, encodeURIComponent(String(workshopId)))
          .replace(`{${'businessLocationId'}}`, encodeURIComponent(String(businessLocationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a new Workshop
     * @summary Creates a new Workshop
     * @param {string} accessToken
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWorkshop: async (
      accessToken: string,
      inputWorkshop: InputWorkshop,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('addWorkshop', 'accessToken', accessToken);
      // verify required parameter 'inputWorkshop' is not null or undefined
      assertParamExists('addWorkshop', 'inputWorkshop', inputWorkshop);
      const localVarPath = `/v2/workshop/workshop-config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputWorkshop,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Place a comment on this repair task.
     * @summary Comment on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      inputComment: InputComment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('commentOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('commentOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'inputComment' is not null or undefined
      assertParamExists('commentOnRepairTask', 'inputComment', inputComment);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/comment`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputComment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create Repair Task
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTask: async (
      accessToken: string,
      workshopId: number,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createRepairTask', 'accessToken', accessToken);
      // verify required parameter 'workshopId' is not null or undefined
      assertParamExists('createRepairTask', 'workshopId', workshopId);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('createRepairTask', 'vin', vin);
      const localVarPath = `/v2/workshop/repair-task/create-in-workshop/{workshopId}/for-vin/{vin}`
        .replace(`{${'workshopId'}}`, encodeURIComponent(String(workshopId)))
        .replace(`{${'vin'}}`, encodeURIComponent(String(vin)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Disables this workshop for the given business location.
     * @summary Disables this workshop for the given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteServiceableLocationWorkshop: async (
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('deleteServiceableLocationWorkshop', 'accessToken', accessToken);
      // verify required parameter 'workshopId' is not null or undefined
      assertParamExists('deleteServiceableLocationWorkshop', 'workshopId', workshopId);
      // verify required parameter 'businessLocationId' is not null or undefined
      assertParamExists(
        'deleteServiceableLocationWorkshop',
        'businessLocationId',
        businessLocationId
      );
      const localVarPath =
        `/v2/workshop/workshop-config/{workshopId}/withLocation/{businessLocationId}`
          .replace(`{${'workshopId'}}`, encodeURIComponent(String(workshopId)))
          .replace(`{${'businessLocationId'}}`, encodeURIComponent(String(businessLocationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get logs for repair task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getLogsForRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('getLogsForRepairTask', 'repairTaskId', repairTaskId);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/logs`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve the set of ModelProblemCategories.
     * @summary Retrieve the set of ModelProblemCategories.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelProblemCategories: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getModelProblemCategories', 'accessToken', accessToken);
      const localVarPath = `/v2/workshop/problemCategories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve problems for this model, you can select a category or pass a search string that queries defectPart and defectState.
     * @summary Retrieve problems for this model.
     * @param {string} accessToken
     * @param {string} model
     * @param {ModelProblemCategory} [category]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelProblems: async (
      accessToken: string,
      model: string,
      category?: ModelProblemCategory,
      searchString?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getModelProblems', 'accessToken', accessToken);
      // verify required parameter 'model' is not null or undefined
      assertParamExists('getModelProblems', 'model', model);
      const localVarPath = `/v2/workshop/problems/{model}`.replace(
        `{${'model'}}`,
        encodeURIComponent(String(model))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (searchString !== undefined) {
        localVarQueryParameter['searchString'] = searchString;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves all transitions possible for the current user and the state of the repair task.
     * @summary Retrieves all possible transitions.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPossibleTransitionsForRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getPossibleTransitionsForRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('getPossibleTransitionsForRepairTask', 'repairTaskId', repairTaskId);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/transitions`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('getRepairTask', 'repairTaskId', repairTaskId);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get Repair Tasks in Badgermole an empty list of states is treated as not filtering for states, using onlyOpenTasks discards anything in the states list.
     * @summary Get Repair Tasks in Badgermole
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [workshopId]
     * @param {boolean} [onlyOpenTasks]
     * @param {Array<RepairTaskState>} [states]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTasks: async (
      accessToken: string,
      page: number,
      pageSize: number,
      workshopId?: number,
      onlyOpenTasks?: boolean,
      states?: Array<RepairTaskState>,
      vin?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRepairTasks', 'accessToken', accessToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getRepairTasks', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getRepairTasks', 'pageSize', pageSize);
      const localVarPath = `/v2/workshop/repair-task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (workshopId !== undefined) {
        localVarQueryParameter['workshopId'] = workshopId;
      }

      if (onlyOpenTasks !== undefined) {
        localVarQueryParameter['onlyOpenTasks'] = onlyOpenTasks;
      }

      if (states) {
        localVarQueryParameter['states'] = states;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves Workshop for WorkshopId
     * @summary Retrieves Workshop for WorkshopId
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkshop: async (
      accessToken: string,
      workshopId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getWorkshop', 'accessToken', accessToken);
      // verify required parameter 'workshopId' is not null or undefined
      assertParamExists('getWorkshop', 'workshopId', workshopId);
      const localVarPath = `/v2/workshop/workshop-config/{workshopId}`.replace(
        `{${'workshopId'}}`,
        encodeURIComponent(String(workshopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieves all workshop or all workshops that may service the specified businessLocationId
     * @summary Retrieves a List of workshops
     * @param {string} accessToken
     * @param {number} [forBusinessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkshops: async (
      accessToken: string,
      forBusinessLocationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getWorkshops', 'accessToken', accessToken);
      const localVarPath = `/v2/workshop/workshop-config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (forBusinessLocationId !== undefined) {
        localVarQueryParameter['forBusinessLocationId'] = forBusinessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Remove a Problem from this Repair Task.
     * @summary Remove a Problem from this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProblemFromRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('removeProblemFromRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('removeProblemFromRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'modelProblemId' is not null or undefined
      assertParamExists('removeProblemFromRepairTask', 'modelProblemId', modelProblemId);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/problem/{modelProblemId}`
        .replace(`{${'repairTaskId'}}`, encodeURIComponent(String(repairTaskId)))
        .replace(`{${'modelProblemId'}}`, encodeURIComponent(String(modelProblemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Pass an assignee (username that exists in the database), to this endpoint to set an assignee for this repair task.
     * @summary Update Assignee on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {string} assignee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAssigneeOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      assignee: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateAssigneeOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateAssigneeOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'assignee' is not null or undefined
      assertParamExists('updateAssigneeOnRepairTask', 'assignee', assignee);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/assignee`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (assignee !== undefined) {
        localVarQueryParameter['assignee'] = assignee;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Updates the Comment of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommentOfProblemOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      textBody: TextBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateCommentOfProblemOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateCommentOfProblemOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'modelProblemId' is not null or undefined
      assertParamExists('updateCommentOfProblemOnRepairTask', 'modelProblemId', modelProblemId);
      // verify required parameter 'textBody' is not null or undefined
      assertParamExists('updateCommentOfProblemOnRepairTask', 'textBody', textBody);
      const localVarPath =
        `/v2/workshop/repair-task/{repairTaskId}/problem/{modelProblemId}/comment`
          .replace(`{${'repairTaskId'}}`, encodeURIComponent(String(repairTaskId)))
          .replace(`{${'modelProblemId'}}`, encodeURIComponent(String(modelProblemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        textBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update Description on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDescriptionOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      textBody: TextBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateDescriptionOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateDescriptionOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'textBody' is not null or undefined
      assertParamExists('updateDescriptionOnRepairTask', 'textBody', textBody);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/description`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        textBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update Priority on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePriorityOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      priority: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updatePriorityOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updatePriorityOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'priority' is not null or undefined
      assertParamExists('updatePriorityOnRepairTask', 'priority', priority);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/priority`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (priority !== undefined) {
        localVarQueryParameter['priority'] = priority;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update Reason for Repair on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {ReasonForRepair} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReasonForRepairOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      reason: ReasonForRepair,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateReasonForRepairOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateReasonForRepairOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'reason' is not null or undefined
      assertParamExists('updateReasonForRepairOnRepairTask', 'reason', reason);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/reason-for-repair`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (reason !== undefined) {
        for (const [key, value] of Object.entries(reason)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Updates the State of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {RepairTaskProblemState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfProblemOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      state: RepairTaskProblemState,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateStateOfProblemOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateStateOfProblemOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'modelProblemId' is not null or undefined
      assertParamExists('updateStateOfProblemOnRepairTask', 'modelProblemId', modelProblemId);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('updateStateOfProblemOnRepairTask', 'state', state);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/problem/{modelProblemId}/state`
        .replace(`{${'repairTaskId'}}`, encodeURIComponent(String(repairTaskId)))
        .replace(`{${'modelProblemId'}}`, encodeURIComponent(String(modelProblemId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        for (const [key, value] of Object.entries(state)) {
          localVarQueryParameter[key] = value;
        }
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update State on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {RepairTaskState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOnRepairTask: async (
      accessToken: string,
      repairTaskId: number,
      state: RepairTaskState,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateStateOnRepairTask', 'accessToken', accessToken);
      // verify required parameter 'repairTaskId' is not null or undefined
      assertParamExists('updateStateOnRepairTask', 'repairTaskId', repairTaskId);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('updateStateOnRepairTask', 'state', state);
      const localVarPath = `/v2/workshop/repair-task/{repairTaskId}/state`.replace(
        `{${'repairTaskId'}}`,
        encodeURIComponent(String(repairTaskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Updates the Workshop with the id workshopId.
     * @summary Updates the Workshop.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWorkshop: async (
      accessToken: string,
      workshopId: number,
      inputWorkshop: InputWorkshop,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateWorkshop', 'accessToken', accessToken);
      // verify required parameter 'workshopId' is not null or undefined
      assertParamExists('updateWorkshop', 'workshopId', workshopId);
      // verify required parameter 'inputWorkshop' is not null or undefined
      assertParamExists('updateWorkshop', 'inputWorkshop', inputWorkshop);
      const localVarPath = `/v2/workshop/workshop-config/{workshopId}`.replace(
        `{${'workshopId'}}`,
        encodeURIComponent(String(workshopId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputWorkshop,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WorkshopApi - functional programming interface
 * @export
 */
export const WorkshopApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkshopApiAxiosParamCreator(configuration);
  return {
    /**
     * Add a Problem from the ModelProblem List for the Given Model Type to this Repair Task.
     * @summary Add a Problem to this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addProblemToRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addProblemToRepairTask(
        accessToken,
        repairTaskId,
        modelProblemId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.addProblemToRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Enables this workshop to service given business location.
     * @summary Enables this workshop to service given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addServiceableLocationWorkshop(
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addServiceableLocationWorkshop(
        accessToken,
        workshopId,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.addServiceableLocationWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a new Workshop
     * @summary Creates a new Workshop
     * @param {string} accessToken
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addWorkshop(
      accessToken: string,
      inputWorkshop: InputWorkshop,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workshop>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addWorkshop(
        accessToken,
        inputWorkshop,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.addWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Place a comment on this repair task.
     * @summary Comment on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commentOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      inputComment: InputComment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commentOnRepairTask(
        accessToken,
        repairTaskId,
        inputComment,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.commentOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Create Repair Task
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRepairTask(
      accessToken: string,
      workshopId: number,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRepairTask(
        accessToken,
        workshopId,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.createRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Disables this workshop for the given business location.
     * @summary Disables this workshop for the given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteServiceableLocationWorkshop(
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceableLocationWorkshop(
        accessToken,
        workshopId,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.deleteServiceableLocationWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get logs for repair task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogsForRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskLog>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsForRepairTask(
        accessToken,
        repairTaskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.getLogsForRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieve the set of ModelProblemCategories.
     * @summary Retrieve the set of ModelProblemCategories.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModelProblemCategories(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelProblemCategoryApi>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModelProblemCategories(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.getModelProblemCategories']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieve problems for this model, you can select a category or pass a search string that queries defectPart and defectState.
     * @summary Retrieve problems for this model.
     * @param {string} accessToken
     * @param {string} model
     * @param {ModelProblemCategory} [category]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getModelProblems(
      accessToken: string,
      model: string,
      category?: ModelProblemCategory,
      searchString?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelProblem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getModelProblems(
        accessToken,
        model,
        category,
        searchString,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.getModelProblems']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieves all transitions possible for the current user and the state of the repair task.
     * @summary Retrieves all possible transitions.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPossibleTransitionsForRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputRepairTaskTransition>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPossibleTransitionsForRepairTask(
        accessToken,
        repairTaskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.getPossibleTransitionsForRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepairTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRepairTask(
        accessToken,
        repairTaskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.getRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get Repair Tasks in Badgermole an empty list of states is treated as not filtering for states, using onlyOpenTasks discards anything in the states list.
     * @summary Get Repair Tasks in Badgermole
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [workshopId]
     * @param {boolean} [onlyOpenTasks]
     * @param {Array<RepairTaskState>} [states]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRepairTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      workshopId?: number,
      onlyOpenTasks?: boolean,
      states?: Array<RepairTaskState>,
      vin?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultRepairTask>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRepairTasks(
        accessToken,
        page,
        pageSize,
        workshopId,
        onlyOpenTasks,
        states,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.getRepairTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieves Workshop for WorkshopId
     * @summary Retrieves Workshop for WorkshopId
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkshop(
      accessToken: string,
      workshopId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workshop>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkshop(
        accessToken,
        workshopId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.getWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieves all workshop or all workshops that may service the specified businessLocationId
     * @summary Retrieves a List of workshops
     * @param {string} accessToken
     * @param {number} [forBusinessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkshops(
      accessToken: string,
      forBusinessLocationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workshop>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkshops(
        accessToken,
        forBusinessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.getWorkshops']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Remove a Problem from this Repair Task.
     * @summary Remove a Problem from this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeProblemFromRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeProblemFromRepairTask(
        accessToken,
        repairTaskId,
        modelProblemId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.removeProblemFromRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Pass an assignee (username that exists in the database), to this endpoint to set an assignee for this repair task.
     * @summary Update Assignee on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {string} assignee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAssigneeOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      assignee: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssigneeOnRepairTask(
        accessToken,
        repairTaskId,
        assignee,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateAssigneeOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Updates the Comment of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCommentOfProblemOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      textBody: TextBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommentOfProblemOnRepairTask(
        accessToken,
        repairTaskId,
        modelProblemId,
        textBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateCommentOfProblemOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update Description on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDescriptionOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      textBody: TextBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDescriptionOnRepairTask(
        accessToken,
        repairTaskId,
        textBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateDescriptionOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update Priority on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePriorityOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      priority: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePriorityOnRepairTask(
        accessToken,
        repairTaskId,
        priority,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updatePriorityOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update Reason for Repair on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {ReasonForRepair} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateReasonForRepairOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      reason: ReasonForRepair,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateReasonForRepairOnRepairTask(
        accessToken,
        repairTaskId,
        reason,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateReasonForRepairOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Updates the State of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {RepairTaskProblemState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateOfProblemOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      state: RepairTaskProblemState,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateOfProblemOnRepairTask(
        accessToken,
        repairTaskId,
        modelProblemId,
        state,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateStateOfProblemOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Update State on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {RepairTaskState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStateOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      state: RepairTaskState,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateOnRepairTask(
        accessToken,
        repairTaskId,
        state,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.updateStateOnRepairTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Updates the Workshop with the id workshopId.
     * @summary Updates the Workshop.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateWorkshop(
      accessToken: string,
      workshopId: number,
      inputWorkshop: InputWorkshop,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workshop>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkshop(
        accessToken,
        workshopId,
        inputWorkshop,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.updateWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WorkshopApi - factory interface
 * @export
 */
export const WorkshopApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WorkshopApiFp(configuration);
  return {
    /**
     * Add a Problem from the ModelProblem List for the Given Model Type to this Repair Task.
     * @summary Add a Problem to this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addProblemToRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .addProblemToRepairTask(accessToken, repairTaskId, modelProblemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enables this workshop to service given business location.
     * @summary Enables this workshop to service given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addServiceableLocationWorkshop(
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .addServiceableLocationWorkshop(accessToken, workshopId, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new Workshop
     * @summary Creates a new Workshop
     * @param {string} accessToken
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addWorkshop(
      accessToken: string,
      inputWorkshop: InputWorkshop,
      options?: any
    ): AxiosPromise<Workshop> {
      return localVarFp
        .addWorkshop(accessToken, inputWorkshop, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Place a comment on this repair task.
     * @summary Comment on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commentOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      inputComment: InputComment,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .commentOnRepairTask(accessToken, repairTaskId, inputComment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Repair Task
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTask(
      accessToken: string,
      workshopId: number,
      vin: string,
      options?: any
    ): AxiosPromise<RepairTask> {
      return localVarFp
        .createRepairTask(accessToken, workshopId, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Disables this workshop for the given business location.
     * @summary Disables this workshop for the given business location.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {number} businessLocationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteServiceableLocationWorkshop(
      accessToken: string,
      workshopId: number,
      businessLocationId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .deleteServiceableLocationWorkshop(accessToken, workshopId, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get logs for repair task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: any
    ): AxiosPromise<Array<TaskLog>> {
      return localVarFp
        .getLogsForRepairTask(accessToken, repairTaskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve the set of ModelProblemCategories.
     * @summary Retrieve the set of ModelProblemCategories.
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelProblemCategories(
      accessToken: string,
      options?: any
    ): AxiosPromise<Array<ModelProblemCategoryApi>> {
      return localVarFp
        .getModelProblemCategories(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve problems for this model, you can select a category or pass a search string that queries defectPart and defectState.
     * @summary Retrieve problems for this model.
     * @param {string} accessToken
     * @param {string} model
     * @param {ModelProblemCategory} [category]
     * @param {string} [searchString]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getModelProblems(
      accessToken: string,
      model: string,
      category?: ModelProblemCategory,
      searchString?: string,
      options?: any
    ): AxiosPromise<Array<ModelProblem>> {
      return localVarFp
        .getModelProblems(accessToken, model, category, searchString, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves all transitions possible for the current user and the state of the repair task.
     * @summary Retrieves all possible transitions.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPossibleTransitionsForRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: any
    ): AxiosPromise<Array<OutputRepairTaskTransition>> {
      return localVarFp
        .getPossibleTransitionsForRepairTask(accessToken, repairTaskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTask(
      accessToken: string,
      repairTaskId: number,
      options?: any
    ): AxiosPromise<RepairTask> {
      return localVarFp
        .getRepairTask(accessToken, repairTaskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Repair Tasks in Badgermole an empty list of states is treated as not filtering for states, using onlyOpenTasks discards anything in the states list.
     * @summary Get Repair Tasks in Badgermole
     * @param {string} accessToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [workshopId]
     * @param {boolean} [onlyOpenTasks]
     * @param {Array<RepairTaskState>} [states]
     * @param {string} [vin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTasks(
      accessToken: string,
      page: number,
      pageSize: number,
      workshopId?: number,
      onlyOpenTasks?: boolean,
      states?: Array<RepairTaskState>,
      vin?: string,
      options?: any
    ): AxiosPromise<PaginatedResultRepairTask> {
      return localVarFp
        .getRepairTasks(
          accessToken,
          page,
          pageSize,
          workshopId,
          onlyOpenTasks,
          states,
          vin,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves Workshop for WorkshopId
     * @summary Retrieves Workshop for WorkshopId
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkshop(accessToken: string, workshopId: number, options?: any): AxiosPromise<Workshop> {
      return localVarFp
        .getWorkshop(accessToken, workshopId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves all workshop or all workshops that may service the specified businessLocationId
     * @summary Retrieves a List of workshops
     * @param {string} accessToken
     * @param {number} [forBusinessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkshops(
      accessToken: string,
      forBusinessLocationId?: number,
      options?: any
    ): AxiosPromise<Array<Workshop>> {
      return localVarFp
        .getWorkshops(accessToken, forBusinessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove a Problem from this Repair Task.
     * @summary Remove a Problem from this Repair Task.
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeProblemFromRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .removeProblemFromRepairTask(accessToken, repairTaskId, modelProblemId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Pass an assignee (username that exists in the database), to this endpoint to set an assignee for this repair task.
     * @summary Update Assignee on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {string} assignee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAssigneeOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      assignee: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateAssigneeOnRepairTask(accessToken, repairTaskId, assignee, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the Comment of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCommentOfProblemOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      textBody: TextBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateCommentOfProblemOnRepairTask(
          accessToken,
          repairTaskId,
          modelProblemId,
          textBody,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Description on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {TextBody} textBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDescriptionOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      textBody: TextBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateDescriptionOnRepairTask(accessToken, repairTaskId, textBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Priority on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} priority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePriorityOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      priority: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updatePriorityOnRepairTask(accessToken, repairTaskId, priority, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update Reason for Repair on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {ReasonForRepair} reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReasonForRepairOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      reason: ReasonForRepair,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateReasonForRepairOnRepairTask(accessToken, repairTaskId, reason, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Updates the State of the Problem in this Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {number} modelProblemId
     * @param {RepairTaskProblemState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOfProblemOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      modelProblemId: number,
      state: RepairTaskProblemState,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateStateOfProblemOnRepairTask(accessToken, repairTaskId, modelProblemId, state, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update State on Repair Task
     * @param {string} accessToken
     * @param {number} repairTaskId
     * @param {RepairTaskState} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStateOnRepairTask(
      accessToken: string,
      repairTaskId: number,
      state: RepairTaskState,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateStateOnRepairTask(accessToken, repairTaskId, state, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the Workshop with the id workshopId.
     * @summary Updates the Workshop.
     * @param {string} accessToken
     * @param {number} workshopId
     * @param {InputWorkshop} inputWorkshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateWorkshop(
      accessToken: string,
      workshopId: number,
      inputWorkshop: InputWorkshop,
      options?: any
    ): AxiosPromise<Workshop> {
      return localVarFp
        .updateWorkshop(accessToken, workshopId, inputWorkshop, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WorkshopApi - interface
 * @export
 * @interface WorkshopApi
 */
export interface WorkshopApiInterface {
  /**
   * Add a Problem from the ModelProblem List for the Given Model Type to this Repair Task.
   * @summary Add a Problem to this Repair Task.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  addProblemToRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Enables this workshop to service given business location.
   * @summary Enables this workshop to service given business location.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  addServiceableLocationWorkshop(
    accessToken: string,
    workshopId: number,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Creates a new Workshop
   * @summary Creates a new Workshop
   * @param {string} accessToken
   * @param {InputWorkshop} inputWorkshop
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  addWorkshop(
    accessToken: string,
    inputWorkshop: InputWorkshop,
    options?: AxiosRequestConfig
  ): AxiosPromise<Workshop>;

  /**
   * Place a comment on this repair task.
   * @summary Comment on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  commentOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Create Repair Task
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  createRepairTask(
    accessToken: string,
    workshopId: number,
    vin: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<RepairTask>;

  /**
   * Disables this workshop for the given business location.
   * @summary Disables this workshop for the given business location.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  deleteServiceableLocationWorkshop(
    accessToken: string,
    workshopId: number,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Get logs for repair task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getLogsForRepairTask(
    accessToken: string,
    repairTaskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<TaskLog>>;

  /**
   * Retrieve the set of ModelProblemCategories.
   * @summary Retrieve the set of ModelProblemCategories.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getModelProblemCategories(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ModelProblemCategoryApi>>;

  /**
   * Retrieve problems for this model, you can select a category or pass a search string that queries defectPart and defectState.
   * @summary Retrieve problems for this model.
   * @param {string} accessToken
   * @param {string} model
   * @param {ModelProblemCategory} [category]
   * @param {string} [searchString]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getModelProblems(
    accessToken: string,
    model: string,
    category?: ModelProblemCategory,
    searchString?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<ModelProblem>>;

  /**
   * Retrieves all transitions possible for the current user and the state of the repair task.
   * @summary Retrieves all possible transitions.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getPossibleTransitionsForRepairTask(
    accessToken: string,
    repairTaskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<OutputRepairTaskTransition>>;

  /**
   *
   * @summary Get Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getRepairTask(
    accessToken: string,
    repairTaskId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<RepairTask>;

  /**
   * Get Repair Tasks in Badgermole an empty list of states is treated as not filtering for states, using onlyOpenTasks discards anything in the states list.
   * @summary Get Repair Tasks in Badgermole
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [workshopId]
   * @param {boolean} [onlyOpenTasks]
   * @param {Array<RepairTaskState>} [states]
   * @param {string} [vin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getRepairTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    workshopId?: number,
    onlyOpenTasks?: boolean,
    states?: Array<RepairTaskState>,
    vin?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<PaginatedResultRepairTask>;

  /**
   * Retrieves Workshop for WorkshopId
   * @summary Retrieves Workshop for WorkshopId
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getWorkshop(
    accessToken: string,
    workshopId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Workshop>;

  /**
   * Retrieves all workshop or all workshops that may service the specified businessLocationId
   * @summary Retrieves a List of workshops
   * @param {string} accessToken
   * @param {number} [forBusinessLocationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  getWorkshops(
    accessToken: string,
    forBusinessLocationId?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<Workshop>>;

  /**
   * Remove a Problem from this Repair Task.
   * @summary Remove a Problem from this Repair Task.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  removeProblemFromRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Pass an assignee (username that exists in the database), to this endpoint to set an assignee for this repair task.
   * @summary Update Assignee on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {string} assignee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateAssigneeOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    assignee: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Updates the Comment of the Problem in this Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {TextBody} textBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateCommentOfProblemOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    textBody: TextBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Update Description on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {TextBody} textBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateDescriptionOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    textBody: TextBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Update Priority on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} priority
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updatePriorityOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    priority: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Update Reason for Repair on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {ReasonForRepair} reason
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateReasonForRepairOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    reason: ReasonForRepair,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Updates the State of the Problem in this Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {RepairTaskProblemState} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateStateOfProblemOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    state: RepairTaskProblemState,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Update State on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {RepairTaskState} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateStateOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    state: RepairTaskState,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Updates the Workshop with the id workshopId.
   * @summary Updates the Workshop.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {InputWorkshop} inputWorkshop
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApiInterface
   */
  updateWorkshop(
    accessToken: string,
    workshopId: number,
    inputWorkshop: InputWorkshop,
    options?: AxiosRequestConfig
  ): AxiosPromise<Workshop>;
}

/**
 * WorkshopApi - object-oriented interface
 * @export
 * @class WorkshopApi
 * @extends {BaseAPI}
 */
export class WorkshopApi extends BaseAPI implements WorkshopApiInterface {
  /**
   * Add a Problem from the ModelProblem List for the Given Model Type to this Repair Task.
   * @summary Add a Problem to this Repair Task.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public addProblemToRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .addProblemToRepairTask(accessToken, repairTaskId, modelProblemId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables this workshop to service given business location.
   * @summary Enables this workshop to service given business location.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public addServiceableLocationWorkshop(
    accessToken: string,
    workshopId: number,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .addServiceableLocationWorkshop(accessToken, workshopId, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new Workshop
   * @summary Creates a new Workshop
   * @param {string} accessToken
   * @param {InputWorkshop} inputWorkshop
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public addWorkshop(
    accessToken: string,
    inputWorkshop: InputWorkshop,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .addWorkshop(accessToken, inputWorkshop, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Place a comment on this repair task.
   * @summary Comment on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public commentOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .commentOnRepairTask(accessToken, repairTaskId, inputComment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Repair Task
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public createRepairTask(
    accessToken: string,
    workshopId: number,
    vin: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .createRepairTask(accessToken, workshopId, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Disables this workshop for the given business location.
   * @summary Disables this workshop for the given business location.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {number} businessLocationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public deleteServiceableLocationWorkshop(
    accessToken: string,
    workshopId: number,
    businessLocationId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .deleteServiceableLocationWorkshop(accessToken, workshopId, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get logs for repair task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getLogsForRepairTask(
    accessToken: string,
    repairTaskId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .getLogsForRepairTask(accessToken, repairTaskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve the set of ModelProblemCategories.
   * @summary Retrieve the set of ModelProblemCategories.
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getModelProblemCategories(accessToken: string, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .getModelProblemCategories(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve problems for this model, you can select a category or pass a search string that queries defectPart and defectState.
   * @summary Retrieve problems for this model.
   * @param {string} accessToken
   * @param {string} model
   * @param {ModelProblemCategory} [category]
   * @param {string} [searchString]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getModelProblems(
    accessToken: string,
    model: string,
    category?: ModelProblemCategory,
    searchString?: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .getModelProblems(accessToken, model, category, searchString, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves all transitions possible for the current user and the state of the repair task.
   * @summary Retrieves all possible transitions.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getPossibleTransitionsForRepairTask(
    accessToken: string,
    repairTaskId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .getPossibleTransitionsForRepairTask(accessToken, repairTaskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getRepairTask(accessToken: string, repairTaskId: number, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .getRepairTask(accessToken, repairTaskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Repair Tasks in Badgermole an empty list of states is treated as not filtering for states, using onlyOpenTasks discards anything in the states list.
   * @summary Get Repair Tasks in Badgermole
   * @param {string} accessToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [workshopId]
   * @param {boolean} [onlyOpenTasks]
   * @param {Array<RepairTaskState>} [states]
   * @param {string} [vin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getRepairTasks(
    accessToken: string,
    page: number,
    pageSize: number,
    workshopId?: number,
    onlyOpenTasks?: boolean,
    states?: Array<RepairTaskState>,
    vin?: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .getRepairTasks(accessToken, page, pageSize, workshopId, onlyOpenTasks, states, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves Workshop for WorkshopId
   * @summary Retrieves Workshop for WorkshopId
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getWorkshop(accessToken: string, workshopId: number, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .getWorkshop(accessToken, workshopId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves all workshop or all workshops that may service the specified businessLocationId
   * @summary Retrieves a List of workshops
   * @param {string} accessToken
   * @param {number} [forBusinessLocationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public getWorkshops(
    accessToken: string,
    forBusinessLocationId?: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .getWorkshops(accessToken, forBusinessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove a Problem from this Repair Task.
   * @summary Remove a Problem from this Repair Task.
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public removeProblemFromRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .removeProblemFromRepairTask(accessToken, repairTaskId, modelProblemId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Pass an assignee (username that exists in the database), to this endpoint to set an assignee for this repair task.
   * @summary Update Assignee on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {string} assignee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateAssigneeOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    assignee: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateAssigneeOnRepairTask(accessToken, repairTaskId, assignee, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the Comment of the Problem in this Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {TextBody} textBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateCommentOfProblemOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    textBody: TextBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateCommentOfProblemOnRepairTask(
        accessToken,
        repairTaskId,
        modelProblemId,
        textBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Description on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {TextBody} textBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateDescriptionOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    textBody: TextBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateDescriptionOnRepairTask(accessToken, repairTaskId, textBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Priority on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} priority
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updatePriorityOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    priority: number,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updatePriorityOnRepairTask(accessToken, repairTaskId, priority, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update Reason for Repair on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {ReasonForRepair} reason
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateReasonForRepairOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    reason: ReasonForRepair,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateReasonForRepairOnRepairTask(accessToken, repairTaskId, reason, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Updates the State of the Problem in this Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {number} modelProblemId
   * @param {RepairTaskProblemState} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateStateOfProblemOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    modelProblemId: number,
    state: RepairTaskProblemState,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateStateOfProblemOnRepairTask(accessToken, repairTaskId, modelProblemId, state, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update State on Repair Task
   * @param {string} accessToken
   * @param {number} repairTaskId
   * @param {RepairTaskState} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateStateOnRepairTask(
    accessToken: string,
    repairTaskId: number,
    state: RepairTaskState,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateStateOnRepairTask(accessToken, repairTaskId, state, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the Workshop with the id workshopId.
   * @summary Updates the Workshop.
   * @param {string} accessToken
   * @param {number} workshopId
   * @param {InputWorkshop} inputWorkshop
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public updateWorkshop(
    accessToken: string,
    workshopId: number,
    inputWorkshop: InputWorkshop,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .updateWorkshop(accessToken, workshopId, inputWorkshop, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WunderBusinessLocationsApi - axios parameter creator
 * @export
 */
export const WunderBusinessLocationsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessLocationsDescription: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/business-locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WunderBusinessLocationsApi - functional programming interface
 * @export
 */
export const WunderBusinessLocationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WunderBusinessLocationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessLocationsDescription(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLocation>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBusinessLocationsDescription(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderBusinessLocationsApi.getBusinessLocationsDescription']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WunderBusinessLocationsApi - factory interface
 * @export
 */
export const WunderBusinessLocationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WunderBusinessLocationsApiFp(configuration);
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessLocationsDescription(options?: any): AxiosPromise<Array<BusinessLocation>> {
      return localVarFp
        .getBusinessLocationsDescription(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WunderBusinessLocationsApi - interface
 * @export
 * @interface WunderBusinessLocationsApi
 */
export interface WunderBusinessLocationsApiInterface {
  /**
   * Get all emmy business locations including territories and no-parking zones
   * @summary Get all emmy business locations including territories and no-parking zones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderBusinessLocationsApiInterface
   */
  getBusinessLocationsDescription(
    options?: AxiosRequestConfig
  ): AxiosPromise<Array<BusinessLocation>>;
}

/**
 * WunderBusinessLocationsApi - object-oriented interface
 * @export
 * @class WunderBusinessLocationsApi
 * @extends {BaseAPI}
 */
export class WunderBusinessLocationsApi
  extends BaseAPI
  implements WunderBusinessLocationsApiInterface
{
  /**
   * Get all emmy business locations including territories and no-parking zones
   * @summary Get all emmy business locations including territories and no-parking zones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderBusinessLocationsApi
   */
  public getBusinessLocationsDescription(options?: AxiosRequestConfig) {
    return WunderBusinessLocationsApiFp(this.configuration)
      .getBusinessLocationsDescription(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WunderFleetBulkActionsApi - axios parameter creator
 * @export
 */
export const WunderFleetBulkActionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesAbort: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesAbort', 'accessToken', accessToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/abort`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesComplete: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesComplete', 'accessToken', accessToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStart: async (
      accessToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'accessToken', accessToken);
      // verify required parameter 'securityToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'securityToken', securityToken);
      // verify required parameter 'bulkEditStartBody' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'bulkEditStartBody', bulkEditStartBody);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (securityToken != null) {
        localVarHeaderParameter['securityToken'] = String(securityToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkEditStartBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStatus: async (
      accessToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStatus', 'accessToken', accessToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesValidateFile: async (
      accessToken: string,
      file: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesValidateFile', 'accessToken', accessToken);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesValidateFile', 'file', file);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/validate-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WunderFleetBulkActionsApi - functional programming interface
 * @export
 */
export const WunderFleetBulkActionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WunderFleetBulkActionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesAbort(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesAbort(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsApi.wunderBulkEditVehiclesAbort']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesComplete(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesComplete(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsApi.wunderBulkEditVehiclesComplete']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesStart(
      accessToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesStart(
        accessToken,
        securityToken,
        bulkEditStartBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsApi.wunderBulkEditVehiclesStart']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesStatus(
      accessToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WunderBulkEditStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesStatus(
        accessToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsApi.wunderBulkEditVehiclesStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesValidateFile(
      accessToken: string,
      file: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditAssetsFileValidation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesValidateFile(
        accessToken,
        file,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsApi.wunderBulkEditVehiclesValidateFile']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WunderFleetBulkActionsApi - factory interface
 * @export
 */
export const WunderFleetBulkActionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WunderFleetBulkActionsApiFp(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesAbort(accessToken: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .wunderBulkEditVehiclesAbort(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesComplete(accessToken: string, options?: any): AxiosPromise<File> {
      return localVarFp
        .wunderBulkEditVehiclesComplete(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStart(
      accessToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .wunderBulkEditVehiclesStart(accessToken, securityToken, bulkEditStartBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStatus(
      accessToken: string,
      options?: any
    ): AxiosPromise<WunderBulkEditStatus> {
      return localVarFp
        .wunderBulkEditVehiclesStatus(accessToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesValidateFile(
      accessToken: string,
      file: File,
      options?: any
    ): AxiosPromise<BulkEditAssetsFileValidation> {
      return localVarFp
        .wunderBulkEditVehiclesValidateFile(accessToken, file, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WunderFleetBulkActionsApi - interface
 * @export
 * @interface WunderFleetBulkActionsApi
 */
export interface WunderFleetBulkActionsApiInterface {
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApiInterface
   */
  wunderBulkEditVehiclesAbort(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Clear status report of previously submitted bulk edit, download report
   * @summary Clear status report of previously submitted bulk edit, download report
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApiInterface
   */
  wunderBulkEditVehiclesComplete(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<File>;

  /**
   * Starts bulk editing of previously validated assets file
   * @summary Starts bulk editing of previously validated assets file
   * @param {string} accessToken
   * @param {string} securityToken
   * @param {BulkEditStartBody} bulkEditStartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApiInterface
   */
  wunderBulkEditVehiclesStart(
    accessToken: string,
    securityToken: string,
    bulkEditStartBody: BulkEditStartBody,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Get status report of previously submitted bulk edit
   * @summary Get status report of previously submitted bulk edit
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApiInterface
   */
  wunderBulkEditVehiclesStatus(
    accessToken: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<WunderBulkEditStatus>;

  /**
   * Validates an Excel file to be used for editing multiple WunderFleet assets
   * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
   * @param {string} accessToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApiInterface
   */
  wunderBulkEditVehiclesValidateFile(
    accessToken: string,
    file: File,
    options?: AxiosRequestConfig
  ): AxiosPromise<BulkEditAssetsFileValidation>;
}

/**
 * WunderFleetBulkActionsApi - object-oriented interface
 * @export
 * @class WunderFleetBulkActionsApi
 * @extends {BaseAPI}
 */
export class WunderFleetBulkActionsApi
  extends BaseAPI
  implements WunderFleetBulkActionsApiInterface
{
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApi
   */
  public wunderBulkEditVehiclesAbort(accessToken: string, options?: AxiosRequestConfig) {
    return WunderFleetBulkActionsApiFp(this.configuration)
      .wunderBulkEditVehiclesAbort(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Clear status report of previously submitted bulk edit, download report
   * @summary Clear status report of previously submitted bulk edit, download report
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApi
   */
  public wunderBulkEditVehiclesComplete(accessToken: string, options?: AxiosRequestConfig) {
    return WunderFleetBulkActionsApiFp(this.configuration)
      .wunderBulkEditVehiclesComplete(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts bulk editing of previously validated assets file
   * @summary Starts bulk editing of previously validated assets file
   * @param {string} accessToken
   * @param {string} securityToken
   * @param {BulkEditStartBody} bulkEditStartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApi
   */
  public wunderBulkEditVehiclesStart(
    accessToken: string,
    securityToken: string,
    bulkEditStartBody: BulkEditStartBody,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsApiFp(this.configuration)
      .wunderBulkEditVehiclesStart(accessToken, securityToken, bulkEditStartBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status report of previously submitted bulk edit
   * @summary Get status report of previously submitted bulk edit
   * @param {string} accessToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApi
   */
  public wunderBulkEditVehiclesStatus(accessToken: string, options?: AxiosRequestConfig) {
    return WunderFleetBulkActionsApiFp(this.configuration)
      .wunderBulkEditVehiclesStatus(accessToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates an Excel file to be used for editing multiple WunderFleet assets
   * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
   * @param {string} accessToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsApi
   */
  public wunderBulkEditVehiclesValidateFile(
    accessToken: string,
    file: File,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsApiFp(this.configuration)
      .wunderBulkEditVehiclesValidateFile(accessToken, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

import { useVehicleInteractionOnStep } from '@/hooks/Tour/General/useVehicleInteraction';
import type { Step, Tour, TriggerAction } from '@badgermoleV2/api';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerRoundFilled } from 'spinners-react';

type VehicleInteractionsProps = {
  readonly tour: Tour;
  readonly step: Step;
  readonly disableAllButtons?: boolean;
};

export const VehicleInteractions = ({
  tour,
  step,
  disableAllButtons
}: VehicleInteractionsProps) => {
  const { sendVehicleInteractionAsync } = useVehicleInteractionOnStep();
  const { t } = useTranslation();
  const [loadingActions, setLoadingActions] = useState<TriggerAction[]>([]);

  function toggleTriggerActionLoading(action: TriggerAction) {
    setLoadingActions((actions) =>
      actions.includes(action) ? actions.filter((a) => a !== action) : [...actions, action]
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          padding: '.5rem',
          justifyContent: 'space-evenly'
        }}
      >
        {step!.triggerActions!.map((triggerAction) => (
          <Button
            key={`VehicleInteractionButton${triggerAction}`}
            data-testid={`vehicleInteraction${triggerAction}`}
            size="small"
            variant="outlined"
            color="secondary"
            disabled={
              loadingActions.filter((loadingAction) => loadingAction === triggerAction).length >
                0 || disableAllButtons
            }
            onClick={async () => {
              toggleTriggerActionLoading(triggerAction);
              await sendVehicleInteractionAsync({
                missionId: tour.missionId!,
                tourId: tour.tourId!,
                stepId: step.stepId!,
                action: {
                  action: triggerAction
                }
              });
              toggleTriggerActionLoading(triggerAction);
            }}
            sx={{ margin: '0.5rem' }}
          >
            {t(`pickup-tour:triggerActions:${triggerAction}`)}
            {loadingActions.filter((loadingAction) => loadingAction === triggerAction).length >
              0 && <SpinnerRoundFilled size={30} thickness={100} speed={100} color="grey" />}
          </Button>
        ))}
      </Grid>
    </>
  );
};

import { BatteryLevelChip } from '@/components/Chips/BatteryLevelChip';
import { CancelStepDialog } from '@/components/Dialogs/CancelStepDialog';
import { CompleteChecksDialog } from '@/components/Dialogs/CompleteChecksDialog';
import { ConfirmDialog } from '@/components/Dialogs/ConfirmDialog';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { NavigateButton } from '@/components/common/NavigateButton/NavigateButton';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useVehicleInteractionOnStep } from '@/hooks/Tour/General/useVehicleInteraction';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import type {
  CompiledTour,
  CompleteChecksResponse,
  Job,
  Step,
  TransitionAction,
  TriggerAction,
  Vehicle
} from '@badgermoleV2/api';
import { StepState } from '@badgermoleV2/api';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Box, Button, ListItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpinnerRoundFilled } from 'spinners-react';

type RelocateVehicleProps = {
  readonly job: Job;
  readonly step: Step;
  readonly vehicle: Vehicle;
  readonly compiledTour: CompiledTour;
  readonly removeVehicleFromTour: (vehicle: Vehicle) => void;
};

export const RelocateVehicle = ({
  compiledTour,
  job,
  step,
  vehicle,
  removeVehicleFromTour
}: RelocateVehicleProps) => {
  const { t } = useTranslation();

  const {
    failStepAsync,
    reloadStepPosition,
    completeStepAsync,
    checkCompleteStepAsync,
    isReloadStepPositionPending,
    isFailStepPending,
    isCheckCompleteStepPending,
    isCompleteStepPending
  } = useStepInteraction();

  const { sendVehicleInteractionAsync } = useVehicleInteractionOnStep();

  const [checksOpen, setChecksOpen] = useState(false);
  const [failStepDialogOpen, setFailStepDialogOpen] = useState(false);
  const [confirmActivateDialogOpen, setConfirmActivateDialogOpen] = useState(false);
  const [completeChecksResponse, setCompleteChecksResponse] = useState<CompleteChecksResponse>();

  const [failProcess, setFailProcess] = useState(false);
  const [selectedCancelAction, setSelectedCancelAction] = useState<TransitionAction>();
  const [cancelReason, setCancelReason] = useState<string>();

  const completeWithChecks = async () => {
    setConfirmActivateDialogOpen(false);
    setChecksOpen(false);
    setFailProcess(false);
    setSelectedCancelAction(undefined);
    setCancelReason(undefined);
    const response = await checkCompleteStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) {
      await completeAction();
    } else {
      setCompleteChecksResponse(response);
    }
    setChecksOpen(true);
  };

  const failWithChecks = async (cancelAction: TransitionAction, reason: string) => {
    setConfirmActivateDialogOpen(false);
    setChecksOpen(false);
    setFailProcess(true);
    setSelectedCancelAction(cancelAction);
    setCancelReason(reason);
    const response = await checkCompleteStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) {
      await failAction(cancelAction, reason);
    } else {
      setCompleteChecksResponse(response);
    }
    setChecksOpen(true);
  };

  const failAction = async (cancelAction: TransitionAction, reason: string) => {
    setFailStepDialogOpen(false);
    const response = await failStepAsync({
      tour: compiledTour,
      step,
      cancelAction,
      failReason: reason
    });
    if (response.success) removeVehicleFromTour(vehicle);
  };

  const completeAction = async () => {
    const response = await completeStepAsync({
      tour: compiledTour,
      step
    });
    if (response.success) removeVehicleFromTour(vehicle);
  };

  const [loadingActions, setLoadingActions] = useState<TriggerAction[]>([]);

  const toggleTriggerActionLoading = (action: TriggerAction) => {
    setLoadingActions((actions) =>
      actions.includes(action) ? actions.filter((a) => a !== action) : [...actions, action]
    );
  };

  const loading =
    isReloadStepPositionPending ||
    isFailStepPending ||
    isCheckCompleteStepPending ||
    isCompleteStepPending;
  const stepInteractionDisabled = step.isDoneOrFailed || loading;

  return (
    <>
      {loading && <DataLoadingDialog open={true} message={t('common:loading:general')} />}

      {checksOpen && completeChecksResponse && (
        <CompleteChecksDialog
          allowIgnore
          open={checksOpen}
          tour={compiledTour}
          job={job}
          step={step}
          completeChecksResponse={completeChecksResponse}
          onRetry={() => {
            if (failProcess) {
              failWithChecks(selectedCancelAction!, cancelReason!);
            } else {
              completeWithChecks();
            }
          }}
          onClose={() => {
            setChecksOpen(false);
          }}
          onIgnore={() => {
            setChecksOpen(false);
            if (failProcess && selectedCancelAction && cancelReason) {
              failAction(selectedCancelAction, cancelReason);
            } else {
              completeAction();
            }
          }}
        />
      )}

      {failStepDialogOpen && (
        <CancelStepDialog
          open={failStepDialogOpen}
          handleOk={(cancelAction, reason) => {
            failWithChecks(cancelAction, reason);
          }}
          handleCancel={() => {
            setFailStepDialogOpen(false);
          }}
          cancelAction={step.cancelActions![0]}
        />
      )}

      {confirmActivateDialogOpen && (
        <ConfirmDialog
          open={confirmActivateDialogOpen}
          cancelTitle={t('relocate:dialog:cancelActivate')}
          handleCancel={() => setConfirmActivateDialogOpen(false)}
          handleOk={() => {
            completeWithChecks();
          }}
          okMessage={t('relocate:dialog:activate')}
          okTitle={t('relocate:dialog:activate')}
          hint={t('relocate:dialog:hint')}
          placeholder={undefined}
          withComment={false}
          successAction={true}
          warning={undefined}
        />
      )}

      <ListItem sx={{ padding: '.2rem' }} key={`RelocateListItem${vehicle.vehicleId}`}>
        <Stack spacing={1} width="100%">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: 'x-large', fontWeight: 'bold' }}>
              {vehicle.licencePlate}
            </Typography>
            <BatteryLevelChip size="small" batteryLevel={vehicle.batteryLevel} />
            <NavigateButton url={NavigatorUtils.createMapsUrl(vehicle)} />
            {(step.state === StepState.Canceled || step.state === StepState.Failed) && (
              <Typography sx={{ fontSize: 'x-large', fontWeight: 'bold', color: 'red' }}>
                {t('relocate:state.failed')}
              </Typography>
            )}
            {step.state === StepState.Completed && (
              <Typography sx={{ fontSize: 'x-large', fontWeight: 'bold', color: 'green' }}>
                {t('relocate:state.activated')}
              </Typography>
            )}
          </Box>

          <Stack spacing={1} direction="row">
            <Button
              disabled={loading}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                reloadStepPosition({
                  tour: compiledTour,
                  step
                });
              }}
            >
              <GpsFixedIcon fontSize="small" sx={{ marginRight: '4px' }} />
              {t('common:buttons.refresh')}
            </Button>

            {(step.triggerActions ?? []).map((triggerAction) => (
              <Button
                key={`CurrentStepTriggerActionButton${triggerAction}`}
                data-testid={`vehicleInteraction${triggerAction}`}
                size="small"
                variant="outlined"
                color="secondary"
                disabled={loadingActions.some((loadingAction) => loadingAction === triggerAction)}
                onClick={async () => {
                  toggleTriggerActionLoading(triggerAction);
                  await sendVehicleInteractionAsync({
                    missionId: compiledTour.tour.missionId!,
                    tourId: compiledTour.tour.tourId!,
                    stepId: step.stepId!,
                    action: {
                      action: triggerAction
                    }
                  });
                  toggleTriggerActionLoading(triggerAction);
                }}
              >
                {t(`pickup-tour:triggerActions:${triggerAction}`)}
                {loadingActions.some((loadingAction) => loadingAction === triggerAction) && (
                  <SpinnerRoundFilled size={30} thickness={100} speed={100} color="grey" />
                )}
              </Button>
            ))}
          </Stack>

          {!stepInteractionDisabled && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                sx={{ width: '40%' }}
                variant="outlined"
                color="error"
                disabled={stepInteractionDisabled}
                fullWidth
                onClick={() => {
                  setFailStepDialogOpen(true);
                }}
              >
                {t('fail')}
              </Button>
              <Button
                disabled={stepInteractionDisabled}
                variant="contained"
                color="success"
                fullWidth
                onClick={() => {
                  setConfirmActivateDialogOpen(true);
                }}
              >
                {t('pickup-tour:completeActions:' + step.completeAction.toString())}
              </Button>
            </Box>
          )}
        </Stack>
      </ListItem>
    </>
  );
};
